import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

import Container from '@/components/container/Container';

type TabValue = string | undefined;

type OnChangeCallback<T> = ((value: T) => void) | React.Dispatch<React.SetStateAction<T>>;

export const TabsContext = React.createContext({
  selected: undefined as TabValue,
  selectTab: ((value: TabValue) => {}) as OnChangeCallback<TabValue> | undefined,
  progress: false,
  disabled: false,
  getSelectedTabRef: (el: HTMLElement) => {},
});

interface Props<T extends TabValue> {
  children: React.ReactNode;
  className?: string;
  extended?: boolean;
  minimal?: boolean;
  /** @deprecated use `tabStyle` prop */
  button?: boolean;
  /** @deprecated use `tabStyle` prop */
  classic?: boolean;
  /** @deprecated use `tabStyle` prop */
  progress?: boolean;
  disabled?: boolean;
  tabStyle?: 'classic' | 'progress' | 'button' | 'pills';
  selected: T;
  onChange: OnChangeCallback<T>;
}

function Tabs<T extends TabValue>({
  children,
  className,
  extended = false,
  minimal = false,
  button = false,
  classic = false,
  progress = false,
  disabled = false,
  tabStyle: _tabStyle,
  selected,
  onChange,
}: Props<T>) {
  const tabsRef = useRef<HTMLDivElement | null>(null);
  const selectedTabRef = useRef<HTMLElement | null>(null);

  const tabStyle = _tabStyle || (progress && 'progress') || (classic && 'classic') || (button && 'button') || undefined;

  useEffect(() => {
    scrollToView();
  }, [selected]);

  const getSelectedTabRef = (el: HTMLElement) => {
    selectedTabRef.current = el;
  };

  const scrollToView = () => {
    if (tabStyle === 'progress' || tabStyle === 'classic') {
      return;
    }
    const tabs = tabsRef.current!;
    const tabsRect = tabs.getBoundingClientRect();

    if (selectedTabRef.current) {
      const selectedTabRect = selectedTabRef.current.getBoundingClientRect();

      if (selectedTabRect.right > tabsRect.width) {
        tabs.scrollLeft += selectedTabRect.right - tabsRect.width;
      } else if (selectedTabRect.left < 0) {
        tabs.scrollLeft += selectedTabRect.left;
      }
    }
  };

  const renderTabs = () => {
    const tabsStyle = classNames(
      'tabs',
      {
        'tabs--extended': extended,
        'tabs--minimal': minimal,
        [`tabs--${tabStyle}`]: tabStyle,
      },
      className
    );
    if (tabStyle === 'progress') {
      return (
        <div className="tabs__wrap">
          <Container center>
            <div className={tabsStyle} ref={tabsRef}>
              {children}
            </div>
          </Container>
        </div>
      );
    }
    return (
      <div className={tabsStyle} ref={tabsRef}>
        {children}
      </div>
    );
  };

  return (
    <TabsContext.Provider
      value={{
        selected,
        selectTab: onChange as OnChangeCallback<TabValue>,
        progress: tabStyle === 'progress',
        disabled,
        getSelectedTabRef,
      }}
    >
      {renderTabs()}
    </TabsContext.Provider>
  );
}

export default Tabs;
