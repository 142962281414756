import Immutable, { fromJS, List } from 'immutable';
import { combineReducers } from 'redux';
import produce, { freeze } from 'immer';
import { ProjectLinkedRealty } from '@city24/common/types/public/RealtyPublic';

import {
  RESET_FILTERS,
  SET_RESULT_LAYOUT,
  SET_SORTING,
  SET_OBJECT_RESULTS_PAGE,
  SEARCH_OBJECTS,
  SET_AREA_SELECT,
  SEARCH_OBJECTS_INIT,
  DO_AUTOCOMPLETE,
  ADD_AUTOCOMPLETE_TERM,
  REMOVE_AUTOCOMPLETE_TERM,
  SET_SEARCH_URL,
  CLEAR_SEARCH_TERM_DRAFT,
  SET_FILTER_COUNT,
  SET_SEARCH_FORM,
  SET_RESULTS_LOADING,
  SET_SEARCH_LAST_RUN,
  SEARCH_PREV_NEXT_OBJECTS,
  SET_MAP_OPENED,
  UPDATE_SEARCH_FILTERS,
  SET_SEARCH_RESULTS_PER_PAGE,
  GET_LINKED_OBJECTS,
  SET_SEARCH_LAST_QUERY,
} from '@/constants/actions';
import { OBJECT_TYPES } from '@/constants/object';
import { getSortingOptions } from '@/constants/filters';
import { createLocationSearchTerm, getAreaSelectSecondaryName, mapAreaSelectExtra } from '@/utils/search';
import { RESULTS_VIEW, RESULTS_VIEW_OPTIONS, SEARCH_RESULTS_TO_SHOW } from '@/constants';

import { AsyncData } from '@/types/collections';
import { NameValue } from '@/utils/collections';
import { isAddressTerm, keywordTerm } from '@/utils/searchTerms';
import { getAppliedFilters } from '@/utils/appliedFilters';
import { accessNested, groupBy } from '@/utils/object';
import { identity } from '@/utils/helpers';
import { formInitialState } from './searchInitialStates';

const viewInitialState = {
  layout: RESULTS_VIEW_OPTIONS.first(),
  mobileLayout: RESULTS_VIEW_OPTIONS.find((view) => view.get('value') === RESULTS_VIEW.grid),
};

const view = produce((state, action) => {
  switch (action.type) {
    case SET_RESULT_LAYOUT: {
      const { layout, mobile } = action;
      if (layout) {
        if (layout.get('value') !== RESULTS_VIEW.map) {
          state.layout = layout;
        }
        if (mobile === undefined) {
          state.mobileLayout = layout;
        }
      }
      if (mobile) {
        state.mobileLayout = mobile;
      }
      break;
    }

    default:
      break;
  }
}, viewInitialState);

const resultsInitialState = {
  objects: List(),
  linkedObjects: {} as { [id: string]: AsyncData<ProjectLinkedRealty[]> },
  type: 'realties',
  pagination: {
    total: 0,
    start: 0,
    end: 0,
  },
  objectsToShow: SEARCH_RESULTS_TO_SHOW,
  objectsPage: 1,
  searchFilters: {},
  sorting: getSortingOptions().find((option) => option.get('default')),
  lastQuery: null,
  resultsLoading: true,
  autocompleteResults: [],
  prevNext: {
    objects: List(),
    pagination: {
      total: 0,
      start: -1,
      end: -1,
    },
  },
  mapOpened: false,
  id: 0,
};

function mapLocationTerm({ matches, content, count }) {
  let term;
  if (matches === 'street_house') {
    if (content.house_number) {
      const name = `${content.street.name} ${content.house_number}`;
      term = { name, value: keywordTerm(name), extra: {} };
    } else {
      return null;
    }
  } else {
    term = createLocationSearchTerm(matches, content[matches]);
  }
  term.extra = {
    ...term.extra,
    ...mapAreaSelectExtra(content.county, content.parish, content.city),
    count,
    secondaryName: getAreaSelectSecondaryName(matches, content),
  };
  return term;
}

function mapFriendlyIdTerm({ content }) {
  const { friendly_id } = content;
  return { name: friendly_id, value: friendly_id, extra: content };
}

function mapAutocompleteResults(value) {
  const { classifier } = value;
  if (classifier.startsWith('address.')) {
    return mapLocationTerm(value, classifier);
  }
  if (classifier === 'friendlyId') {
    return mapFriendlyIdTerm(value, classifier);
  }
  return undefined;
}

function mergeFiltersToState(state, action) {
  if (!action.filters) {
    return state;
  }
  return state.withMutations((s) => {
    const objectType =
      (action.filters.objectType && action.filters.objectType.get('value')) || s.getIn(['objectType', 'value']);

    Object.keys(action.filters).forEach((key) => {
      const value = action.filters[key];
      switch (key) {
        case 'purpose':
          s.setIn([key, objectType], value);
          break;

        case 'extras': {
          if (objectType === OBJECT_TYPES.House || objectType === OBJECT_TYPES.HouseShare) {
            s.setIn([key, OBJECT_TYPES.House], value).setIn([key, OBJECT_TYPES.HouseShare], value);
          } else {
            s.setIn([key, objectType], value);
          }
          break;
        }
        default:
          s.set(key, value);
          break;
      }
    });
  });
}

const results = produce((state, action) => {
  switch (action.type) {
    case SET_OBJECT_RESULTS_PAGE:
      state.objectsPage = action.objectResultsPage;
      break;

    case UPDATE_SEARCH_FILTERS:
    case ADD_AUTOCOMPLETE_TERM:
      if (action.resetPageNumber) {
        state.objectsPage = 1;
      }
      break;

    case SEARCH_OBJECTS_INIT: {
      if (action.id >= state.id) {
        state.resultsLoading = true;
        if (action.searchFilters) {
          state.searchFilters = action.searchFilters;
          state.pagination = resultsInitialState.pagination;
        }
        state.id = action.id;
      }
      break;
    }

    case SEARCH_OBJECTS: {
      if (action.id >= state.id) {
        state.objects = Immutable.fromJS(action.objects);
        state.pagination = freeze(action.pagination || resultsInitialState.pagination);
        state.prevNext = { objects: state.objects, pagination: state.pagination };
        state.type = action.resultsType;
        state.resultsLoading = false;
        state.lastQuery = action.lastQuery;
        state.objectsPage = action.page;
        state.id = action.id;
      }
      break;
    }

    case SET_SORTING:
      state.sorting = action.sorting;
      state.objectsPage = 1;
      break;

    case SET_SEARCH_LAST_QUERY: {
      const { query, pagination } = action.query;
      state.lastQuery = query;
      state.prevNext.pagination.total = pagination.total;
      break;
    }
    case GET_LINKED_OBJECTS: {
      const { projectId, realties } = action;
      realties.data = realties.data.map((o: ProjectLinkedRealty) => Immutable.fromJS(o));
      state.linkedObjects[projectId] = realties;
      break;
    }

    case SEARCH_PREV_NEXT_OBJECTS: {
      const newPagination = action.pagination || resultsInitialState.pagination;
      const {
        prevNext: { objects, pagination },
      } = state;

      const ids = objects.map((object) => object.get('id')).toSet();

      const newObjects = Immutable.fromJS(action.objects.filter((object) => !ids.has(object.id)));
      if (!newObjects.isEmpty()) {
        state.prevNext = freeze({
          objects: action.afterFrom ? objects.concat(newObjects) : newObjects.concat(objects),
          pagination: freeze({
            total: newPagination.total,
            start: pagination.start === -1 ? newPagination.start : Math.min(newPagination.start, pagination.start),
            end: pagination.end === -1 ? newPagination.end : Math.max(newPagination.end, pagination.end),
          }),
        });
      }
      break;
    }

    case SET_RESULTS_LOADING:
      state.resultsLoading = action.resultsLoading;
      break;

    case DO_AUTOCOMPLETE: {
      const grouped = groupBy(action.results, (v) =>
        v.classifier === 'address.street_house' ? 'address.street' : v.classifier
      );
      state.autocompleteResults = freeze(
        Object.keys(grouped).map((key) => ({
          name: `autocomplete.${key}`,
          values: grouped[key].map((value) => mapAutocompleteResults(value, key)).filter(identity),
        }))
      );
      break;
    }

    case SET_MAP_OPENED:
      state.mapOpened = action.mapOpened;
      break;

    case SET_SEARCH_RESULTS_PER_PAGE:
      state.objectsToShow = action.resultsPerPage;
      break;

    default:
      break;
  }
}, resultsInitialState);

function addAddressTerms(searchTerms, county, parish, city) {
  return produce(searchTerms, (draft) => {
    const deleteParents = (child) =>
      ['county', 'parish', 'city', 'village'].forEach((key) => {
        const value = child.getIn(['extra', key, 'value']);
        if (value) draft.delete(value);
      });

    if (county.has('value')) {
      draft.set(county.get('value'), county.toJS());
    }

    if (parish.size) {
      parish.forEach((p) => {
        draft.set(p.get('value'), p.toJS());
        deleteParents(p);
      });
    }
    if (city.size) {
      city.forEach((c) => {
        draft.set(c.get('value'), c.toJS());
        deleteParents(c);
      });
    }
  });
}

function form(state = formInitialState, action) {
  switch (action.type) {
    case CLEAR_SEARCH_TERM_DRAFT:
      return state.set('searchTermDraft', state.get('searchTerm'));

    case SET_FILTER_COUNT:
      return state.set('filterCount', action.filterCount);

    case SET_SEARCH_LAST_RUN:
      return state.set('lastRun', action.lastRun);

    case SET_SEARCH_URL:
      return state.set('searchParamsURL', action.searchParamsURL);

    case SEARCH_OBJECTS_INIT:
      if (action.id >= state.get('id')) {
        const newState = state.set('id', action.id);
        if (action.searchParamsURL !== null) {
          return newState.set('searchParamsURL', action.searchParamsURL);
        }
        return newState;
      }
      return state;

    case UPDATE_SEARCH_FILTERS:
      return mergeFiltersToState(state, action);

    case RESET_FILTERS:
      return mergeFiltersToState(formInitialState, action).set('searchParamsURL', '');

    case ADD_AUTOCOMPLETE_TERM: {
      const { term } = action;
      const updateTerm = (searchTerm) =>
        produce(searchTerm, (draft) => {
          draft.set(term.value, term);
        });
      const newState = state.update('searchTerm', updateTerm);
      return newState.update('searchTermDraft', newState.get('searchTerm'), updateTerm);
    }

    case REMOVE_AUTOCOMPLETE_TERM: {
      const { termKey } = action;

      if (isAddressTerm(termKey)) {
        // Remove all address terms when one is removed and reset areaSelect
        return ['county', 'parish'].reduce(
          (newState, key) =>
            newState.update('searchTerm', formInitialState.get('searchTerm'), (searchTerm) =>
              produce(searchTerm, (draft) => {
                searchTerm.forEach((term, k) => {
                  if (!isAddressTerm(k, key)) {
                    draft.delete(key);
                  }
                });
              })
            ),
          state
        );
      }
      return state.update('searchTerm', (searchTerm) =>
        produce(searchTerm, (draft) => {
          draft.delete(termKey);
        })
      );
    }

    case SET_AREA_SELECT: {
      const { county, parish, city, isDraft } = action;
      const newState = state.update('searchTermDraft', (s) => addAddressTerms(s, county, parish, city));
      if (isDraft) {
        return newState;
      }
      return newState.update('searchTerm', (s) => addAddressTerms(s, county, parish, city));
    }
    case SET_SEARCH_FORM:
      return action.searchForm;

    case SEARCH_OBJECTS_INIT:
      if (action.searchFilters) {
        return state.set('filterCount', getAppliedFilters(action.searchFilters, formInitialState));
      }
      return state;

    default:
      return state;
  }
}

export default combineReducers({
  view,
  results,
  form,
});
