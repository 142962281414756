import { fromJS, List, Map } from 'immutable';
import * as Sentry from '@sentry/nextjs';
import { parseTokenPayload } from '@city24/common/api/city24/auth';

import { MODEL_STATUSES } from '@/constants';
import {
  SET_PERIOD_PACKAGE,
  UPDATE_PRODUCT_CONF,
  SET_AUTO_RENEWAL,
  SORT_IMAGES,
  UPDATE_OBJECT_IMAGE,
  UPLOAD_OBJECT_IMAGES,
  DELETE_OBJECT_IMAGE,
  SET_AD_INFO,
  SET_APARTMENT_PLANS,
  SET_FLOOR_PLANS,
  SET_SUMMER_COMMUNALS_COST,
  SET_WINTER_COMMUNALS_COST,
  SET_ADDRESS,
  SET_LAND_REGISTRY_SEARCH,
  SET_CADASTER_NUMBER,
  SET_ESTATE_REGISTER_NUMBER,
  SET_POSITION,
  SET_ZOOM,
  SET_STREETVIEW_POV,
  SET_AD_CATEGORY,
  SET_BANK,
  SET_MY_OBJECT_STATUS,
  SET_MY_OBJECT_PROMO_CODE,
  GET_MY_OBJECT,
  GET_MY_OBJECT_PUBLISHED_SERVICES,
  UPDATE_MY_OBJECT,
  UNPUBLISH_MY_OBJECT,
  DELETE_MY_OBJECT,
  GET_MY_OBJECT_LOCATION,
  GET_MY_OBJECT_IMAGES,
  SETUP_PUBLISHING_PACKAGE,
  GET_PUBLISHING_PACKAGE,
  RESET_OBJECT,
  CREATE_NEW_VIDEO,
  SET_VIDEO,
  REMOVE_VIDEO,
  GET_MY_OBJECT_LIST,
  SET_OBJECT_BOOKING,
  GET_OM_CITY_PARISH_OPTIONS,
  GET_OM_DISTRICT_SETTLEMENT_OPTIONS,
} from '@/constants/actions';
import { OBJECT_TYPES, MY_OBJECTS_PER_PAGE } from '@/constants/object';
import { EXPORT_TO_KV, getMarketingPackageConfig, MARKETING_PACKAGE, PERIOD_PACKAGE } from '@/constants/packages';
import { SIZE_ID_SQUARE_METER } from '@/constants/filters';

import api, { cacheReload, convertIRI, paginatedQuery, parsePagination } from '@/api/City24Api';
import { NameValue } from '@/utils/collections';
import { addressTerm, isAddressTerm } from '@/utils/searchTerms';
import { applyImageFormat } from '@/utils/formatting';
import { fromISOString, toISOString } from '@/utils/date';
import { pick } from '@/utils/object';
import { isPublished } from '@/utils/objectStates';

import { httpErrorfromResponse } from '@/errors/http';
import { getLocale } from '@/selectors/appSelectors';

import DUMMY_IMAGE_1 from '@/img/object/object_1.jpg';
import DUMMY_IMAGE_2 from '@/img/object/object_2.jpg';

import { getMyObject, getMyObjectOriginal } from './MyObjectSelectors';
import { getAreaOptions } from './MyObjectLocationSelectors';
import {
  fetchCitiesParishes,
  fetchDistrictsSettlements,
  fetchCounties,
  fetchTopLevelLocations,
  fetchLocationsByParent,
} from './MyObjectLocationActions';
import { fromUIToAttributes } from './utils';

export function setPosition(position) {
  return {
    type: SET_POSITION,
    position,
  };
}

export function setZoom(zoom) {
  return {
    type: SET_ZOOM,
    zoom,
  };
}

export function setStreetviewPov(pov) {
  return {
    type: SET_STREETVIEW_POV,
    pov,
  };
}

function convertDescriptions(info, originalObject) {
  const descIds = {};
  const descriptions = originalObject.get('descriptions');
  if (descriptions) {
    (Array.isArray(descriptions) ? descriptions : Object.values(descriptions)).forEach((desc) => {
      descIds[desc.locale_code] = `/unit_descriptions/${desc.id}`;
    });
  }
  return info.get('description').reduce((descs, desc, locale) => {
    if (desc.get('description') || desc.get('introduction')) {
      const localeCode = locale.replace('-', '_');
      descs[localeCode] = {
        description: desc.get('description') || null,
        introduction: desc.get('introduction') || null,
        locale_code: localeCode,
      };
      if (descIds[localeCode]) {
        descs[localeCode].id = descIds[localeCode];
      }
    }
    return descs;
  }, {});
}

function convertSlogans(info, originalObject) {
  const sloganIds = {};
  const slogans = originalObject.get('slogans');
  if (slogans) {
    (Array.isArray(slogans) ? slogans : Object.values(slogans)).forEach((sl) => {
      sloganIds[sl.locale_code] = `/slogans/${sl.id}`;
    });
  }
  return info.get('slogan').reduce((sls, sl, locale) => {
    if (sl.get('slogan')) {
      const localeCode = locale.replace('-', '_');
      sls[localeCode] = {
        slogan: sl.get('slogan') || null,
        locale_code: localeCode,
      };
      if (sloganIds[localeCode]) {
        sls[localeCode].id = sloganIds[localeCode];
      }
    }
    return sls;
  }, {});
}

function convertAdditionalInfo(info, originalObject) {
  const additionalInfo = info.get('additionalInfo');
  if (additionalInfo.get('title') || additionalInfo.get('url')) {
    const i = {
      title: additionalInfo.get('title') || null,
      url: additionalInfo.get('url') || null,
    };
    const origInfo = originalObject.get('info');
    if (origInfo.length > 0 && origInfo[0].id) {
      i.id = `/additional_informations/${origInfo[0].id}`;
    }
    return [i];
  }
  return [];
}

function convertVideos(videos, locale) {
  return videos
    .filter((video) => {
      return video.get('url');
    })
    .map((video, id) => {
      return {
        positionIdx: id,
        source: video.get('url'),
        titleNames: [
          {
            locale: locale.replace('-', '_'),
            title: '',
          },
        ],
      };
    });
}

function convertMyObject(myObject, originalObject, rootState) {
  const info = myObject.get('info');
  const objectType = myObject.getIn(['objectType', 'value']);
  const transactionType = myObject.getIn(['transactionType', 'value']);
  const numberValue = (nr) => (nr !== null ? Number(nr) : null);
  const obj = {
    transaction_type: `/transaction_types/${myObject.getIn(['transactionType', 'id'])}`,
    unit_type: `/unit_types/${myObject.getIn(['objectType', 'id'])}`,
    portal: PORTAL,
    price: info.get('price') !== null ? `${info.get('price')}` : null,
    show_price_drop: info.get('showPriceDrop'),
    property_size: numberValue(info.get('size')),
    size_unit_id: SIZE_ID_SQUARE_METER, // square meter
    lot_size: numberValue(info.get('lotSize')),
    lot_size_unit_id: info.getIn(['lotSizeUnit', 'id']),
    room_count: numberValue(info.get('rooms')),
    year_built: numberValue(info.get('yearBuilt')),
    attributes: fromUIToAttributes(objectType, transactionType, info),
    descriptions: convertDescriptions(info, originalObject),
    slogans: convertSlogans(info, originalObject),
    info: convertAdditionalInfo(info, originalObject),
    videos: convertVideos(myObject.getIn(['images', 'videos']), getLocale(rootState)),
  };

  const objectLocation = myObject.get('location');
  const objectAddress = objectLocation.get('address');
  const address = {
    regional_unit: null,
    street: null,
    location_name: objectAddress.get('place') || null,
    house_number: objectAddress.get('houseNr') || null,
    export_house_number: !objectAddress.get('houseNrHidden'),
    cadaster_number: objectLocation.get('cadasterNr'),
    estate_register_number: objectLocation.get('estateRegisterNr'),
  };
  obj.address = address;

  if (objectType === OBJECT_TYPES.Apartment) {
    address.apartment_number = objectAddress.get('apartmentNr') || null;
    address.export_apartment_number = !objectAddress.get('apartmentNrHidden');
  } else {
    address.apartment_number = null;
    address.export_apartment_number = null;
  }

  const settlement = objectAddress.get('districtSettlement');
  const cityParish = objectAddress.get('cityParish');
  const county = objectAddress.get('county');
  if (settlement.get('value')) {
    address.regional_unit = `/regional_units/${settlement.getIn(['extra', 'original'])}`;
  } else if (cityParish.get('value')) {
    address.regional_unit = `/regional_units/${cityParish.getIn(['extra', 'original'])}`;
  } else if (county.get('value')) {
    address.regional_unit = `/regional_units/${county.getIn(['extra', 'original'])}`;
  }

  const street = objectAddress.get('street');
  if (street.get('value')) {
    if (isAddressTerm(street.get('value'), 'street')) {
      const id = street.getIn(['extra', 'original']);
      if (/^[0-9]+$/.test(`${id}`)) {
        address.street = `/regional_units/${id}`;
      } else {
        address.street = `${id}`;
      }
    } else {
      address.street = { name: street.get('name') };
    }
  }

  const coordinates = objectLocation.get('position');

  if (coordinates.get('manual')) {
    obj.latitude = coordinates.getIn(['manual', 'lat']);
    obj.longitude = coordinates.getIn(['manual', 'lng']);
  } else if (coordinates.get('address')) {
    obj.latitude = coordinates.getIn(['address', 'lat']);
    obj.longitude = coordinates.getIn(['address', 'lng']);
  }

  const coordinatesAreSet = obj.latitude !== undefined && obj.longitude !== undefined;
  if (coordinatesAreSet) {
    address.latitude = obj.latitude;
    address.longitude = obj.longitude;
  }

  if (originalObject.get('id')) {
    const originalAddress = originalObject.get('address');
    if (originalAddress && originalAddress.id) {
      address.id = `/addresses/${originalAddress.id}`;
      // Add street ID if original street is new
      if (address.street && address.street.name && originalAddress.street) {
        if (originalAddress.street.new) {
          address.street.id = `/regional_units/${originalAddress.street.id}`;
        }
      }

      // If coordinates were not set, use original values
      if (!coordinatesAreSet) {
        const lat = originalAddress.latitude;
        const lng = originalAddress.longitude;
        obj.latitude = lat;
        obj.longitude = lng;
        address.latitude = lat;
        address.longitude = lng;
      }
    }
  }

  //  Map zoom
  obj.zoom = objectLocation.get('zoom');

  //  Streetview POV
  obj.streetview_params = objectLocation.get('pov');

  return obj;
}

export function saveMyObject() {
  return (dispatch, getState) => {
    // Update my object data in server
    const state = getState();
    const myObject = getMyObject(state);
    const originalObject = getMyObjectOriginal(state);
    const body = convertMyObject(myObject, originalObject, state);
    const id = myObject.get('id');
    const action = id === 'new' ? api.realty.createNewRealty(body) : api.realty.updateRealty(id, body);

    return action.then((res) => {
      if (res.ok) {
        return res.json().then((object) => {
          return dispatch({
            type: UPDATE_MY_OBJECT,
            object,
          });
        });
      }

      return httpErrorfromResponse(res).then((err) => {
        throw err;
      });
    });
  };
}

function findByAlternateIds(collection, id) {
  return collection.find((c) => c.getIn(['extra', 'altIds']).includes(id) || c.getIn(['extra', 'original']) === id);
}

function prefillAddressOptions(dispatch, getState, county, parish, city, village, district) {
  const mappedAddress = {};

  const firstLevelLocation = PORTAL_LV ? county || city : county;

  if (firstLevelLocation) {
    const areas = getAreaOptions(getState());
    let countySelect;
    if (areas.counties.data.size > 0) {
      mappedAddress.county = findByAlternateIds(areas.counties.data, firstLevelLocation) || Map();
      countySelect = Promise.resolve(mappedAddress.county);
      // promises.push(mappedAddress.county);
    } else {
      const fetchAction = PORTAL_LV ? fetchTopLevelLocations() : fetchCounties();
      countySelect = dispatch(fetchAction).then(({ counties }) => {
        mappedAddress.county = findByAlternateIds(counties.data, firstLevelLocation) || Map();
        return mappedAddress.county;
      });
    }

    return countySelect
      .then((county) => {
        if (county.getIn(['extra', 'original'])) {
          const fetchAction = PORTAL_LV
            ? fetchLocationsByParent(county.getIn(['extra', 'original']), GET_OM_CITY_PARISH_OPTIONS)
            : fetchCitiesParishes(county.getIn(['extra', 'original']));
          return dispatch(fetchAction).then(({ citiesParishes }) => {
            mappedAddress.cityParish =
              (city && findByAlternateIds(citiesParishes.data, city)) ||
              (parish && findByAlternateIds(citiesParishes.data, parish)) ||
              (district && findByAlternateIds(citiesParishes.data, district)) ||
              Map();
            return mappedAddress.cityParish || Map();
          });
        }

        return Map();
      })
      .then((cityParish) => {
        if (cityParish.getIn(['extra', 'original'])) {
          const fetchAction = PORTAL_LV
            ? fetchLocationsByParent(cityParish.getIn(['extra', 'original']), GET_OM_DISTRICT_SETTLEMENT_OPTIONS)
            : fetchDistrictsSettlements(cityParish.getIn(['extra', 'original']));
          return dispatch(fetchAction).then(({ settlements }) => {
            if (settlements.data.size > 0) {
              let collection = settlements.data;
              if (collection.first().has('options')) {
                collection = collection.toSeq().flatMap((v) => v.get('options'));
              }

              mappedAddress.districtSettlement = findByAlternateIds(collection, district || city || village) || Map();
            }
          });
        }
      })
      .then(() => mappedAddress);
  }

  return Promise.resolve(mappedAddress);
}

export function presetMyObjectLocation({
  components: { county, parish, city, district, street, village },
  house_number: houseNr = '',
  apartment_number: apartmentNr = '',
  cadaster_number: cadasterNr = '',
  coordinates: { latitude, longitude },
}) {
  return (dispatch, getState) => {
    return prefillAddressOptions(dispatch, getState, county.id, parish?.id, city?.id, village?.id, district?.id).then(
      (prefilled) => {
        return dispatch({
          type: GET_MY_OBJECT_LOCATION,
          address: {
            county: Map(),
            cityParish: Map(),
            districtSettlement: Map(),
            street:
              (street &&
                NameValue({
                  name: street.name,
                  value: addressTerm('street', street.id),
                  extra: Map({ original: street.id }),
                })) ||
              NameValue(),
            houseNr,
            apartmentNr,
            latitude,
            longitude,
            ...prefilled,
          },
          cadasterNr,
        });
      }
    );
  };
}

function fetchMyObjectLocation(dispatch, getState, { address, latitude, longitude, zoom, streetview_params }) {
  if (address) {
    const getId = (item) => (item && item.id) || convertIRI(item);
    prefillAddressOptions(
      dispatch,
      getState,
      getId(address.county),
      getId(address.parish),
      getId(address.city),
      getId(address.village),
      getId(address.district)
    ).then((prefilled) => {
      dispatch({
        type: GET_MY_OBJECT_LOCATION,
        address: {
          id: address.id,
          county: Map(),
          cityParish: Map(),
          districtSettlement: Map(),
          street:
            (address.street &&
              NameValue({
                name: address.street.name,
                value: addressTerm('street', address.street.id),
                extra: Map({ original: address.street.id }),
              })) ||
            NameValue(),
          place: address.location_name || '',
          houseNr: address.house_number || '',
          houseNrHidden: !address.export_house_number || false,
          apartmentNr: address.apartment_number || '',
          apartmentNrHidden: !address.export_apartment_number || false,
          latitude,
          longitude,
          ...prefilled,
        },
        cadasterNr: address.cadaster_number,
        estateRegisterNr: address.estate_register_number,
      });
    });

    //  Set streetview params
    dispatch(setStreetviewPov(streetview_params));

    //  Set zoom
    if (zoom !== null) {
      dispatch({
        type: SET_ZOOM,
        zoom,
      });
    }
  }
}

export function fetchMyObjectImages(id) {
  return (dispatch) => {
    return api.realty
      .getImages(id)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        throw httpErrorfromResponse(res, { id });
      })
      .then((images) => {
        images.sort((a, b) => a.position_idx - b.position_idx);

        return dispatch({
          type: GET_MY_OBJECT_IMAGES,
          images,
        });
      });
  };
}

export function fetchMyObject(id, idType = 'id') {
  const query = {};
  if (idType === 'guid') {
    query.guid = true;
  } else if (idType === 'friendlyId') {
    query.friendlyId = true;
  }
  return (dispatch, getState) => {
    return api.realty
      .getPrivateRealtyDetails(id, query)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        return httpErrorfromResponse(res, { id, idType }).then((err) => {
          throw err;
        });
      })
      .then((object) => {
        if (object.id) {
          object.transaction_type = convertIRI(object.transaction_type);
          fetchMyObjectLocation(dispatch, getState, object);
        }
        return dispatch({
          type: GET_MY_OBJECT,
          object,
        });
      });
  };
}

export function fetchMyObjectList(reload = false, page = 1) {
  return (dispatch) => {
    const { userId } = parseTokenPayload();
    const query = paginatedQuery(MY_OBJECTS_PER_PAGE, page, {
      statusId: [
        MODEL_STATUSES.NEW,
        MODEL_STATUSES.VALID,
        MODEL_STATUSES.PUBLISHED,
        MODEL_STATUSES.EXPIRED,
        MODEL_STATUSES.ARCHIVED,
        MODEL_STATUSES.USER_ARCHIVED,
      ],
      order: {
        dateModified: 'DESC',
      },
    });
    return api.publishing
      .getMyObjects(userId, query, reload ? cacheReload() : {})
      .then((res) => (res.ok ? Promise.all([res.json(), parsePagination(res)]) : [[], parsePagination(res)]))
      .then(([objects, pagination]) => {
        return dispatch({
          type: GET_MY_OBJECT_LIST,
          objects,
          totalItems: pagination.total,
        });
      });
  };
}

export function setMyObjectStatus(status) {
  return {
    type: SET_MY_OBJECT_STATUS,
    status,
  };
}

export function setMyObjectPromoCode(code) {
  return {
    type: SET_MY_OBJECT_PROMO_CODE,
    code,
  };
}

// LOCATION

export function setLandRegistrySearch(searchTerm) {
  return {
    type: SET_LAND_REGISTRY_SEARCH,
    searchTerm,
  };
}

export function setAddress(address) {
  return {
    type: SET_ADDRESS,
    address,
  };
}

export function setCadasterNumber(cadasterNr) {
  return {
    type: SET_CADASTER_NUMBER,
    cadasterNr,
  };
}

export function setEstateRegisterNumber(estateRegisterNr) {
  return {
    type: SET_ESTATE_REGISTER_NUMBER,
    estateRegisterNr,
  };
}

// PRODUCTS

export function setPeriodPackage(periodPackage) {
  let pkg = periodPackage;
  if (periodPackage.get('selected') === PERIOD_PACKAGE.xl) {
    pkg = pkg.setIn(['kvExportService', 'selected'], false);
  }
  return {
    type: SET_PERIOD_PACKAGE,
    periodPackage: pkg,
  };
}

export function updatePackage(id, conf) {
  return {
    type: UPDATE_PRODUCT_CONF,
    id,
    conf,
  };
}

export function setAutoRenewal(autoRenewal) {
  return {
    type: SET_AUTO_RENEWAL,
    autoRenewal,
  };
}

function mapMediaPublishing(publishing, includeFreeServices = false) {
  const services = [];
  const periodServices = [];

  (includeFreeServices
    ? publishing.publishing_services
    : publishing.publishing_services.filter((service) => Number(service.amount) > 0.0)
  ).forEach((service) => {
    const mapped = {
      type: service.service_type,
      price: Number(service.amount),
      period: service.publish_price?.period,
      startDate: service.start_date,
      endDate: service.end_date,
      paymentId: service.payment_id,
      active: service.active_service ?? true,
      paid: service.paid,
      statusId: service.status_id,
    };
    switch (service.service_type) {
      case 'BASE_CITY24_MP':
        periodServices.push(mapped);
        break;
      case 'AD_LEVEL':
        mapped.type = MARKETING_PACKAGE.adLevel;
        mapped.level = service.level;
        mapped.period = service.period;
        services.push(mapped);
        break;
      case 'INTERNAL_INFOBLOCK':
        mapped.type = MARKETING_PACKAGE.fromOwner;
        services.push(mapped);
        break;
      case 'SPECIAL_OFFER':
        mapped.type = MARKETING_PACKAGE.specialOffer;
        services.push(mapped);
        break;
      case 'EXTERNAL_INFOBLOCK':
        if (PORTAL_LV) {
          mapped.type = MARKETING_PACKAGE.mediaCampaign;
          services.push(mapped);
        }
        break;
      case EXPORT_TO_KV:
        services.push(mapped);
        break;
      default:
        break;
    }
  });
  return {
    id: publishing.id,
    periodServices,
    services,
  };
}

export function applyPromoCode(publishingId, promoCode) {
  return (dispatch) => {
    api.publishing
      .applyPromoCode(
        publishingId,
        {},
        {
          promoCode,
        }
      )
      .then((res) => (res.ok ? res.json() : {}))
      .then((mediaPublishing) => {
        return dispatch({
          type: GET_PUBLISHING_PACKAGE,
          ...mapMediaPublishing(mediaPublishing),
        });
      })
      .then(() => {
        return dispatch({
          type: SET_MY_OBJECT_PROMO_CODE,
          code: promoCode,
        });
      });
  };
}

export function setupPublishingPackage(objectId, periodService, optional, promoCode) {
  return (dispatch) => {
    const services = [];

    if (periodService?.get('selected')) {
      services.push({
        service_type: 'BASE_CITY24_MP',
        period: periodService.get('period'),
      });
      const kvExportService = periodService.get('kvExportService');
      if (kvExportService.get('selected')) {
        services.push({
          service_type: EXPORT_TO_KV,
          period: periodService.get('period'),
        });
      }
    }

    const adLevel = optional.get(MARKETING_PACKAGE.adLevel);
    if (adLevel.get('selected')) {
      const { serviceName } = getMarketingPackageConfig(MARKETING_PACKAGE.adLevel);
      services.push({
        service_type: serviceName,
        period: 1,
        amount: adLevel.get('period'),
        level: adLevel.get('level'),
      });
    }

    [MARKETING_PACKAGE.specialOffer, MARKETING_PACKAGE.fromOwner, MARKETING_PACKAGE.mediaCampaign].forEach(
      (pkgName) => {
        const pkg = optional.get(pkgName);
        if (pkg.get('selected')) {
          const { serviceName } = getMarketingPackageConfig(pkgName);
          services.push({
            service_type: serviceName,
            period: pkg.get('period'),
          });
        }
      }
    );

    if (services.length === 0) {
      throw new Error('No services were added');
    }

    return api.publishing
      .createPublishing({
        realty: `/realties/${objectId}`,
        portal: PORTAL,
        publishing_services: services,
        promoCode,
      })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        throw httpErrorfromResponse(res, { objectId });
      })
      .then((publishing) => {
        return dispatch({
          type: SETUP_PUBLISHING_PACKAGE,
          active: isPublished(publishing.status_id),
          startDate: publishing.start_date,
          endDate: publishing.end_date,
          ...mapMediaPublishing(publishing),
        });
      });
  };
}

export function fetchPublishingPackage(objectId) {
  return (dispatch) => {
    return api.publishing
      .getPublishings({
        realty: objectId,
        status: 'ready',
      })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        throw httpErrorfromResponse(res, { objectId });
      })
      .then((publishings) => {
        if (publishings.length === 0) {
          return dispatch({ type: GET_PUBLISHING_PACKAGE });
        }
        const publishing = publishings[0];
        return dispatch({
          type: GET_PUBLISHING_PACKAGE,
          active: isPublished(publishing.status_id),
          startDate: publishing.start_date,
          endDate: publishing.end_date,
          ...mapMediaPublishing(publishing),
        });
      });
  };
}

export function fetchMyObjectPublishings(objectId) {
  return (dispatch) => {
    const resetAction = () =>
      dispatch({
        type: GET_MY_OBJECT_PUBLISHED_SERVICES,
        active: false,
        startDate: null,
        endDate: null,
        periodServices: List(),
        services: Map(),
      });

    resetAction();
    return api.publishing
      .getPublishings({
        realty: objectId,
        status: 'recentlyActive',
      })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw httpErrorfromResponse(res, { objectId });
      })
      .then((publishings) => {
        if (publishings.length < 1) {
          return resetAction();
        }
        const publishing = publishings[0];

        const { services, periodServices } = mapMediaPublishing(publishing, true);
        const activePeriodServices = periodServices.filter((srv) => fromISOString(srv.endDate) > new Date());
        activePeriodServices.sort((a, b) => (a.startDate > b.startDate ? 1 : -1));
        const activeServices = {};
        services
          .filter((service) => service.paid)
          .forEach((service) => {
            if (service.type === EXPORT_TO_KV) {
              const periodSrv = activePeriodServices.find((s) => s.paymentId === service.paymentId);
              if (periodSrv) periodSrv.kvExportService = { selected: true, ...service };
              return;
            }
            activeServices[service.type] = (activeServices[service.type] || List()).push(Map(service));
          });

        return dispatch({
          type: GET_MY_OBJECT_PUBLISHED_SERVICES,
          active: isPublished(publishing.status_id),
          startDate: publishing.start_date,
          endDate: publishing.end_date,
          periodServices: fromJS(activePeriodServices),
          services: Map(activeServices),
        });
      });
  };
}

// CATEGORY

export function setCategory(category) {
  return {
    type: SET_AD_CATEGORY,
    category,
  };
}

// IMAGES

export function uploadObjectImages(objectId, images) {
  return (dispatch) => {
    return Promise.all(
      images.map((imgPromise) =>
        imgPromise
          .then(({ file, metadata }) => {
            const body = new FormData();
            body.append('image', file);
            body.append('metadata', JSON.stringify(metadata));

            return api.realty.addImage(objectId, body).then((res) => {
              if (res.ok) {
                return res.json();
              }

              throw httpErrorfromResponse(res, { file });
            });
          })
          .catch((err) => {
            Sentry.configureScope((scope) => {
              scope.setExtras({ objectId });
              Sentry.captureException(err);
            });

            return err;
          })
      )
    ).then((results) => {
      const imageList = [];
      const failed = [];

      results.forEach((result) => {
        if (result instanceof Error) {
          failed.push(result);
        } else {
          imageList.push(result);
        }
      });

      return dispatch({
        type: UPLOAD_OBJECT_IMAGES,
        images: imageList,
        failed,
      });
    });
  };
}

export function sortImages(objectId, images, { oldIndex, newIndex }) {
  return (dispatch) => {
    const newImages = images.slice();
    newImages.splice(oldIndex, 1);
    newImages.splice(newIndex, 0, images[oldIndex]);

    // Dispatch sorted images for UI update
    dispatch({
      type: SORT_IMAGES,
      images: newImages,
      fake: true,
    });
    const body = newImages.map((img, i) => ({
      id: `/realty_images/${img.id}`,
      position_idx: i,
      image_type: i === 0 ? 'main_image' : 'normal_image',
    }));
    return api.realty
      .updateImages(objectId, body)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        throw httpErrorfromResponse(res);
      })
      .then((apiImages) => {
        apiImages.sort((a, b) => a.position_idx - b.position_idx);
        return dispatch({
          type: SORT_IMAGES,
          images: apiImages,
          fake: false,
        });
      })
      .catch((err) => {
        // Revert to old images in case of error
        dispatch({
          type: SORT_IMAGES,
          images,
          error: err,
          fake: false,
        });
        Sentry.withScope((scope) => {
          scope.setExtras({ objectId });
          Sentry.captureException(err);
        });
      });
  };
}

/**
 *
 * (TODO: proper typings added later)
 * @param {RealtyImageEditing} image
 * @param {ImageEditOptions} imageEdits
 * @param {boolean} upload
 * @returns
 */
export function updateObjectImage(image, imageEdits = {}, upload = true) {
  if (!upload) {
    const newImage = { ...image };
    if (imageEdits.rotate) {
      newImage.rotate = imageEdits.rotate.degrees;
    }
    return {
      type: UPDATE_OBJECT_IMAGE,
      newImage,
    };
  }

  return (dispatch) => {
    const body = {
      image,
      imageUrl: applyImageFormat(image.url, 'original', 'object'),
      ...imageEdits,
    };

    return api.realty
      .updateImage(image.id, body)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        throw httpErrorfromResponse(res, { body });
      })
      .then((newImage) => {
        return dispatch({
          type: UPDATE_OBJECT_IMAGE,
          newImage: { ...newImage, rotate: 0 },
        });
      })
      .catch((err) => {
        Sentry.withScope((scope) => {
          scope.setExtras({ body });
          Sentry.captureException(err);
        });
      });
  };
}

export function deleteObjectImage(image) {
  return (dispatch) => {
    return api.realty
      .deleteImage(image.id)
      .then((res) => {
        if (res.ok) {
          return image;
        }

        throw httpErrorfromResponse(res);
      })
      .then(() => {
        return dispatch({
          type: DELETE_OBJECT_IMAGE,
          image,
        });
      })
      .catch((err) => {
        Sentry.withScope((scope) => {
          scope.setExtras({ id: image.id });
          Sentry.captureException(err);
        });
      });
  };
}

// AD INFO

export function setAdInfo(key, value) {
  return {
    type: SET_AD_INFO,
    key,
    value,
  };
}

export function setApartmentPlans(files) {
  return {
    type: SET_APARTMENT_PLANS,
    files,
  };
}

export function uploadApartmentPlans(files) {
  return (dispatch) => {
    // UPLOAD FILES
    const dummyFiles = fromJS([
      { id: 0, src: DUMMY_IMAGE_1 },
      { id: 1, src: DUMMY_IMAGE_2 },
    ]);

    dispatch(setApartmentPlans(dummyFiles));
  };
}

export function setFloorPlans(files) {
  return {
    type: SET_FLOOR_PLANS,
    files,
  };
}

export function uploadFloorPlans(files) {
  return (dispatch) => {
    // UPLOAD FILES
    const dummyFiles = fromJS([
      { id: 0, src: DUMMY_IMAGE_1 },
      { id: 1, src: '../../some_file.pdf' },
    ]);

    dispatch(setFloorPlans(dummyFiles));
  };
}

export function setVideo(index, url) {
  return {
    type: SET_VIDEO,
    index,
    url,
  };
}

export function createNewVideo() {
  return {
    type: CREATE_NEW_VIDEO,
  };
}

export function removeVideo(index) {
  return {
    type: REMOVE_VIDEO,
    index,
  };
}

export function uploadSummerCommunalCost(file) {
  return (dispatch) => {
    // UPLOAD FILES
    const dummyFile = fromJS({ id: 0, src: DUMMY_IMAGE_1 });

    dispatch({
      type: SET_SUMMER_COMMUNALS_COST,
      file: dummyFile,
    });
  };
}

export function uploadWinterCommunalCost(file) {
  return (dispatch) => {
    // UPLOAD FILES
    const dummyFile = fromJS({ id: 0, src: DUMMY_IMAGE_1 });

    dispatch({
      type: SET_WINTER_COMMUNALS_COST,
      file: dummyFile,
    });
  };
}

// CONFIRM

export function setBank(bank) {
  return {
    type: SET_BANK,
    bank,
  };
}

export function resetMyObject() {
  return {
    type: RESET_OBJECT,
  };
}

export function unpublishMyObject(id) {
  return (dispatch) => {
    return api.publishing
      .changePublishingStatus(id, { action: 'cancel' })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        throw httpErrorfromResponse(res);
      })
      .then((obj) => {
        return dispatch({
          type: UNPUBLISH_MY_OBJECT,
          id,
          status_id: obj.status_id,
        });
      })
      .finally(() => {
        dispatch(fetchMyObjectList(true));
      });
  };
}

export function deleteMyObject(id) {
  return (dispatch) => {
    return api.publishing
      .changePublishingStatus(id, { action: 'delete' })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        throw httpErrorfromResponse(res);
      })
      .then((obj) => {
        return dispatch({
          type: DELETE_MY_OBJECT,
          id,
          status_id: obj.status_id,
        });
      })
      .finally(() => {
        dispatch(fetchMyObjectList(true));
      });
  };
}

export function setObjectBooking(id, date) {
  return (dispatch) => {
    const realtyId = `${id}`;
    return api.realty
      .setBooking({ realty: realtyId, booked_until: date ? toISOString(date) : null })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw httpErrorfromResponse(res);
      })
      .then((booking) => {
        return dispatch({
          type: SET_OBJECT_BOOKING,
          id: realtyId,
          booked: pick(booking, 'booked_until', 'booking_comment'),
        });
      });
  };
}
