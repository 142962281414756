import { List, Map, merge, Range } from 'immutable';
import { TransactionTypeLiteral } from '@city24/common/enums/realty/TransactionType';
import { UnitTypeLiteral } from '@city24/common/enums/realty/UnitType';
import { FinanceCenterBank, MultiBankCalculatorItem, SingleBankCalculatorItem } from '@/types/finance';
import { isCampaignRunning } from '@/constants/campaign';
import { InBankCalculatorType } from '@/components/integrations/Inbank/InbankCalculator';
import { INBANK_LAND_OFFER_LINKS, INBANK_OFFER_LINKS, INBANK_RENT_OFFER_LINKS } from '@/constants/integrations';

const yearsName = 'loanCalc.period.years';
const monthsName = 'loanCalc.period.months';

export const LOAN_APPLICATION_TYPE = {
  homeLoan: 'home-loan',
  consumerLoan: 'consumer-loan',
} as const;

export const LOAN_APPLICANTS_COUNT = {
  alone: 'alone',
  withCoBorrower: 'with-a-co-borrower',
} as const;

export const SELF_FINANCE_UNIT = {
  percent: 'percent',
  eur: 'eur',
} as const;

export const CUSTOM_LV_BIGBANK_CAMPAIGN_RUNNING = isCampaignRunning({
  startDate: new Date('2023-12-20'),
  endDate: new Date('2023-12-31'),
});
export const CUSTOM_LV_LUMINOR_CAMPAIGN_RUNNING = isCampaignRunning({
  startDate: process.env.NEXT_PUBLIC_APP_ENV === 'development' ? new Date('2023-05-01') : new Date('2023-06-01'),
  endDate: new Date('2023-12-31'),
});

export const MULTI_BANK_HOME_LOAN_CALCULATOR: MultiBankCalculatorItem[] = PORTAL_EE
  ? [
      {
        name: 'coop',
        color: '#0078D2',
        footerDisclaimer: 'footer.disclaimer.coop',
        defaults: {
          [UnitTypeLiteral.NEW_PROJECT]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0,
            fixedInterestRate: 1.9,
            accountMonthlyFee: 0,
          },
          [UnitTypeLiteral.APARTMENT]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0,
            fixedInterestRate: 1.9,
            accountMonthlyFee: 0,
          },
          [UnitTypeLiteral.HOUSE]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0,
            fixedInterestRate: 1.9,
            accountMonthlyFee: 0,
          },
          [UnitTypeLiteral.LAND]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0,
            fixedInterestRate: 1.9,
            accountMonthlyFee: 0,
          },
          [UnitTypeLiteral.HOUSE_SHARE]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0,
            fixedInterestRate: 1.9,
            accountMonthlyFee: 0,
          },
          [UnitTypeLiteral.COTTAGE_OR_VILLA]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0,
            fixedInterestRate: 1.9,
            accountMonthlyFee: 0,
          },
          [UnitTypeLiteral.GARAGE]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0,
            fixedInterestRate: 1.9,
            accountMonthlyFee: 0,
          },
          [UnitTypeLiteral.MODULAR_HOUSE]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0,
            fixedInterestRate: 1.9,
            accountMonthlyFee: 0,
          },
        },
        links: {
          button: {
            et: 'https://www.cooppank.ee/kodulaenu-eripakkumine-lepingutasu-0eu?utm_source=city24&utm_medium=calculator&utm_campaign=city24',
            en: 'https://www.cooppank.ee/en/home-loan-special-offer-agreement-fee-0eu?utm_source=city24&utm_medium=calculator&utm_campaign=city24',
            ru: 'https://www.cooppank.ee/ru/specialnoe-predlozhenie-po-zhilischnomu-kreditu-plata-za-dogovor-0-evro?utm_source=city24&utm_medium=calculator&utm_campaign=city24',
          },
          link: {
            et: 'https://www.cooppank.ee/kodulaenu-eripakkumine-lepingutasu-0eu?utm_source=city24&utm_medium=calculator&utm_campaign=city24',
            en: 'https://www.cooppank.ee/en/home-loan-special-offer-agreement-fee-0eu?utm_source=city24&utm_medium=calculator&utm_campaign=city24',
            ru: 'https://www.cooppank.ee/ru/specialnoe-predlozhenie-po-zhilischnomu-kreditu-plata-za-dogovor-0-evro?utm_source=city24&utm_medium=calculator&utm_campaign=city24',
          },
        },
      },
      {
        name: 'luminor_ee',
        color: '#ee4472',
        footerDisclaimer: 'footer.disclaimer.luminor',
        defaults: {
          [UnitTypeLiteral.NEW_PROJECT]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0,
            fixedInterestRate: 1.9,
            accountMonthlyFee: 0,
          },
          [UnitTypeLiteral.APARTMENT]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0,
            fixedInterestRate: 1.9,
            accountMonthlyFee: 0,
          },
          [UnitTypeLiteral.HOUSE]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0,
            fixedInterestRate: 1.9,
            accountMonthlyFee: 0,
          },
          [UnitTypeLiteral.LAND]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0,
            fixedInterestRate: 1.9,
            accountMonthlyFee: 0,
          },
          [UnitTypeLiteral.HOUSE_SHARE]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0,
            fixedInterestRate: 1.9,
            accountMonthlyFee: 0,
          },
          [UnitTypeLiteral.COTTAGE_OR_VILLA]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0,
            fixedInterestRate: 1.9,
            accountMonthlyFee: 0,
          },
          [UnitTypeLiteral.GARAGE]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0,
            fixedInterestRate: 1.9,
            accountMonthlyFee: 0,
          },
          [UnitTypeLiteral.MODULAR_HOUSE]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0,
            fixedInterestRate: 1.9,
            accountMonthlyFee: 0,
          },
        },
        links: {
          button: {
            et: 'https://luminor.ee/k%C3%BCsilaenupakkumist?utm_source=city24&utm_medium=object_view&utm_campaign=mortgage&utm_term=button&utm_content=variant1',
            en: 'https://luminor.ee/askforloanoffer?utm_source=city24&utm_medium=object_view&utm_campaign=mortgage&utm_term=button&utm_content=variant1',
            ru: 'https://luminor.ee/sprosite-predlozheniye?utm_source=city24&utm_medium=object_view&utm_campaign=mortgage&utm_term=button&utm_content=variant1',
          },
          link: {
            et: 'https://luminor.ee/era/kodulaen',
            en: 'https://luminor.ee/retail/mortgage-loan',
            ru: 'https://luminor.ee/chastnye/zhilishchnyy-kredit',
          },
        },
      },
      {
        name: 'lhv',
        color: '#3B3B47',
        footerDisclaimer: 'footer.disclaimer.lhv',
        defaults: {
          [UnitTypeLiteral.NEW_PROJECT]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0,
            fixedInterestRate: 1.9,
            accountMonthlyFee: 0,
          },
          [UnitTypeLiteral.APARTMENT]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0,
            fixedInterestRate: 1.9,
            accountMonthlyFee: 0,
          },
          [UnitTypeLiteral.HOUSE]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0,
            fixedInterestRate: 1.9,
            accountMonthlyFee: 0,
          },
          [UnitTypeLiteral.LAND]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0,
            fixedInterestRate: 1.9,
            accountMonthlyFee: 0,
          },
          [UnitTypeLiteral.HOUSE_SHARE]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0,
            fixedInterestRate: 1.9,
            accountMonthlyFee: 0,
          },
          [UnitTypeLiteral.COTTAGE_OR_VILLA]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0,
            fixedInterestRate: 1.9,
            accountMonthlyFee: 0,
          },
          [UnitTypeLiteral.GARAGE]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0,
            fixedInterestRate: 1.9,
            accountMonthlyFee: 0,
          },
          [UnitTypeLiteral.MODULAR_HOUSE]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0,
            fixedInterestRate: 1.9,
            accountMonthlyFee: 0,
          },
        },
        links: {
          button: {
            et: 'https://www.lhv.ee/kodulaen?utm_source=city24&utm_medium=kalkulaator&utm_campaign=city24-2023',
            en: 'https://www.lhv.ee/en/home-loan?utm_source=city24&utm_medium=kalkulaator&utm_campaign=city24-2023',
            ru: 'https://www.lhv.ee/ru/zhilisnyj-kredit?utm_source=city24&utm_medium=kalkulaator&utm_campaign=city24-2023',
          },
          link: {
            et: 'https://www.lhv.ee/et/kodulaen?utm_source=city24&utm_medium=kalkulaator&utm_campaign=city24-2023#tingimused-tab',
            en: 'https://www.lhv.ee/en/home-loan?utm_source=city24&utm_medium=kalkulaator&utm_campaign=city24-2023#terms-tab',
            ru: 'https://www.lhv.ee/ru/zhilisnyj-kredit?utm_source=city24&utm_medium=kalkulaator&utm_campaign=city24-2023#uslovija-tab',
          },
        },
      },
    ]
  : [
      {
        name: 'seb',
        color: '#0099FF',
        defaults: {
          [UnitTypeLiteral.NEW_PROJECT]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0.5,
            fixedInterestRate: 1.8,
            accountMonthlyFee: 2,
          },
          [UnitTypeLiteral.APARTMENT]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0.5,
            fixedInterestRate: 1.8,
            accountMonthlyFee: 2,
          },
          [UnitTypeLiteral.HOUSE]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0.5,
            fixedInterestRate: 1.8,
            accountMonthlyFee: 2,
          },
          [UnitTypeLiteral.LAND]: {
            period: 15,
            selfFinancing: 30,
            loanCommissionFee: 0.5,
            fixedInterestRate: 2.5,
            accountMonthlyFee: 2,
          },
          [UnitTypeLiteral.COMMERCIAL]: {
            period: 15,
            selfFinancing: 30,
            loanCommissionFee: 0.5,
            fixedInterestRate: 3,
            accountMonthlyFee: 2,
          },
        },
        links: {
          button: {
            lv: 'https://www.seb.lv/privatpersonam/krediti/majokla-kredits#appform_loan_private_home',
            en: 'https://www.seb.lv/en/private/loans/home-loan#appform_loan_private_home',
            ru: 'https://www.seb.lv/ru/chastnym-licam/kredity/ipotechnyy-kredit-dlya-pokupki-stroitelstva-i-remonta-zhilya#appform_loan_private_home',
          },
          link: {
            lv: 'https://www.seb.lv/privatpersonam/krediti/majokla-kredits?utm_source=city24&utm_campaign=AO-Homeloan-City24&utm_content=utm_cons_priv_PLMH_banner_LV__&utm_p=PLMH',
            en: 'https://www.seb.lv/en/private/loans/home-loan?utm_source=city24&utm_campaign=AO-Homeloan-City24&utm_content=utm_cons_priv_PLMH_banner_LV__&utm_p=PLMH',
            ru: 'https://www.seb.lv/ru/chastnym-licam/kredity/ipotechnyy-kredit-dlya-pokupki-stroitelstva-i-remonta-zhilya?utm_source=city24&utm_campaign=AO-Homeloan-City24&utm_content=utm_cons_priv_PLMH_banner_LV__&utm_p=PLMH',
          },
        },
      },
      {
        name: 'swedbank',
        color: '#ff5f00',
        defaults: {
          [UnitTypeLiteral.NEW_PROJECT]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0,
            fixedInterestRate: 1.65,
            accountMonthlyFee: 0,
          },
          [UnitTypeLiteral.APARTMENT]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 1.2,
            fixedInterestRate: 1.99,
            accountMonthlyFee: 0,
            /** custom override for apartments built after 2000  */
            fixedInterestOverYear2000: 1.65,
            /** used to reset fixedInterestRate value */
            fixedInterestDefault: 1.99,
          },
          [UnitTypeLiteral.HOUSE]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 1.2,
            fixedInterestRate: 1.99,
            accountMonthlyFee: 0,
            /** custom override for houses built after 2000  */
            fixedInterestOverYear2000: 1.79,
            /** used to reset fixedInterestRate value */
            fixedInterestDefault: 1.99,
          },
          [UnitTypeLiteral.LAND]: {
            period: 20,
            selfFinancing: 50,
            loanCommissionFee: 1.2,
            fixedInterestRate: 2.8,
            accountMonthlyFee: 0,
          },
          [UnitTypeLiteral.COMMERCIAL]: {
            period: 10,
            selfFinancing: 20,
            loanCommissionFee: 0.1,
            fixedInterestRate: 3.5,
            accountMonthlyFee: 0,
          },
        },
        links: {
          button: {
            lv: 'https://www.swedbank.lv/private/credit/loans/home?language=LAT&cid=%swedbank-cid%',
            en: 'https://www.swedbank.lv/private/credit/loans/home?language=ENG&cid=%swedbank-cid%',
            ru: 'https://www.swedbank.lv/private/credit/loans/home?language=RUS&cid=%swedbank-cid%',
          },
          link: {
            lv: 'https://www.swedbank.lv/private/credit/loans/home?language=LAT',
            en: 'https://www.swedbank.lv/private/credit/loans/home?language=ENG',
            ru: 'https://www.swedbank.lv/private/credit/loans/home?language=RUS',
          },
        },
      },
      {
        name: 'luminor',
        color: '#e74972',
        defaults: {
          [UnitTypeLiteral.NEW_PROJECT]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 1,
            loanCommissionFeeMin: 100,
            fixedInterestRate: 1.59,
            accountMonthlyFee: 1.3,
            loanRegistrationFee: true,
            loanRegistrationFeeAddition: 14.23,
          },
          [UnitTypeLiteral.APARTMENT]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 1,
            loanCommissionFeeMin: 100,
            fixedInterestRate: 1.69,
            accountMonthlyFee: 1.3,
            loanRegistrationFee: true,
            loanRegistrationFeeAddition: 14.23,
          },
          [UnitTypeLiteral.HOUSE]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 1,
            loanCommissionFeeMin: 100,
            fixedInterestRate: 1.59,
            accountMonthlyFee: 1.3,
            loanRegistrationFee: true,
            loanRegistrationFeeAddition: 14.23,
          },
          [UnitTypeLiteral.LAND]: {
            period: 7,
            maxPeriod: 7,
            selfFinancing: 35,
            maximumLoanAmount: 50000,
            loanCommissionFee: 1,
            loanCommissionFeeMin: 100,
            fixedInterestRate: 2,
            accountMonthlyFee: 1.3,
            loanRegistrationFee: true,
            loanRegistrationFeeAddition: 14.23,
          },
        },
        links: {
          button: {
            lv: 'https://www.luminor.lv/lv/pieteikums-hipotekara-kredita-sanemsanai?city24=city24&utm_source=City24&utm_medium=calculator&utm_campaign=mortgage_loan#informacija-par-klientu',
            en: 'https://www.luminor.lv/en/housing-loan-application?city24=city24&utm_source=City24&utm_medium=calculator&utm_campaign=mortgage_loan#customer-information',
            ru: 'https://www.luminor.lv/ru/zayavlenie-na-poluchenie-ipotechnogo-kredita?city24=city24&utm_source=City24&utm_medium=calculator&utm_campaign=mortgage_loan#informatsiya-o-kliente',
          },
          link: {
            lv: 'https://www.luminor.lv/lv/pieteikums-hipotekara-kredita-sanemsanai?city24=city24&utm_source=City24&utm_medium=calculator&utm_campaign=mortgage_loan#informacija-par-klientu',
            en: 'https://www.luminor.lv/en/housing-loan-application?city24=city24&utm_source=City24&utm_medium=calculator&utm_campaign=mortgage_loan#customer-information',
            ru: 'https://www.luminor.lv/ru/zayavlenie-na-poluchenie-ipotechnogo-kredita?city24=city24&utm_source=City24&utm_medium=calculator&utm_campaign=mortgage_loan#informatsiya-o-kliente',
          },
        },
      },
      {
        name: 'citadele',
        color: '#e3112c',
        defaults: {
          [UnitTypeLiteral.NEW_PROJECT]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 0,
            fixedInterestRate: 1.5,
            accountMonthlyFee: 0,
          },
          [UnitTypeLiteral.APARTMENT]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 1,
            fixedInterestRate: 1.8,
            accountMonthlyFee: 0,
          },
          [UnitTypeLiteral.HOUSE]: {
            period: 30,
            selfFinancing: 15,
            loanCommissionFee: 1,
            fixedInterestRate: 1.8,
            accountMonthlyFee: 0,
          },
          [UnitTypeLiteral.LAND]: {
            period: 10,
            selfFinancing: 40,
            loanCommissionFee: 1,
            fixedInterestRate: 3.5,
            accountMonthlyFee: 0,
          },
          [UnitTypeLiteral.COMMERCIAL]: {
            period: 20,
            selfFinancing: 30,
            loanCommissionFee: 1,
            fixedInterestRate: 3.2,
            accountMonthlyFee: 0,
          },
        },
        links: {
          button: {
            lv: 'https://www.citadele.lv/lv/private/mortgage/?utm_source=city24&utm_medium=display&utm_term=calculator&utm_content=CTA_calculator_LV&utm_campaign=city24_mortgage',
            en: 'https://www.citadele.lv/en/private/mortgage/?utm_source=city24&utm_medium=display&utm_term=calculator&utm_content=CTA_calculator_EN&utm_campaign=city24_mortgage',
            ru: 'https://www.citadele.lv/ru/private/mortgage/?utm_source=city24&utm_medium=display&utm_term=calculator&utm_content=CTA_calculator_RU&utm_campaign=city24_mortgage',
          },
          link: {
            lv: 'https://www.citadele.lv/lv/private/mortgage/?utm_source=city24&utm_medium=display&utm_term=calculator&utm_content=CTA_calculator_LV&utm_campaign=city24_mortgage',
            en: 'https://www.citadele.lv/en/private/mortgage/?utm_source=city24&utm_medium=display&utm_term=calculator&utm_content=CTA_calculator_EN&utm_campaign=city24_mortgage',
            ru: 'https://www.citadele.lv/ru/private/mortgage/?utm_source=city24&utm_medium=display&utm_term=calculator&utm_content=CTA_calculator_RU&utm_campaign=city24_mortgage',
          },
        },
      },
    ];

if (PORTAL_LV && new Date() <= new Date('2023-12-31')) {
  MULTI_BANK_HOME_LOAN_CALCULATOR.push({
    name: 'bigbank',
    color: '#0ccca2',
    defaults: {
      [UnitTypeLiteral.NEW_PROJECT]: {
        period: 30,
        selfFinancing: CUSTOM_LV_BIGBANK_CAMPAIGN_RUNNING ? 10 : 15,
        loanCommissionFee: 0,
        fixedInterestRate: 1.65,
        accountMonthlyFee: 2.5,
      },
      [UnitTypeLiteral.APARTMENT]: {
        period: 30,
        selfFinancing: CUSTOM_LV_BIGBANK_CAMPAIGN_RUNNING ? 10 : 15,
        loanCommissionFee: 1,
        // loanCommissionFeeMax: 1000,
        fixedInterestRate: 1.65,
        accountMonthlyFee: 2.5,
      },
      [UnitTypeLiteral.HOUSE]: {
        period: 30,
        selfFinancing: CUSTOM_LV_BIGBANK_CAMPAIGN_RUNNING ? 10 : 15,
        loanCommissionFee: 1,
        // loanCommissionFeeMax: 1000,
        fixedInterestRate: 1.65,
        accountMonthlyFee: 2.5,
      },
      [UnitTypeLiteral.COMMERCIAL]: {
        period: 20,
        maxPeriod: 20,
        selfFinancing: 15,
        loanCommissionFee: 1,
        fixedInterestRate: 4,
        accountMonthlyFee: 0,
      },
      [UnitTypeLiteral.LAND]: {
        period: 15,
        maxPeriod: 15,
        selfFinancing: 50,
        loanCommissionFee: 1,
        fixedInterestRate: 1.65,
        accountMonthlyFee: 2.5,
      },
    },
    links: {
      overrides: {
        [UnitTypeLiteral.COMMERCIAL]: {
          button: {
            lv: 'https://biznesam.bigbank.lv/lv?period=%period%&amount=%loanAmount%&utm_source=city24&utm_medium=calculator&utm_campaign=city24-calculator&utm_content=%utmContent%',
            en: 'https://biznesam.bigbank.lv/lv?period=%period%&amount=%loanAmount%&utm_source=city24&utm_medium=calculator&utm_campaign=city24-calculator&utm_content=%utmContent%',
            ru: 'https://biznesam.bigbank.lv/ru?period=%period%&amount=%loanAmount%&utm_source=city24&utm_medium=calculator&utm_campaign=city24-calculator&utm_content=%utmContent%',
          },
          link: {
            lv: 'https://www.bigbank.lv/privatpersonam/hipotekarais-aizdevums/?utm_source=city24&utm_medium=calculator&utm_campaign=city24-calculator&utm_content=%utmContent%',
            en: 'https://www.bigbank.lv/privatpersonam/hipotekarais-aizdevums/?utm_source=city24&utm_medium=calculator&utm_campaign=city24-calculator&utm_content=%utmContent%',
            ru: 'https://www.bigbank.lv/ru/privatpersonam/hipotekarais-aizdevums?utm_source=city24&utm_medium=calculator&utm_campaign=city24-calculator&utm_content=%utmContent%',
          },
        },
      },
      button: {
        lv: 'https://loans.bigbank.lv/?period=%period%&productName=HOUSING_LOAN&goodsPrice=%amount%&amount=%loanAmount%&utm_source=city24&utm_medium=calculator&utm_campaign=city24-calculator&utm_content=%utmContent%',
        en: 'https://loans.bigbank.lv/?period=%period%&productName=HOUSING_LOAN&goodsPrice=%amount%&amount=%loanAmount%&utm_source=city24&utm_medium=calculator&utm_campaign=city24-calculator&utm_content=%utmContent%',
        ru: 'https://loans.bigbank.lv/?period=%period%&productName=HOUSING_LOAN&lang=ru&goodsPrice=%amount%&amount=%loanAmount%&utm_source=city24&utm_medium=calculator&utm_campaign=city24-calculator&utm_content=%utmContent%',
      },
      link: {
        lv: 'https://www.bigbank.lv/privatpersonam/hipotekarais-aizdevums/?utm_source=city24&utm_medium=calculator&utm_campaign=city24-calculator&utm_content=%utmContent%',
        en: 'https://www.bigbank.lv/privatpersonam/hipotekarais-aizdevums/?utm_source=city24&utm_medium=calculator&utm_campaign=city24-calculator&utm_content=%utmContent%',
        ru: 'https://www.bigbank.lv/ru/privatpersonam/hipotekarais-aizdevums/?utm_source=city24&utm_medium=calculator&utm_campaign=city24-calculator&utm_content=%utmContent%',
      },
    },
  });
}

export const MULTI_BANK_PERIOD_OPTIONS_YEARS = Range(30, 0, -5).map((i) => Map({ name: yearsName, value: `${i}` }));
export const MULTI_BANK_PERIOD_LAND_OPTIONS_YEARS = merge(Range(30, 10, -5), Range(10, 0, -1)).map((i) =>
  Map({ name: yearsName, value: `${i}` })
);

export const SINGLE_BANK_LOAN_CALCULATOR: SingleBankCalculatorItem[] = PORTAL_EE
  ? [
      {
        name: 'tbb',
        color: '#B79C5E',
        defaults: {
          amountDefault: 100000,
          amountRange: Range(100000, 0, -5000).map((i) => Map({ name: `${i} €`, value: `${i}` })),
          periodDefault: 10,
          periodRange: Range(10, 0, -1).map((i) => Map({ name: yearsName, value: `${i}` })),
          fixedInterestRate: 7.5,
        },
        links: {
          button: {
            et: 'https://www.tbb.ee/laenud/ettevottele/investeerimislaen/?utm_source=city24&utm_medium=calculator&utm_campaign=investeerimislaen',
            en: 'https://www.tbb.ee/en/loans/business-loans/investment-loan/?utm_source=city24&utm_medium=calculator&utm_campaign=investeerimislaen',
            ru: 'https://www.tbb.ee/ru/kredity/biznes/investicionnyj/?utm_source=city24&utm_medium=calculator&utm_campaign=investeerimislaen',
          },
          link: {
            et: 'https://www.tbb.ee/laenud/ettevottele/investeerimislaen/?utm_source=city24&utm_medium=calculator&utm_campaign=investeerimislaen',
            en: 'https://www.tbb.ee/en/loans/business-loans/investment-loan/?utm_source=city24&utm_medium=calculator&utm_campaign=investeerimislaen',
            ru: 'https://www.tbb.ee/ru/kredity/biznes/investicionnyj/?utm_source=city24&utm_medium=calculator&utm_campaign=investeerimislaen',
          },
        },
      },
      {
        name: 'coop',
        color: '#0078D2',
        footerDisclaimer: 'footer.disclaimer.coop_rent',
        defaults: {
          amountDefault: 3000,
          amountRange: Range(25000, 0, -100).map((i) => Map({ name: `${i} €`, value: `${i}` })),
          periodInMonths: true,
          periodDefault: 48,
          periodRange: Range(120, 0, -6).map((i) => Map({ name: monthsName, value: `${i}` })),
          fixedInterestRate: 12.9,
        },
        links: {
          button: {
            et: 'https://www.cooppank.ee/vaikelaen?utm_source=city24&utm_medium=link&utm_campaign=city24&slco=city24',
            en: 'https://www.cooppank.ee/en/small-loan?utm_source=city24&utm_medium=link&utm_campaign=city24&slco=city24',
            ru: 'https://www.cooppank.ee/ru/vaikelaen?utm_source=city24&utm_medium=link&utm_campaign=city24&slco=city24',
          },
          link: {
            et: 'https://www.cooppank.ee/vaikelaen?utm_source=city24&utm_medium=link&utm_campaign=city24&slco=city24',
            en: 'https://www.cooppank.ee/en/small-loan?utm_source=city24&utm_medium=link&utm_campaign=city24&slco=city24',
            ru: 'https://www.cooppank.ee/ru/vaikelaen?utm_source=city24&utm_medium=link&utm_campaign=city24&slco=city24',
          },
        },
      },
    ]
  : [];

export const FINANCE_CENTER_BANKS: FinanceCenterBank[] = PORTAL_EE
  ? [
      {
        name: 'luminor',
        color: '#471334',
        footerDisclaimer: 'footer.disclaimer.luminor',
        links: {
          button: {
            et: 'https://luminor.ee/k%C3%BCsilaenupakkumist?utm_source=city24&utm_medium=object_view&utm_campaign=mortgage&utm_term=button&utm_content=variant1',
            en: 'https://luminor.ee/askforloanoffer?utm_source=city24&utm_medium=object_view&utm_campaign=mortgage&utm_term=button&utm_content=variant1',
            ru: 'https://luminor.ee/sprosite-predlozheniye?utm_source=city24&utm_medium=object_view&utm_campaign=mortgage&utm_term=button&utm_content=variant1',
          },
          link: {
            et: 'https://luminor.ee/era/kodulaen',
            en: 'https://luminor.ee/retail/mortgage-loan',
            ru: 'https://luminor.ee/chastnye/zhilishchnyy-kredit',
          },
        },
      },
      {
        name: 'coop',
        color: '#0078D2',
        footerDisclaimer: 'footer.disclaimer.coop',
        links: {
          button: {
            et: 'https://www.cooppank.ee/kodulaenu-eripakkumine-lepingutasu-0eu?utm_source=city24&utm_medium=calculator&utm_campaign=city24',
            en: 'https://www.cooppank.ee/en/home-loan-special-offer-agreement-fee-0eu?utm_source=city24&utm_medium=calculator&utm_campaign=city24',
            ru: 'https://www.cooppank.ee/ru/specialnoe-predlozhenie-po-zhilischnomu-kreditu-plata-za-dogovor-0-evro?utm_source=city24&utm_medium=calculator&utm_campaign=city24',
          },
          link: {
            et: 'https://www.cooppank.ee/kodulaenu-eripakkumine-lepingutasu-0eu?utm_source=city24&utm_medium=calculator&utm_campaign=city24',
            en: 'https://www.cooppank.ee/en/home-loan-special-offer-agreement-fee-0eu?utm_source=city24&utm_medium=calculator&utm_campaign=city24',
            ru: 'https://www.cooppank.ee/ru/specialnoe-predlozhenie-po-zhilischnomu-kreditu-plata-za-dogovor-0-evro?utm_source=city24&utm_medium=calculator&utm_campaign=city24',
          },
        },
      },
      {
        name: 'lhv',
        color: '#3B3B47',
        footerDisclaimer: 'footer.disclaimer.lhv',
        links: {
          button: {
            et: 'https://www.lhv.ee/kodulaen?utm_source=city24&utm_medium=kalkulaator&utm_campaign=city24-2023',
            en: 'https://www.lhv.ee/en/home-loan?utm_source=city24&utm_medium=kalkulaator&utm_campaign=city24-2023',
            ru: 'https://www.lhv.ee/ru/zhilisnyj-kredit?utm_source=city24&utm_medium=kalkulaator&utm_campaign=city24-2023',
          },
          link: {
            et: 'https://www.lhv.ee/kodulaen?utm_source=city24&utm_medium=kalkulaator&utm_campaign=city24-2023',
            en: 'https://www.lhv.ee/en/home-loan?utm_source=city24&utm_medium=kalkulaator&utm_campaign=city24-2023',
            ru: 'https://www.lhv.ee/ru/zhilisnyj-kredit?utm_source=city24&utm_medium=kalkulaator&utm_campaign=city24-2023',
          },
        },
      },
    ]
  : [];

export const INBANK_CALCULATOR_DEFAULTS = {
  general: {
    fixedInterestRate: 6.9,
    loanCommissionFee: 2.9,
    minAmount: 2000,
    maxAmount: 20000,
    amountStep: 500,
    minPeriod: 12,
    maxPeriod: 72,
    monthlyFee: 3.5,
    links: INBANK_OFFER_LINKS,
  },
  land: {
    fixedInterestRate: 6.9,
    loanCommissionFee: 2.9,
    minAmount: 2000,
    maxAmount: 20000,
    amountStep: 500,
    minPeriod: 12,
    maxPeriod: 72,
    monthlyFee: 3.5,
    links: INBANK_LAND_OFFER_LINKS,
  },
  rent: {
    fixedInterestRate: 6.9,
    loanCommissionFee: 2.9,
    minAmount: 500,
    maxAmount: 20000,
    amountStep: 500,
    minPeriod: 12,
    maxPeriod: 72,
    monthlyFee: 3.5,
    links: INBANK_RENT_OFFER_LINKS,
  },
};

export const ALTERO_SIDEBAR_IFRAME_LINKS = {
  m: {
    'lv-LV':
      'https://www.altero.lv/widgets/city24/altero-with-form/lv-mobile.html?utm_source=city24&utm_medium=calculator_mob_lv&loan_period=60&loan_amount=',
    'ru-RU':
      'https://www.altero.lv/widgets/city24/altero-with-form/ru-mobile.html?utm_source=city24&utm_medium=calculator_mob_ru&loan_period=60&loan_amount=',
    'en-GB':
      'https://www.altero.lv/widgets/city24/altero-with-form/lv-mobile.html?utm_source=city24&utm_medium=calculator_mob_lv&loan_period=60&loan_amount=',
  },
  d: {
    'lv-LV':
      'https://www.altero.lv/widgets/city24/altero-with-form/lv.html?utm_source=city24&utm_medium=calculator_desk_lv&loan_period=60&loan_amount=',
    'ru-RU':
      'https://www.altero.lv/widgets/city24/altero-with-form/ru.html?utm_source=city24&utm_medium=calculator_deskt_ru&loan_period=60&loan_amount=',
    'en-GB':
      'https://www.altero.lv/widgets/city24/altero-with-form/lv.html?utm_source=city24&utm_medium=calculator_desk_lv&loan_period=60&loan_amount=',
  },
} as const;

export const ALTERO_HORIZONTAL_IFRAME_LINKS = {
  'lv-LV':
    'https://www.altero.lv/widgets/city24/contacts/lv-600-240.html?utm_source=city24&utm_medium=calculator_slider_lv&loan_period=60&loan_amount=',
  'ru-RU':
    'https://www.altero.lv/widgets/city24/contacts/ru-600-240.html?utm_source=city24&utm_medium=calculator_slider_ru&loan_period=60&loan_amount=',
  'en-GB':
    'https://www.altero.lv/widgets/city24/contacts/lv-600-240.html?utm_source=city24&utm_medium=calculator_slider_lv&loan_period=60&loan_amount=',
} as const;

export const PERIOD_OPTIONS_YEARS = Range(30, 0, -1).map((i) => Map({ name: yearsName, value: `${i}` }));
export const PERIOD_OPTIONS_MONTHS = Range(12, 85, 12).map((i) => Map({ name: monthsName, value: `${i}` }));

export const SELF_FINANCE_UNIT_OPTIONS = List([
  Map({ name: '%', value: SELF_FINANCE_UNIT.percent, default: true }),
  Map({ name: '€', value: SELF_FINANCE_UNIT.eur }),
]);

export const LOAN_APPLICANTS_COUNT_OPTIONS = List([
  Map({ value: LOAN_APPLICANTS_COUNT.alone, name: 'luminor.calc.applicantCount.alone' }),
  Map({
    value: LOAN_APPLICANTS_COUNT.withCoBorrower,
    name: 'luminor.calc.applicantCount.coBorrower',
  }),
]);

export const OTHER_DEPENDANTS_OPTIONS = Range(0, 11).map((i) => Map({ name: `${i}`, value: `${i}` }));

export function showLuminorBankContent(transactionType: TransactionTypeLiteral, objectType: UnitTypeLiteral): boolean {
  return transactionType === TransactionTypeLiteral.SELL && ![UnitTypeLiteral.COMMERCIAL].includes(objectType);
}
export function showSebBankContent(transactionType: TransactionTypeLiteral, objectType: UnitTypeLiteral): boolean {
  return showLuminorBankContent(transactionType, objectType);
}

export function showSEBBankContent(transactionType: TransactionTypeLiteral, objectType: UnitTypeLiteral): boolean {
  return transactionType === TransactionTypeLiteral.SELL;
}

export function showMultiBankCalculator(transactionType: TransactionTypeLiteral, objectType: UnitTypeLiteral): boolean {
  if (PORTAL_LV) {
    return (
      transactionType === TransactionTypeLiteral.SELL &&
      [
        UnitTypeLiteral.NEW_PROJECT,
        UnitTypeLiteral.APARTMENT,
        UnitTypeLiteral.HOUSE,
        UnitTypeLiteral.LAND,
        UnitTypeLiteral.COMMERCIAL,
      ].includes(objectType)
    );
  }
  // EE version
  return (
    transactionType === TransactionTypeLiteral.SELL &&
    [
      UnitTypeLiteral.NEW_PROJECT,
      UnitTypeLiteral.APARTMENT,
      UnitTypeLiteral.HOUSE,
      UnitTypeLiteral.LAND,
      UnitTypeLiteral.HOUSE_SHARE,
      UnitTypeLiteral.COTTAGE_OR_VILLA,
      UnitTypeLiteral.GARAGE,
      UnitTypeLiteral.MODULAR_HOUSE,
    ].includes(objectType)
  );
}

/**
 * For transition period, remove once done.
 */
export const INBANK_CAMPAIGN_RUNNING =
  (process.env.NEXT_PUBLIC_APP_ENV === 'development' ? new Date('2023-12-19') : new Date('2024-01-01')) <= new Date();
export const ALTERO_CAMPAIGN_RUNNING = !INBANK_CAMPAIGN_RUNNING;

export function showAlteroBankContent(objectType: string, propertySize: number): boolean {
  return (
    ALTERO_CAMPAIGN_RUNNING &&
    !!propertySize &&
    (objectType === UnitTypeLiteral.APARTMENT ||
      objectType === UnitTypeLiteral.HOUSE ||
      objectType === UnitTypeLiteral.NEW_PROJECT)
  );
}
export function showAlteroInsuranceContent(objectType: UnitTypeLiteral): boolean {
  return (
    ALTERO_CAMPAIGN_RUNNING &&
    [UnitTypeLiteral.APARTMENT, UnitTypeLiteral.HOUSE, UnitTypeLiteral.NEW_PROJECT].includes(objectType)
  );
}

export function showInBankCalculator(objectType: UnitTypeLiteral, propertySize: number, price: number): boolean {
  return (
    INBANK_CAMPAIGN_RUNNING &&
    ((objectType === UnitTypeLiteral.LAND && price < 40000) ||
      (!!propertySize &&
        (objectType === UnitTypeLiteral.APARTMENT ||
          objectType === UnitTypeLiteral.HOUSE ||
          objectType === UnitTypeLiteral.NEW_PROJECT)))
  );
}

export function getInBankCalculatorAmount(
  objectType: UnitTypeLiteral,
  transactionType: TransactionTypeLiteral,
  propertySize: number,
  price: number
): number {
  if (objectType === UnitTypeLiteral.LAND) {
    if (price > INBANK_CALCULATOR_DEFAULTS.land.maxAmount) {
      return INBANK_CALCULATOR_DEFAULTS.land.maxAmount;
    }
    if (price < INBANK_CALCULATOR_DEFAULTS.land.minAmount) {
      return INBANK_CALCULATOR_DEFAULTS.land.minAmount;
    }
    return Math.round(price);
  }

  if (transactionType === TransactionTypeLiteral.RENT) {
    return Math.round(price * 3);
  }

  return propertySize * 100;
}

export function getInBankCalculatorType(
  unitType: UnitTypeLiteral,
  transactionType: TransactionTypeLiteral
): InBankCalculatorType {
  if (unitType === UnitTypeLiteral.LAND) {
    return 'land';
  }

  if (transactionType === 'rent') {
    return 'rent';
  }

  return 'general';
}
