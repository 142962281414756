import { fromJS, OrderedMap, Map, List } from 'immutable';
import produce from 'immer';

import { languageFromLocale } from '@/constants';
import { LANGUAGES_SELECT } from '@/constants/settings';
import { UserRole, UserStatus } from '@/constants/signIn';
import {
  SIGN_IN,
  SET_EMAIL,
  CREATE_ACCOUNT,
  SAVE_FAVOURITE,
  REMOVE_FAVOURITE,
  REMOVE_SAVED_SEARCH,
  UPDATE_SAVED_SEARCH,
  SIGN_IN_ERROR,
  SIGN_OUT,
  CREATE_ACCOUNT_ERROR,
  SOCIAL_LOGIN_INIT,
  GET_FAVOURITES,
  GET_FAVOURITES_SHORT_DETAILS,
  GET_INVOICES,
  GET_COUNTERS,
  GET_SAVED_SEARCHES,
  ADD_SAVED_SEARCH,
  SET_LATEST_SEARCHES,
  SET_PASSWORD_UPDATED,
  SET_ACCOUNT_VERIFIED,
  ACCOUNT_VERIFICATION_ERROR,
  PASSWORD_UPDATE_ERROR,
  SET_RECENTLY_VIEWED_OBJECTS,
  SAVE_SEARCH_SETTINGS_DRAFT,
  CLEAR_SEARCH_SETTINGS_DRAFT,
  SET_PASSWORD_RESET_ERROR,
  UPDATE_USER_SETTINGS,
  UPDATE_USER_SETTINGS_ERROR,
  SET_UUID_VERIFIED,
  UUID_VERIFICATION_ERROR,
  IMPERSONATE_USER,
  GET_UNSUBSCRIBE_SAVED_SEARCHES,
  CLEAR_USER_ERRORS,
} from '@/constants/actions';
import { pick } from '@/utils/object';
import { fromISOString } from '@/utils/date';
import { formatDate } from '@/utils/formatting';
import { RealtySearch } from '@/types/api/realtySearches';

const initialState = {
  signedIn: false,
  impersonate: false,
  isBroker: false,
  provider: '',
  accessToken: '',
  id: '',
  username: '',
  email: '',
  canEditEmail: true,
  firstName: '',
  lastName: '',
  roles: new Set(),
  phone: '',
  phone2: '',
  invoices: List(),
  invoicesTotalItems: null,
  subscriptions: {
    newsletter: false,
    // activeAlerts: false,
    // priceChange: false
  },
  counters: Map({
    noObjects: 0,
    noInvoices: 0,
    noSavedSearches: 0,
  }),
  authProviders: {
    facebook: null,
    google2: null,
  },
  invoiceName: '',
  invoiceAddress: '',
  language: LANGUAGES_SELECT.find((lang) => {
    return (PORTAL_LV && lang.get('value') === 'lv') || (PORTAL_EE && lang.get('value') === 'et');
  }),
  gdprAcceptDate: null,
  favourites: OrderedMap(),
  favouritesChanged: false,
  favouriteObjects: Map(),
  savedSearches: OrderedMap(),
  savedSearchToUnsubscribe: {} as RealtySearch,
  saveSearchSettingsDraft: Map({
    title: null,
    alert: false,
    interval: Map({ name: 'At once', value: 'at-once' }),
    priceChange: false,
    email: null,
  }),
  searchesChanged: false,
  latestSearches: List(),
  recentlyViewedObjects: OrderedMap(),
  error: '',
  errorMessage: '',
  violations: [] as { message: string }[],
  passwordUpdated: false,
  accountVerified: false,
  needsConfirmation: false,
  passwordResetError: false,
  uuid: {
    valid: null,
    error: '',
  },
};

const user = produce((state, action) => {
  switch (action.type) {
    case SIGN_IN: {
      const { userDetails } = action;
      state.signedIn = true;
      state.isBroker = userDetails.get('office') && userDetails.get('office').size > 0;
      state.email = userDetails.get('email');
      state.canEditEmail = userDetails.get('can_edit_email_address');
      state.username = userDetails.get('username');
      state.firstName = userDetails.get('first_name');
      state.lastName = userDetails.get('last_name') || '';
      state.phone = userDetails.get('phone_number') || '';
      state.phone2 = userDetails.get('phone_number2') || '';
      state.invoiceName = userDetails.get('payer_name') || '';
      state.invoiceAddress = userDetails.get('payer_address') || '';
      state.language = LANGUAGES_SELECT.find(
        (lang) => lang.get('value') === languageFromLocale(userDetails.get('locale_code'))
      );
      state.roles = new Set(userDetails.get('roles'));
      state.error = '';

      if (userDetails.get('gdpr_privacy_accept')) {
        state.gdprAcceptDate = formatDate(fromISOString(userDetails.get('gdpr_privacy_accept')));
      }

      if (userDetails.get('external_auth')?.size) {
        userDetails.get('external_auth').forEach((p) => {
          switch (p.get('provider')) {
            case 'facebook':
              state.authProviders.facebook = p.get('identifier');
              break;
            case 'Google2':
              state.authProviders.google2 = p.get('identifier');
              break;
            default:
              break;
          }
        });
      }

      if (userDetails.hasIn(['user_interest', 'receive_newsletter'])) {
        state.subscriptions.newsletter = !!userDetails.getIn(['user_interest', 'receive_newsletter']);
      }
      break;
    }
    case CREATE_ACCOUNT_ERROR:
    case SIGN_IN_ERROR:
      state.signedIn = false;
      state.isBroker = false;
      state.provider = action.provider;
      state.error = action.error;
      if (!action.provider) {
        state.errorMessage = action.errorData?.startsWith?.('errors.') ? action.errorData : action.error;
      }
      state.violations = action.violations || [];
      break;
    case UPDATE_USER_SETTINGS_ERROR:
      state.error = action.error;
      state.errorMessage = action.error;
      state.violations = action.violations || [];
      break;
    case SIGN_OUT:
      state.signedIn = false;
      state.isBroker = false;
      state.email = '';
      state.username = '';
      state.roles = new Set();
      state.phone = '';
      state.savedSearches = Map();
      state.favourites = initialState.favourites;
      state.favouriteObjects = initialState.favouriteObjects;
      state.favouritesChanged = false;
      break;

    case SOCIAL_LOGIN_INIT:
      state.accessToken = action.accessToken;
      state.provider = action.provider;
      break;

    case SET_EMAIL:
      state.email = action.email;
      break;

    case CREATE_ACCOUNT: {
      const { user: _user } = action;
      state.email = _user.email;
      state.username = `${_user.first_name} ${_user.last_name}`;
      state.phone = _user.phone;
      state.error = '';
      break;
    }

    case GET_FAVOURITES:
      state.favourites = OrderedMap(action.favourites);
      state.favouritesChanged = false;
      break;

    case GET_FAVOURITES_SHORT_DETAILS:
      if (!action.object) {
        break;
      }
      state.favouriteObjects = state.favouriteObjects.set(
        action.favourite,
        fromJS(action.object).set('favourite', state.favourites.get(action.favourite))
      );
      break;

    case SAVE_FAVOURITE:
      state.favourites = state.favourites.set(action.favourite, action.favouriteId);
      state.favouritesChanged = true;
      break;

    case REMOVE_FAVOURITE:
      state.favourites = state.favourites.delete(action.favourite);
      state.favouriteObjects = state.favouriteObjects.delete(action.favourite);
      state.favouritesChanged = true;
      break;

    case GET_INVOICES:
      state.invoices = fromJS(
        action.invoices.map((invoice) => ({
          ...invoice,
          date: fromISOString(invoice.date),
        }))
      );
      state.invoicesTotalItems = action.totalItems;
      break;
    case GET_COUNTERS:
      state.counters = fromJS(action.counters);
      break;

    case GET_SAVED_SEARCHES:
      state.savedSearches = OrderedMap(action.searches.map((search) => [search.id, search]));
      state.searchesChanged = false;
      break;

    case GET_UNSUBSCRIBE_SAVED_SEARCHES:
      state.savedSearchToUnsubscribe = action.search;
      break;

    case ADD_SAVED_SEARCH:
      state.savedSearches = OrderedMap({ [action.search.id]: action.search }).merge(state.savedSearches);
      state.searchesChanged = true;
      break;

    case REMOVE_SAVED_SEARCH:
      state.savedSearches = state.savedSearches.delete(action.id);
      state.searchesChanged = true;
      break;

    case UPDATE_SAVED_SEARCH:
      state.savedSearches = state.savedSearches.update(action.id, (search) => ({
        ...search,
        ...pick(action, 'name', 'email_alert_type', 'last_run'),
      }));
      state.searchesChanged = true;
      break;

    case SET_LATEST_SEARCHES:
      state.latestSearches = action.latestSearches;
      break;

    case SET_RECENTLY_VIEWED_OBJECTS:
      state.recentlyViewedObjects = state.recentlyViewedObjects.set(action.id, fromJS(action.object));
      break;

    case SET_PASSWORD_UPDATED:
      state.passwordUpdated = action.passwordUpdated;
      state.error = '';
      state.errorMessage = '';
      break;

    case PASSWORD_UPDATE_ERROR: {
      const { error } = action;
      state.passwordUpdated = false;
      state.error = error.name;
      state.errorMessage = error.message;
      state.violations = error.violations || [];
      break;
    }

    case SET_ACCOUNT_VERIFIED: {
      const { user } = action;
      if (user.user_uuid?.expired) {
        state.accountVerified = false;
        state.errorMessage = 'errors.verification.expired';
        break;
      }
      if (user.status_id !== UserStatus.ACTIVE) {
        state.accountVerified = false;
        state.id = user.id;
        state.username = user.username;
        state.firstName = user.first_name;
        state.lastName = user.last_name;
        state.email = user.email_address;
        state.roles = new Set(user.roles);
        state.needsConfirmation = state.roles.has(UserRole.MANAGER) || state.roles.has(UserRole.AGENT);
      } else {
        state.accountVerified = true;
        state.needsConfirmation = false;
      }
      break;
    }
    case ACCOUNT_VERIFICATION_ERROR: {
      const { error } = action;
      state.accountVerified = false;
      state.error = error.name;
      state.errorMessage = error.message;
      state.violations = error.violations || [];
      break;
    }

    case SET_UUID_VERIFIED:
      state.uuid.valid = action.valid;
      state.uuid.error = action.error;
      break;

    case UUID_VERIFICATION_ERROR: {
      const { error } = action;
      state.violations.push({ message: error.message });
      state.error = error.name;
      state.errorMessage = error.message;
      break;
    }

    case CLEAR_USER_ERRORS:
      state.error = '';
      state.errorMessage = '';
      state.violations = [];
      state.passwordResetError = false;
      break;

    case SAVE_SEARCH_SETTINGS_DRAFT:
      state.saveSearchSettingsDraft = state.saveSearchSettingsDraft
        .set('title', action.title)
        .set('alert', action.alert)
        .set('interval', action.interval)
        .set('priceChange', action.priceChange)
        .set('email', action.email);
      break;

    case CLEAR_SEARCH_SETTINGS_DRAFT:
      state.saveSearchSettingsDraft = initialState.saveSearchSettingsDraft;
      break;

    case SET_PASSWORD_RESET_ERROR:
      state.passwordResetError = action.hasError;
      break;

    case UPDATE_USER_SETTINGS:
      const { userDetails } = action;
      state.email = userDetails.email_address;
      state.canEditEmail = userDetails.can_edit_email_address;
      state.username = userDetails.username;
      state.firstName = userDetails.first_name;
      state.lastName = userDetails.last_name || '';
      state.phone = userDetails.phone_number || '';
      state.phone2 = userDetails.phone_number2 || '';
      state.invoiceName = userDetails.payer_name || '';
      state.invoiceAddress = userDetails.payer_address || '';
      state.language = LANGUAGES_SELECT.find(
        (lang) => lang.get('value') === languageFromLocale(userDetails.locale_code)
      );

      if (userDetails.user_interest) {
        state.subscriptions.newsletter = !!userDetails.user_interest.receive_newsletter;
      }
      break;

    case IMPERSONATE_USER:
      state.impersonate = action.status;
      break;

    default:
      break;
  }
}, initialState);

export default user;
