import React from 'react';
import PropTypes from 'prop-types';
import { Map, Collection } from 'immutable';
import classNames from 'classnames';

import { connect } from 'react-redux';
import ReactGA from '@/utils/ga';
import * as searchActions from '../search/SearchActions';
import * as userActions from '../../actions/userActions';
import { fetchSavedSearches } from '@/components/savedSearches/savedSearchesActions';

import { getSavedSearches, getLatestSearches } from '@/selectors/userSelectors';
import { SEARCH_URL_LABELS, SORTING_VALUES } from '@/constants/filters';
import {
  composeSearchURLFromFilters,
  composeSearchURLFromSavedSearch,
  getLatestSearchFromStorage,
  parseQuickSearchDate,
} from '@/utils/search';
import { GA_SEARCH } from '@/constants/ga';
import { getSignedIn } from '@/selectors/appSelectors';
import QuickSavedSearch from './QuickSavedSearch';
import QuickLastSearch from './QuickLastSearch';
import { toISOString } from '@/utils/date';

const propTypes = {
  savedSearches: PropTypes.instanceOf(Collection).isRequired,
  latestSearches: PropTypes.instanceOf(Collection).isRequired,
  signedIn: PropTypes.bool.isRequired,
  routes: PropTypes.shape({
    objectResults: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
  setSorting: PropTypes.func.isRequired,
  setLatestSearches: PropTypes.func.isRequired,
  fetchSavedSearches: PropTypes.func.isRequired,
  updateSavedSearch: PropTypes.func.isRequired,
};

const defaultProps = {};

class QuickSearch extends React.Component {
  componentDidMount() {
    const { setLatestSearches, fetchSavedSearches, signedIn } = this.props;

    if (signedIn) {
      fetchSavedSearches();
    }

    setLatestSearches(getLatestSearchFromStorage());
  }

  getLastSearchLink(lastSearch) {
    const { routes } = this.props;

    const filters = lastSearch.get('filters').toObject();
    const searchDate = parseQuickSearchDate(lastSearch.get('date'));
    filters.lastRun = Math.floor(searchDate.getTime() / 1000);
    return `${routes.objectResults}${composeSearchURLFromFilters(SEARCH_URL_LABELS, filters)}`;
  }

  getSavedSearchLink(savedSearch) {
    const { routes } = this.props;
    return `${routes.objectResults}${composeSearchURLFromSavedSearch(SEARCH_URL_LABELS, savedSearch)}`;
  }

  onSearchClick = () => {
    const { setSorting } = this.props;
    setSorting(Map(SORTING_VALUES.latest));
    ReactGA.event({
      category: GA_SEARCH,
      action: 'select_lastSearch',
    });
  };

  render() {
    const { savedSearches, latestSearches, updateSavedSearch, alternate, t } = this.props;
    const savedSearch = savedSearches.first();

    const quickSearchStyle = classNames({
      'quick-search': true,
      'quick-search--alternate': alternate,
    });

    if (savedSearch) {
      return (
        <QuickSavedSearch
          className={quickSearchStyle}
          t={t}
          linkTo={this.getSavedSearchLink(savedSearch)}
          savedSearch={savedSearch}
          onClick={(event) => {
            this.onSearchClick(event);
            updateSavedSearch({ ...savedSearch, last_run: toISOString(new Date()) });
          }}
        />
      );
    }

    const lastSearch = latestSearches ? latestSearches.last() : null;
    if (lastSearch) {
      return (
        <QuickLastSearch
          className={quickSearchStyle}
          t={t}
          linkTo={this.getLastSearchLink(lastSearch)}
          lastSearch={lastSearch}
          onClick={this.onSearchClick}
        />
      );
    }

    return null;
  }
}

QuickSearch.propTypes = propTypes;
QuickSearch.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    savedSearches: getSavedSearches(state),
    latestSearches: getLatestSearches(state),
    signedIn: getSignedIn(state),
  };
}

const mapDispatchToProps = {
  setSorting: searchActions.setSorting,
  setLatestSearches: userActions.setLatestSearches,
  fetchSavedSearches: fetchSavedSearches,
  updateSavedSearch: userActions.updateSavedSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickSearch);
