import React from 'react';
import classNames from 'classnames';
import Icon from '@/components/icon/Icon';

export interface ButtonContentProps {
  icon?: string;
  iconPosition?: 'inline' | 'inline-after' | 'after';
  children: React.ReactNode;
}

function ButtonContent({ icon, iconPosition, children }: ButtonContentProps): React.ReactElement {
  const showIcon = () => {
    if (icon) {
      const iconStyle = classNames('btn__icon', {
        'btn__icon--inline': iconPosition === 'inline',
        'btn__icon--after': iconPosition === 'inline-after' || iconPosition === 'after',
      });
      return <Icon name={icon} className={iconStyle} />;
    }
    return null;
  };

  // const { google, suffix, loading } = props;

  return (
    <>
      {/* {google && (
          <span className="google-icon">
            <img src={GoogleIcon} alt="google icon" />
          </span>
        )} */}

      {!iconPosition && showIcon()}

      {children && (
        <span>
          {iconPosition === 'inline' && showIcon()}
          {children}
          {iconPosition === 'inline-after' && showIcon()}
        </span>
      )}
      {iconPosition === 'after' && showIcon()}

      {/* {suffix && <span className="btn__suffix">{suffix}</span>} */}
      {/* {loading && <Loading small className="btn__loader" />} */}
    </>
  );
}

export default ButtonContent;
