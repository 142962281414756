import React, { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '@/hooks';
import type { TFunction } from 'next-i18next';
import { getLanguage } from '@/selectors/appSelectors';
import { computeLoanSale } from '@/components/SEBCalculator/SEBCalculatorFunctions';
import { INBANK_CALCULATOR_DEFAULTS } from '@/constants/finance';
import { GA_INBANK_CALCULATOR } from '@/constants/ga';
import ReactGA from '@/utils/ga';
import CompactInbankCalculator from '@/components/integrations/Inbank/CompactInbankCalculator';
import ExtendedInbankCalculator from '@/components/integrations/Inbank/ExtendedInbankCalculator';

export type InBankCalculatorType = 'land' | 'rent' | 'general';

interface Props {
  amount: number;
  t: TFunction;
  compact?: boolean;
  type: InBankCalculatorType;
}

export interface InbankCalculatorState {
  amount: number;
  period: number;
}

export interface PaymentInfoInterface {
  monthlyPaymentDec: number;
  loanAmount: number;
  totalAmount: number;
  apr: string;
}

const InbankCalculator = ({ amount, t, type, compact = false }: Props): React.ReactElement => {
  const lang = useAppSelector(getLanguage);
  const defaults = INBANK_CALCULATOR_DEFAULTS[type];

  const defaultAmount =
    amount >= defaults.minAmount && amount <= defaults.maxAmount
      ? amount
      : amount < defaults.minAmount
      ? defaults.minAmount
      : defaults.maxAmount;

  const [formState, setFormState] = useState<InbankCalculatorState>({
    amount: defaultAmount,
    period: 72,
  });

  const paymentInfo = useMemo(() => {
    return computeLoanSale(
      formState.amount,
      formState.period / 12,
      defaults.fixedInterestRate,
      0,
      'percent',
      defaults.loanCommissionFee,
      0,
      0,
      defaults.monthlyFee,
      false,
      0
    ) as PaymentInfoInterface;
  }, [formState]);

  const gaEventHandler = (action: string, label?: string) => {
    ReactGA.event({
      category: GA_INBANK_CALCULATOR,
      action,
      label,
    });
  };

  useEffect(() => {
    gaEventHandler('show_calculator', compact ? 'compact' : 'extended');
  }, []);

  return compact ? (
    <CompactInbankCalculator
      formState={formState}
      defaultAmount={defaultAmount}
      setFormState={setFormState}
      paymentInfo={paymentInfo}
      t={t}
      buttonLink={defaults.links[lang as keyof typeof defaults.links]}
      gaEventHandler={gaEventHandler}
      type={type}
    />
  ) : (
    <ExtendedInbankCalculator
      formState={formState}
      setFormState={setFormState}
      paymentInfo={paymentInfo}
      t={t}
      buttonLink={defaults.links[lang as keyof typeof defaults.links]}
      gaEventHandler={gaEventHandler}
      type={type}
    />
  );
};

export default InbankCalculator;
