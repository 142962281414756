import { fromJS, List, Map } from 'immutable';
import { combineReducers } from 'redux';

import {
  SET_PERIOD_PACKAGE,
  UPDATE_PRODUCT_CONF,
  SET_AUTO_RENEWAL,
  SORT_IMAGES,
  UPDATE_OBJECT_IMAGE,
  UPLOAD_OBJECT_IMAGES,
  DELETE_OBJECT_IMAGE,
  SET_AD_INFO,
  SET_APARTMENT_PLANS,
  SET_FLOOR_PLANS,
  SET_SUMMER_COMMUNALS_COST,
  SET_WINTER_COMMUNALS_COST,
  SET_LAND_REGISTRY_SEARCH,
  SET_ADDRESS,
  SET_ESTATE_REGISTER_NUMBER,
  SET_CADASTER_NUMBER,
  SET_POSITION,
  SET_ZOOM,
  SET_STREETVIEW_POV,
  SET_AD_CATEGORY,
  SET_BANK,
  SET_MY_OBJECT_STATUS,
  SET_MY_OBJECT_PROMO_CODE,
  GET_MY_OBJECT,
  GET_MY_OBJECT_LOCATION,
  GET_MY_OBJECT_IMAGES,
  GET_MY_OBJECT_PUBLISHED_SERVICES,
  SETUP_PUBLISHING_PACKAGE,
  GET_PUBLISHING_PACKAGE,
  RESET_OBJECT,
  SET_VIDEO,
  CREATE_NEW_VIDEO,
  REMOVE_VIDEO,
  SET_KV_EXPORT_SERVICE,
} from '@/constants/actions';
import { determinePeriodPackage, EXPORT_TO_KV, MARKETING_PACKAGE } from '@/constants/packages';
import { MODEL_STATUSES } from '@/constants';
import { TRANSACTION_TYPES_OPTIONS, OBJECT_TYPE_OPTIONS } from '@/constants/attributes';
import { LOT_SIZE_TYPE_OPTIONS } from '@/constants/filters';

import { NameValue } from '@/utils/collections';
import { fromAttributesToUI } from './utils';

const initialState = fromJS({
  id: 'new',
  status: MODEL_STATUSES.NEW,
  objectType: {},
  transactionType: {},
  location: {
    id: 0,
    landRegistrySearch: null,
    address: {
      county: {},
      cityParish: {},
      districtSettlement: {},
      street: NameValue(),
      place: '',
      houseNr: null,
      houseNrHidden: false,
      apartmentNr: null,
      apartmentNrHidden: false,
      zoom: 14,
    },
    cadasterNr: null,
    estateRegisterNr: null,
    position: {
      address: null,
      manual: null,
    },
    pov: {
      heading: null,
      pitch: 0,
      zoom: 1,
    },
  },
  info: {
    price: null,
    showPriceDrop: true,
    rentPerDay: null,
    purpose: [],
    otherUsePossible: false,
    size: null,
    rooms: null,
    floor: null,
    totalFloors: null,
    material: [],
    condition: [],
    lotSize: null,
    lotSizeUnit: LOT_SIZE_TYPE_OPTIONS.first(),
    balcony: [],
    balconySize: null,
    elevator: false,
    yearBuilt: null,
    roofType: {},
    energyCertificate: {},
    bedrooms: null,
    bathrooms: null,
    kitchenSize: null,
    numberOfDesks: null,
    numberOfParkingPlaces: null,
    parkingLocations: [],
    parkingLocationsCommercial: [],
    parkingCost: [],
    heatingSystem: [],
    sanitaryArrangements: [],
    stove: [],
    ventilation: [],
    communalCostItems: [],
    summerCommunalCost: null,
    summerCommunalCostFile: {},
    winterCommunalCost: null,
    winterCommunalCostFile: {},
    extraSpaces: [],
    municipalEngineering: [],
    communications: [],
    security: [],
    extraValues: [],
    ownership: {},
    limitations: {},
    available: null,
    immediatelyAvailable: false,
    projectType: {},
    brokersFee: null,
    depositMoney: null,
    vat: null,
    additionalInfo: {
      url: '',
      title: '',
    },
    description: {
      'et-EE': {
        description: '',
        introduction: '',
      },
      'lv-LV': {
        description: '',
        introduction: '',
      },
      'en-GB': {
        description: '',
        introduction: '',
      },
      'ru-RU': {
        description: '',
        introduction: '',
      },
    },
    slogan: {
      'et-EE': {
        slogan: '',
      },
      'lv-LV': {
        slogan: '',
      },
      'en-GB': {
        slogan: '',
      },
      'ru-RU': {
        slogan: '',
      },
    },
  },
  images: {
    galleryImages: [],
    failedUploads: [],
    apartmentPlan: [],
    floorPlan: [],
    videos: [
      {
        url: '',
      },
    ],
  },
  packages: {
    publishingId: null,
    promoCode: null,
    publishing: {
      active: false,
      startDate: null,
      endDate: null,
    },
    period: {
      active: [],
      selected: null,
      period: 30,
      price: 0,
      campaign: null,
      autoRenewal: false,
      paymentId: null,
      kvExportService: {
        selected: false,
        price: 0,
      },
    },
    services: {
      active: {},
      draft: {
        [MARKETING_PACKAGE.adLevel]: {
          selected: false,
          period: 1,
          level: 1,
          price: 0,
          campaign: null,
          paymentId: null,
        },
        [MARKETING_PACKAGE.specialOffer]: {
          selected: false,
          option: null,
          period: 7,
          price: 0,
          campaign: null,
          paymentId: null,
        },
        [MARKETING_PACKAGE.fromOwner]: {
          selected: false,
          option: null,
          period: 7,
          price: 0,
          campaign: null,
          paymentId: null,
        },
        [MARKETING_PACKAGE.mediaCampaign]: {
          selected: false,
          option: null,
          period: 7,
          price: 0,
          campaign: null,
          paymentId: null,
        },
      },
    },
  },
  payment: {
    paymentId: null,
    paymentType: 'bank',
    selectedMethod: null,
  },
});

function convertToEditingImage(image) {
  return { ...image, rotate: 0 };
}

function userInterface(state = initialState, action) {
  switch (action.type) {
    case GET_MY_OBJECT:
      return state.withMutations((s) => {
        const { object } = action;
        if (!object.id) {
          return;
        }
        s.merge({
          id: object.id,
          status: object.status_id,
          transactionType: TRANSACTION_TYPES_OPTIONS.find((tt) => tt.get('value') === object.transaction_type),
          objectType: OBJECT_TYPE_OPTIONS.find((ot) => ot.get('value') === object.unit_type),
        })
          .update('info', (info) => {
            const { attributes, descriptions, slogans, info: additionalInfo } = object;
            // TODO: Communal costs files
            return info.withMutations((newInfo) => {
              newInfo.merge(
                {
                  price: object.price,
                  showPriceDrop: object.show_price_drop,
                  size: object.property_size,
                  lotSize: object.lot_size,
                  lotSizeUnit:
                    LOT_SIZE_TYPE_OPTIONS.find((opt) => opt.get('id') === object.lot_size_unit_id) ||
                    LOT_SIZE_TYPE_OPTIONS.first(),
                  rooms: object.room_count,
                  yearBuilt: object.year_built,
                },
                attributes ? fromAttributesToUI(object.unit_type, object.transaction_type, attributes) : {}
              );

              if (descriptions) {
                (Array.isArray(descriptions) ? descriptions : Object.values(descriptions)).forEach((desc) => {
                  newInfo.setIn(
                    ['description', desc.locale_code.replace('_', '-')],
                    Map({
                      description: desc.description || '',
                      introduction: desc.introduction || '',
                    })
                  );
                });
              }

              if (additionalInfo.length > 0) {
                newInfo.set(
                  'additionalInfo',
                  Map({
                    title: additionalInfo[0].title,
                    url: additionalInfo[0].url,
                  })
                );
              }

              (Array.isArray(slogans) ? slogans : Object.values(slogans)).forEach((sl) => {
                newInfo.setIn(
                  ['slogan', sl.locale_code.replace('_', '-')],
                  Map({
                    slogan: sl.slogan || '',
                  })
                );
              });

              return newInfo;
            });
          })
          .update('images', (images) => {
            const { videos } = object;

            return images.withMutations((newImages) => {
              if (!videos || !Object.values(videos).length) {
                return newImages;
              }

              return newImages.set(
                'videos',
                List(
                  Object.values(videos).map((video) => {
                    return Map({ url: video.source });
                  })
                )
              );
            });
          });
      });

    case GET_MY_OBJECT_LOCATION:
      return state.update('location', (location) =>
        location.withMutations((loc) => {
          const { address, cadasterNr, estateRegisterNr } = action;
          loc
            .update('address', (addr) => addr.merge(address))
            .setIn(
              ['position', 'address'],
              address.latitude && address.longitude
                ? Map({ lat: Number(address.latitude), lng: Number(address.longitude) })
                : null
            )
            .set('cadasterNr', cadasterNr)
            .set('estateRegisterNr', estateRegisterNr);
        })
      );

    case GET_MY_OBJECT_IMAGES:
      return state.update('images', (images) => {
        return images.set('galleryImages', action.images.map(convertToEditingImage));
      });

    case SET_MY_OBJECT_STATUS:
      return state.set('status', action.status);

    case SET_MY_OBJECT_PROMO_CODE:
      return state.setIn(['packages', 'promoCode'], action.code === '' ? null : action.code);

    // CATEGORY
    case SET_AD_CATEGORY:
      return state.merge(action.category);

    case SET_LAND_REGISTRY_SEARCH:
      return state.setIn(['location', 'landRegistrySearch'], action.landRegistrySearch);

    // LOCATION
    case SET_ADDRESS:
      return state.setIn(['location', 'address'], action.address);

    case SET_CADASTER_NUMBER:
      return state.setIn(['location', 'cadasterNr'], action.cadasterNr);

    case SET_ESTATE_REGISTER_NUMBER:
      return state.setIn(['location', 'estateRegisterNr'], action.estateRegisterNr);

    case SET_POSITION:
      return state.setIn(['location', 'position'], action.position);

    case SET_ZOOM:
      return state.setIn(['location', 'address', 'zoom'], action.zoom);

    case SET_STREETVIEW_POV:
      return state.setIn(['location', 'pov'], action.pov);

    // INFO
    case SET_AD_INFO: {
      return state.update('info', (info) => {
        return Array.isArray(action.key) ? info.setIn(action.key, action.value) : info.set(action.key, action.value);
      });
    }

    case SET_SUMMER_COMMUNALS_COST:
      return state.setIn(['info', 'summerCommunalCostFile'], action.file);

    case SET_WINTER_COMMUNALS_COST:
      return state.setIn(['info', 'winterCommunalCostFile'], action.file);

    case SET_PERIOD_PACKAGE:
      return state.setIn(
        ['packages', 'period'],
        action.periodPackage.set('active', state.getIn(['packages', 'period', 'active']))
      );

    case SET_KV_EXPORT_SERVICE:
      return state.setIn(['packages', 'period', 'kvExportService'], Map(action.service));

    case UPDATE_PRODUCT_CONF:
      return state.setIn(['packages', 'services', 'draft', action.id], action.conf);

    case SET_AUTO_RENEWAL:
      return state.setIn(['packages', 'period', 'autoRenewal'], action.autoRenewal);

    // IMAGES

    case SORT_IMAGES:
      return state.setIn(
        ['images', 'galleryImages'],
        action.fake ? action.images : action.images.map(convertToEditingImage)
      );

    case UPDATE_OBJECT_IMAGE:
      return state.updateIn(['images', 'galleryImages'], (images) => {
        const imgIndex = images.findIndex((img) => img.id === action.newImage.id);
        if (imgIndex === -1) {
          return images;
        }
        const newImages = images.slice();
        newImages.splice(imgIndex, 1, action.newImage);
        return newImages;
      });

    case UPLOAD_OBJECT_IMAGES:
      return state
        .updateIn(['images', 'galleryImages'], (images) => images.concat(action.images.map(convertToEditingImage)))
        .setIn(['images', 'failedUploads'], action.failed);

    case DELETE_OBJECT_IMAGE:
      return state.updateIn(['images', 'galleryImages'], (images) => {
        const idx = images.indexOf(action.image);
        if (idx === -1) {
          return images;
        }
        const newImages = images.slice();
        newImages.splice(idx, 1);
        return newImages;
      });

    case SET_APARTMENT_PLANS:
      return state.setIn(['images', 'apartmentPlan'], action.files);

    case SET_FLOOR_PLANS:
      return state.setIn(['images', 'floorPlan'], action.files);

    case SET_VIDEO:
      return state.setIn(['images', 'videos', action.index, 'url'], action.url);

    case CREATE_NEW_VIDEO:
      return state.updateIn(['images', 'videos'], (videos) =>
        videos.push(
          Map({
            url: '',
          })
        )
      );

    case REMOVE_VIDEO:
      return state.updateIn(['images', 'videos'], (videos) => videos.delete(action.index));

    // CONFIRM
    case SETUP_PUBLISHING_PACKAGE:
    case GET_PUBLISHING_PACKAGE: {
      const { id, active, startDate, endDate, periodServices, services } = action;
      if (!id) {
        return state;
      }
      const periodService = periodServices.find((srv) => !srv.paid);
      const paymentId = periodService?.paymentId ?? services.find((srv) => !srv.paid)?.paymentId;
      return state
        .update('packages', (packages) => {
          return packages.withMutations((pkg) => {
            pkg
              .setIn(['publishing', 'startDate'], startDate)
              .setIn(['publishing', 'endDate'], endDate)
              .setIn(['publishing', 'active'], active);
            const period = periodService?.period ?? 0;
            pkg
              .setIn(
                ['period', 'selected'],
                periodService ? determinePeriodPackage(period, state.getIn(['transactionType', 'value'])) : null
              )
              .setIn(['period', 'price'], periodService?.price ?? 0)
              .setIn(['period', 'period'], period)
              .setIn(['period', 'paymentId'], periodService?.paymentId ?? null);
            services
              .filter((srv) => !srv.paid)
              .forEach((service) => {
                if (service.type === EXPORT_TO_KV) {
                  pkg
                    .setIn(['period', 'kvExportService', 'selected'], true)
                    .setIn(['period', 'kvExportService', 'price'], service.price);
                  return;
                }
                const pkgService = pkg.getIn(['services', 'draft', service.type]).set('selected', true).merge(service);

                pkg.setIn(['services', 'draft', service.type], pkgService);
              });
          });
        })
        .setIn(['packages', 'publishingId'], id)
        .setIn(['payment', 'paymentId'], paymentId);
    }

    case GET_MY_OBJECT_PUBLISHED_SERVICES: {
      const { active, startDate, endDate, periodServices, services } = action;
      return state.update('packages', (packages) => {
        return packages
          .setIn(['publishing', 'active'], active)
          .setIn(['publishing', 'startDate'], startDate)
          .setIn(['publishing', 'endDate'], endDate)
          .setIn(['period', 'active'], periodServices)
          .setIn(['services', 'active'], services);
      });
    }

    case SET_BANK:
      return state.setIn(['payment', 'selectedMethod'], action.bank);

    case RESET_OBJECT:
      return initialState;

    default:
      return state;
  }
}

const initialStateOriginal = Map();

function original(state = initialStateOriginal, action) {
  switch (action.type) {
    case GET_MY_OBJECT:
      return Map(action.object);
    case RESET_OBJECT:
      return initialStateOriginal;
    default:
      return state;
  }
}

export default combineReducers({
  userInterface,
  original,
});
