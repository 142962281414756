import Immutable from 'immutable';
import { PRICE_TYPE_OPTIONS, LOT_SIZE_TYPE_OPTIONS, DATE_ADDED_OPTIONS } from '@/constants/filters';
import { OBJECT_TYPES, TRANSACTION_TYPES } from '@/constants/object';
import { CONSTRUCTION_PHASE_OPTIONS_NP, OBJECT_TYPE_OPTIONS, TRANSACTION_TYPES_OPTIONS } from '@/constants/attributes';

export const formInitialState = Immutable.Map({
  id: 0,
  isDefault: true,
  transactionType: TRANSACTION_TYPES_OPTIONS.find((o) => o.get('value') === TRANSACTION_TYPES.SALE),
  objectType: OBJECT_TYPE_OPTIONS.find((o) => o.get('value') === OBJECT_TYPES.Apartment),
  subObjectTypes: Immutable.List(),
  constructionPhase: CONSTRUCTION_PHASE_OPTIONS_NP.find((option) => option.get('default')),
  searchTerm: new Map(),
  searchTermDraft: new Map(),
  minPrice: '',
  maxPrice: '',
  priceType: PRICE_TYPE_OPTIONS.find((option) => option.get('default')),
  minSize: '',
  maxSize: '',
  minLotSize: '',
  maxLotSize: '',
  lotSizeType: LOT_SIZE_TYPE_OPTIONS.find((option) => option.get('default')),
  minFloor: '',
  maxFloor: '',
  onlyLastFloor: false,
  minYearBuilt: '',
  maxYearBuilt: '',
  energyCertificate: Immutable.List(),
  rooms: Immutable.List(),
  fromOwner: false,
  condition: Immutable.List(),
  material: Immutable.List(),
  extras: Immutable.Map({
    [OBJECT_TYPES.Apartment]: Immutable.List(),
    [OBJECT_TYPES.House]: Immutable.List(),
    [OBJECT_TYPES.HouseShare]: Immutable.List(),
    [OBJECT_TYPES.Commercial]: Immutable.List(),
    [OBJECT_TYPES.Land]: Immutable.List(),
    [OBJECT_TYPES.CottageOrVilla]: Immutable.List(),
    [OBJECT_TYPES.Garage]: Immutable.List(),
  }),
  projectType: Immutable.List(),
  purpose: Immutable.Map({
    [OBJECT_TYPES.Commercial]: Immutable.List(),
    [OBJECT_TYPES.Land]: Immutable.List(),
  }),
  // additional: Immutable.List(),
  virtualTour: false,
  openHouse: false,
  specialOffers: false,
  auction: false,
  pricedrop: false,
  dateAdded: DATE_ADDED_OPTIONS.find((option) => option.get('default')),
  filterCount: 0,
  lastRun: 0,
  searchId: 0,
  searchParamsURL: '',
});
