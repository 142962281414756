import React from 'react';
import { TFunction } from 'next-i18next';

import { useAppDispatch, useAppSelector } from '@/hooks';
import { getModalState, isModalOpen } from '@/selectors/appSelectors';

import Button from '@/components/button/NextButton';
import SuccessIcon from '@/img/icons/success.svg';
import Modal from '../Modal';
import FancySection from '../ModalSections/FancySection';
import { ModalName, SuccessfulActionModalState } from '../modalInterfaces';
import { closeModal } from '../modalReducer';

interface Props {
  t: TFunction;
  onClose: (actionType?: string) => void;
}

const SuccessfulActionModal = ({ t, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const modalState = useAppSelector(getModalState);
  const isOpen = isModalOpen(modalState, ModalName.SuccessfulAction);
  const { actionType } = modalState.state as SuccessfulActionModalState;
  const onRequestClose = () => {
    onClose(actionType);
    dispatch(closeModal());
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <FancySection
        image={SuccessIcon}
        title={t('successfulAction.title')}
        subtitle={actionType ? t(`successfulAction.${actionType}`) : t('successfulAction.subtitle')}
      />
      <Button btnType="primary" extended onClick={onRequestClose}>
        {t('common.close')}
      </Button>
    </Modal>
  );
};

export default SuccessfulActionModal;
