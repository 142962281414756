import { LocationLevel } from '@city24/common/enums/location/LocationLevel';

export function isKeywordTerm(term: string): boolean {
  return term.startsWith('q|');
}
export function keywordTerm(value: string): string {
  return `q|${value}`;
}

export type AddressTypeName = 'county' | 'parish' | 'city' | 'village' | 'district' | 'street';

export function addressTypeFromLevel(level: LocationLevel): AddressTypeName {
  switch (level) {
    case LocationLevel.County:
      return 'county';
    case LocationLevel.Parish:
      return 'parish';
    case LocationLevel.City:
      return 'city';
    case LocationLevel.Village:
      return 'village';
    case LocationLevel.District:
      return 'district';
    case LocationLevel.Street:
    default:
      return 'street';
  }
}

const addressRegex = /^address\.(county|parish|city|district|village|street|street_house)\|([0-9|]+)$/;

export function isAddressTerm(term: string, type = ''): boolean {
  if (type) {
    return term.startsWith(`address.${type}|`);
  }
  return addressRegex.test(term);
}
export function addressTerm(type: string, value: string): string {
  if (!type) {
    // eslint-disable-next-line no-console
    console.error('Missing type for address term');
  }
  return `address.${type}|${value}`;
}

export function parseAddressTerm(term: string): RegExpMatchArray {
  if (!term) {
    return [];
  }
  const match = term.match(addressRegex);
  if (!match) {
    return [];
  }
  return match;
}
