import { Map } from 'immutable';

import {
  SET_CONSUMER_LOAN_CALC,
  SET_HOME_LOAN_CALC,
  SET_MAX_LOAN_CAPABILITY_CALC,
  SET_NOTARIOUS_COST_CALC,
} from '@/constants/actions';
import {
  LOAN_APPLICANTS_COUNT_OPTIONS,
  MULTI_BANK_HOME_LOAN_CALCULATOR,
  OTHER_DEPENDANTS_OPTIONS,
  PERIOD_OPTIONS_MONTHS,
  PERIOD_OPTIONS_YEARS,
  SELF_FINANCE_UNIT_OPTIONS,
} from '@/constants/finance';
import { defaultLuminorParams } from '@/components/finance/Calculators/loanCalculation';
import { shuffleArray } from '@/utils/helpers';
import { createSlice } from '@reduxjs/toolkit';
import hydrateAction from '@/actions/hydrateAction';

const initialState = {
  homeLoan: Map({
    price: null,
    period: PERIOD_OPTIONS_YEARS.find((i) => i.get('value') === `${defaultLuminorParams.period}`),
    interest: defaultLuminorParams.interest,
    selfFinancing: defaultLuminorParams.selfFinancing,
    selfFinancingUnit: SELF_FINANCE_UNIT_OPTIONS.find((i) => i.get('value') === defaultLuminorParams.selfFinancingUnit),
  }),
  consumerLoan: Map({
    loanAmount: 7000,
    period: PERIOD_OPTIONS_MONTHS.find((i) => i.get('value') === '60'),
  }),
  maxLoanCapability: Map({
    applicantCount: LOAN_APPLICANTS_COUNT_OPTIONS.find((i) => i.get('value') === 'alone'),
    otherDependants: OTHER_DEPENDANTS_OPTIONS.find((i) => i.get('value') === '0'),
    monthlyIncome: null,
    monthlyPayments: null,
  }),
  notariousCost: Map({
    price: null,
    loanAmount: null,
  }),
  multiBank: {
    euriborRate: 3.3,
    bankOrder: shuffleArray(MULTI_BANK_HOME_LOAN_CALCULATOR.map((item) => item.name)),
  },
};

const calculators = createSlice({
  name: 'calculators',
  initialState,
  reducers: {
    setEuriborRate(state, action) {
      state.multiBank.euriborRate = action.payload;
    },
    setHomeLoanCalc(state, action) {
      state.homeLoan = action.payload;
    },
    setConsumerLoanCalc(state, action) {
      state.consumerLoan = action.payload;
    },
    setMaxLoanCapabilityCalc(state, action) {
      state.maxLoanCapability = action.payload;
    },
    setNotariousCostCalc(state, action) {
      state.notariousCost = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(hydrateAction, (state, action) => {
        state.multiBank = action.payload.calculators.multiBank;
      }) /** @deprecated replace with slice actions above */
      .addDefaultCase((state, action) => {
        switch (action.type) {
          case SET_HOME_LOAN_CALC:
            state.homeLoan = action.payload;
            break;

          case SET_CONSUMER_LOAN_CALC:
            state.consumerLoan = action.payload;
            break;

          case SET_MAX_LOAN_CAPABILITY_CALC:
            state.maxLoanCapability = action.payload;
            break;

          case SET_NOTARIOUS_COST_CALC:
            state.notariousCost = action.payload;
            break;

          default:
            break;
        }
      });
  },
});

export const { actions } = calculators;

export default calculators.reducer;
