import React, { useCallback, useContext, useState } from 'react';
import ReactGA from '@/utils/ga';
import type { TFunction } from 'next-i18next';
import produce, { original } from 'immer';

import { useAppDispatch, useAppSelector } from '@/hooks';
import { validateEmail } from '@/utils/validation';
import { SITE_NAME } from '@/constants';
import { GA_NEW_ACCOUNT } from '@/constants/ga';

import { createAccount } from '@/actions/userActions';
import { getModalState, getPrefixedRoutes, isModalOpen } from '@/selectors/appSelectors';
import { getUserViolations } from '@/selectors/userSelectors';

import AppContext from '@/components/appContext';
import Button from '@/components/button/NextButton';
import Input from '@/components/input/Input';
import ButtonGroup from '@/components/button/ButtonGroup';
import CheckboxField from '@/components/checkbox/CheckboxField';
import Recaptcha, { RecaptchaProps } from '@/components/Recaptcha/Recaptcha';
import Trans from '@/components/translation/Trans';

import Shield from '@/img/icons/shield.svg';

import Modal from '../Modal';
import ModalHeader from '../ModalHeader';
import FancySection from '../ModalSections/FancySection';
import { ModalName, ConfirmModalState } from '../modalInterfaces';
import { closeModal } from '../modalReducer';

interface Props {
  t: TFunction;
}

interface State {
  email: string;
  emailMissing: boolean;
  emailNotEmail: boolean;
  username: string;
  usernameMissing: boolean;
  phone: string;
  phoneMissing: boolean;
  password: string;
  passwordMissing: boolean;
  passwordTooShort: boolean;
  agree: boolean;
  notAgreed: boolean;
  subscribeToNewsletter: boolean;
}

interface CaptchaState {
  verified: boolean;
  token: string;
  version: number;
}

function RegisterModal({ t }: Props) {
  const { Link } = useContext(AppContext);
  const dispatch = useAppDispatch();
  const modal = useAppSelector(getModalState);
  const violations = useAppSelector(getUserViolations);
  const routes = useAppSelector(getPrefixedRoutes);
  const [state, setState] = useState<State>({
    email: '',
    emailMissing: false,
    emailNotEmail: false,
    username: '',
    usernameMissing: false,
    phone: '',
    phoneMissing: false,
    password: '',
    passwordMissing: false,
    passwordTooShort: false,
    agree: false,
    notAgreed: false,
    subscribeToNewsletter: false,
  });

  const [captcha, setCaptchaState] = useState<CaptchaState>({ verified: false, token: '', version: 3 });
  const onCaptchaVerified: RecaptchaProps['onVerified'] = useCallback(
    (verified, token, version) => {
      setCaptchaState({ verified, token, version });
    },
    [setCaptchaState]
  );

  const createAccountFromForm = () => {
    const { email, password, username, phone, agree, subscribeToNewsletter } = state;

    const formErrors = {
      emailMissing: !email,
      emailNotEmail: !validateEmail(email),
      usernameMissing: !username,
      phoneMissing: !phone,
      passwordMissing: !password,
      passwordTooShort: password.length > 0 && password.length < 8,
      notAgreed: !agree,
    };

    if (
      captcha.verified &&
      !formErrors.emailMissing &&
      !formErrors.emailNotEmail &&
      !formErrors.usernameMissing &&
      !formErrors.phoneMissing &&
      !formErrors.passwordMissing &&
      !formErrors.passwordTooShort &&
      !formErrors.notAgreed
    ) {
      dispatch(
        createAccount(
          email,
          password,
          username,
          phone,
          routes.accountVerification.replace(':hash', ''),
          subscribeToNewsletter,
          captcha
        )
      );
    }

    setState(
      produce((draft) => {
        return { ...original(draft), ...formErrors };
      })
    );

    ReactGA.event({
      category: GA_NEW_ACCOUNT,
      action: 'create_account_button',
    });
  };

  const {
    email,
    emailMissing,
    emailNotEmail,
    username,
    usernameMissing,
    phone,
    phoneMissing,
    password,
    passwordMissing,
    passwordTooShort,
    notAgreed,
    agree,
    subscribeToNewsletter,
  } = state;
  const { confirmed } = modal.state as ConfirmModalState;

  let emailInUse: string;

  violations.forEach((violation) => {
    if (violation.message === 'errors.email.unique') {
      emailInUse = violation.message;
    }
  });

  const closeRegisterModal = () => {
    dispatch(closeModal());
    ReactGA.event({
      category: GA_NEW_ACCOUNT,
      action: 'close_button',
    });
  };

  const renderContent = () => {
    if (confirmed) {
      return (
        <>
          <FancySection
            image={Shield}
            title={t('registerConfirmModal.title')}
            subtitle={t('registerConfirmModal.subtitle')}
          />
          <Button btnType="primary" extended onClick={closeRegisterModal}>
            {t('common.close')}
          </Button>
        </>
      );
    }
    return (
      <div>
        <ModalHeader title={t('newAccountModal.title')} />
        <ButtonGroup vertical wide>
          <Input
            notched
            label={t('common.email')}
            value={email}
            type="email"
            getValue={(value: string) =>
              setState(
                produce((draft) => {
                  draft.email = value;
                })
              )
            }
            error={(() => {
              if (emailMissing) {
                return t('errors.fillField');
              }
              if (emailNotEmail) {
                return t('errors.email.notEmail');
              }
              if (emailInUse) {
                return t(emailInUse);
              }
              return null;
            })()}
            gaEvent={() =>
              ReactGA.event({
                category: GA_NEW_ACCOUNT,
                action: 'insert_email',
              })
            }
            onBlur={() => {
              setState(
                produce((draft) => {
                  draft.emailMissing = false;
                  draft.emailNotEmail = false;
                })
              );
            }}
          />
          <Input
            notched
            label={t('common.fullName')}
            value={username}
            getValue={(value: string) =>
              setState(
                produce((draft) => {
                  draft.username = value;
                })
              )
            }
            error={usernameMissing && t('errors.fillField')}
            gaEvent={() =>
              ReactGA.event({
                category: GA_NEW_ACCOUNT,
                action: 'insert_fullname',
              })
            }
            onBlur={() => {
              setState(
                produce((draft) => {
                  draft.usernameMissing = false;
                })
              );
            }}
          />
          <Input
            notched
            label={t('common.phone')}
            value={phone}
            getValue={(value: string) =>
              setState(
                produce((draft) => {
                  draft.phone = value;
                })
              )
            }
            error={phoneMissing && t('errors.fillField')}
            gaEvent={() =>
              ReactGA.event({
                category: GA_NEW_ACCOUNT,
                action: 'insert_phone',
              })
            }
            onBlur={() => {
              setState(
                produce((draft) => {
                  draft.phoneMissing = false;
                })
              );
            }}
          />
          <Input
            type="password"
            notched
            label={t('common.password')}
            value={password}
            getValue={(value: string) =>
              setState(
                produce((draft) => {
                  draft.password = value;
                })
              )
            }
            error={(() => {
              if (passwordTooShort) {
                return t('errors.passwordTooShort');
              }
              if (passwordMissing) {
                return t('errors.fillField');
              }
              return null;
            })()}
            caption={t('registration.passwordMinLength')}
            gaEvent={() =>
              ReactGA.event({
                category: GA_NEW_ACCOUNT,
                action: 'insert_password',
              })
            }
            onBlur={() => {
              setState(
                produce((draft) => {
                  draft.passwordTooShort = false;
                  draft.passwordMissing = false;
                })
              );
            }}
          />
          <CheckboxField
            text={
              <Trans
                t={t}
                i18nKey="registration.agreeToTerms"
                values={{ portal: SITE_NAME }}
                components={{
                  privacyLink: (
                    <Link key="privacy" href={routes.privacyRegulations} className="inline-link" target="_blank">
                      {routes.privacyRegulations}
                    </Link>
                  ),
                  termsLink: (
                    <Link key="terms" href={routes.termsOfUse} className="inline-link" target="_blank">
                      {routes.termsOfUse}
                    </Link>
                  ),
                }}
              />
            }
            id="agree-to-terms"
            getValue={(agree: boolean) => {
              setState(
                produce((draft) => {
                  draft.agree = agree;
                })
              );
              ReactGA.event({
                category: GA_NEW_ACCOUNT,
                action: 'agreePrivacyPolicyTerms',
              });
            }}
            checked={agree}
            error={notAgreed && t('errors.agreeToTerms')}
          />
          <CheckboxField
            text={t('common.subscribeToNewsletter')}
            id="subscribe-to-newsletter"
            getValue={(subscribeToNewsletter: boolean) => {
              setState(
                produce((draft) => {
                  draft.subscribeToNewsletter = subscribeToNewsletter;
                })
              );
              ReactGA.event({
                category: GA_NEW_ACCOUNT,
                action: 'subscribeToNewsletter',
              });
            }}
            checked={subscribeToNewsletter}
          />
          <Recaptcha onVerified={onCaptchaVerified} skipBackendVerification />
          <Button btnType="primary" onClick={createAccountFromForm} disabled={!captcha.verified}>
            {t('newAccountModal.createAccount')}
          </Button>
        </ButtonGroup>
      </div>
    );
  };

  return (
    <Modal
      isOpen={isModalOpen(modal, ModalName.Register)}
      onRequestClose={closeRegisterModal}
      contentLabel={confirmed ? t('registerConfirmModal.title') : t('newAccountModal.title')}
    >
      {renderContent()}
    </Modal>
  );
}

export default RegisterModal;
