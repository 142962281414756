export const SEARCH_OBJECTS_INIT = 'SEARCH_OBJECTS_INIT';
export const SEARCH_OBJECTS = 'SEARCH_OBJECTS';
export const SEARCH_PREV_NEXT_OBJECTS = 'SEARCH_PREV_NEXT_OBJECTS';
export const SET_RESULTS_LOADING = 'SET_RESULTS_LOADING';
export const DO_AUTOCOMPLETE = 'DO_AUTOCOMPLETE';

export const GET_MAIN_PAGE_OBJECTS = 'GET_MAIN_PAGE_OBJECTS';
export const GET_MAIN_PAGE_CAMPAIGNS_INFO = 'GET_MAIN_PAGE_CAMPAIGNS_INFO';
export const GET_MAIN_PAGE_CAMPAIGN = 'GET_MAIN_PAGE_CAMPAIGN';
export const GET_OBJECT_TYPE_COUNTS = 'GET_OBJECT_TYPE_COUNTS';

export const GET_OBJECT_DETAILS = 'GET_OBJECT_DETAILS';
export const GET_PROJECT_DETAILS = 'GET_PROJECT_DETAILS';
export const GET_MODULAR_HOUSE_DETAILS = 'GET_MODULAR_HOUSE_DETAILS';
export const OBJECT_STATUS_ERROR = 'OBJECT_STATUS_ERROR';
export const SET_STREET_VIEW_AVAILABILITY = 'SET_STREET_VIEW_AVAILABILITY';

export const GET_PROJECT_REALTIES = 'GET_PROJECT_REALTIES';
export const GET_LINKED_OBJECTS = 'GET_LINKED_OBJECTS';

export const SET_OBJECT_LOADING = 'SET_OBJECT_LOADING';
export const SET_OBJECT_DETAIL_BACK = 'SET_OBJECT_DETAIL_BACK';
export const SET_OBJECT_MEDIA_FULL = 'SET_OBJECT_MEDIA_FULL';
export const SET_SLIDE_COUNT = 'SET_SLIDE_COUNT';
export const SET_OPEN_HOUSE_DETAILS = 'SET_OPEN_HOUSE_DETAILS';

export const GET_COUNTIES = 'GET_COUNTIES';
export const GET_PARISHES = 'GET_PARISHES';
export const GET_CITIES = 'GET_CITIES';
export const GET_DISTRICTS = 'GET_DISTRICTS';
export const GET_POPULAR_AREAS = 'GET_POPULAR_AREAS';

export const GET_SEB_NEWS_ARTICLES = 'GET_SEB_NEWS_ARTICLES';
export const GET_INTERNAL_FEED_ARTICLES = 'GET_INTERNAL_FEED_ARTICLES';
export const GET_CENU_BANKA_DATA = 'GET_CENU_BANKA_DATA';

// MODALS
export const SET_SEARCH_MODAL = 'SET_SEARCH_MODAL';
export const SET_SAVE_SEARCH_MODAL = 'SET_SAVE_SEARCH_MODAL';
export const SET_SAVE_SEARCH_CONFIRM_MODAL = 'SET_SAVE_SEARCH_CONFIRM_MODAL';
export const SET_SAVED_SEARCH_REMOVE_MODAL = 'SET_SAVED_SEARCH_REMOVE_MODAL';
export const SET_SIGN_IN_MODAL = 'SET_SIGN_IN_MODAL';
export const SET_REGISTER_MODAL = 'SET_REGISTER_MODAL';
export const SET_REPORT_AD_MODAL = 'SET_REPORT_AD_MODAL';
export const SET_REPORT_AD_CONFIRM_MODAL = 'SET_REPORT_AD_CONFIRM_MODAL';
export const SET_SAVE_FAVOURITE_MODAL = 'SET_SAVE_FAVOURITE_MODAL';
export const SET_AREA_SELECT_MODAL = 'SET_AREA_SELECT_MODAL';
export const MESSAGE_BROKER_ERROR = 'MESSAGE_BROKER_ERROR';
export const SET_PASSWORD_RESET_MODAL = 'SET_PASSWORD_RESET_MODAL';
export const SET_DELETE_MY_OBJECT_MODAL = 'SET_DELETE_MY_OBJECT_MODAL';
export const SET_INVOICES_SENT_MODAL = 'SET_INVOICES_SENT_MODAL';
export const SET_SUCCESSFUL_ACTION_MODAL = 'SET_SUCCESSFUL_ACTION_MODAL';
export const SET_DETAIL_PREVIEW_MODAL = 'SET_DETAIL_PREVIEW_MODAL';
export const SET_SEB_CALCULATOR_MODAL = 'SET_SEB_CALCULATOR_MODAL';
export const SET_OBJECT_LIST_MODAL = 'SET_OBJECT_LIST_MODAL';

// SET FILTERS
export const CLEAR_SEARCH_TERM_DRAFT = 'CLEAR_SEARCH_TERM_DRAFT';
export const SET_SEARCH_LAST_RUN = 'SET_SEARCH_LAST_RUN';
export const RESET_FILTERS = 'RESET_FILTERS';
export const SET_FILTER_COUNT = 'SET_FILTER_COUNT';
export const UPDATE_SEARCH_FILTERS = 'UPDATE_SEARCH_FILTERS';
export const SET_SEARCH_RESULTS_PER_PAGE = 'SET_SEARCH_RESULTS_PER_PAGE';

export const ADD_AUTOCOMPLETE_TERM = 'ADD_AUTOCOMPLETE_TERM';
export const REMOVE_AUTOCOMPLETE_TERM = 'REMOVE_AUTOCOMPLETE_TERM';

export const SET_SEARCH_URL = 'SET_SEARCH_URL';
export const SET_SEARCH_LAST_QUERY = 'SET_SEARCH_LAST_QUERY';
export const SET_SEARCH_FORM = 'SET_SEARCH_FORM';

// RESULTS
export const SET_RESULT_LAYOUT = 'SET_RESULT_LAYOUT';
export const SET_SORTING = 'SET_SORTING';
export const SET_OBJECT_RESULTS_PAGE = 'SET_OBJECT_RESULTS_PAGE';
export const SET_AREA_SELECT = 'SET_AREA_SELECT';
export const SET_MAP_OPENED = 'SET_MAP_OPENED';

// MAP
export const MAP_OBJECT_SELECTED = 'MAP_OBJECT_SELECTED';
export const MAP_CLEAR_SELECTED_ZOOM = 'MAP_CLEAR_SELECTED_ZOOM';
export const MAP_LOAD_OBJECT_DETAILS_INIT = 'MAP_LOAD_OBJECT_DETAILS_INIT';
export const MAP_LOAD_OBJECT_DETAILS = 'MAP_LOAD_OBJECT_DETAILS';
export const MAP_LOAD_OBJECT_DETAILS_FINISHED = 'MAP_LOAD_OBJECT_DETAILS_FINISHED';
export const MAP_OBJECT_UPDATE = 'MAP_OBJECT_UPDATE';
export const OBJECT_TO_MAP_MARKER = 'OBJECT_TO_MAP_MARKER';
export const HIGHLIGHT_OBJECT_MAP_MARKER = 'HIGHLIGHT_OBJECT_MAP_MARKER';
export const CLEAR_OBJECT_TO_MAP_MARKER = 'CLEAR_OBJECT_TO_MAP_MARKER';
export const MAP_CLEAR_OBJECTS = 'MAP_CLEAR_OBJECTS';
export const MAP_INFO_CLOSE = 'MAP_INFO_CLOSE';
export const MAP_LOAD_NEARBY_PLACES = 'MAP_LOAD_NEARBY_PLACES';
export const SET_MAP_BOUNDS = 'SET_MAP_BOUNDS';

// USER
export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';
export const SIGN_OUT = 'SIGN_OUT';
export const SOCIAL_LOGIN_INIT = 'SOCIAL_LOGIN_INIT';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const CREATE_ACCOUNT_ERROR = 'CREATE_ACCOUNT_ERROR';
export const SET_EMAIL = 'SET_EMAIL';
export const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS';
export const UPDATE_USER_SETTINGS_ERROR = 'UPDATE_USER_SETTINGS_ERROR';
export const GET_USER_INFO = 'GET_USER_INFO';
export const IMPERSONATE_USER = 'IMPERSONATE_USER';

export const GET_FAVOURITES = 'GET_FAVOURITES';
export const GET_FAVOURITES_SHORT_DETAILS = 'GET_FAVOURITES_SHORT_DETAILS';
export const SAVE_FAVOURITE = 'SAVE_FAVOURITE';
export const REMOVE_FAVOURITE = 'REMOVE_FAVOURITE';
export const GET_OBJECT_FAVOURITE_COUNT = 'GET_OBJECT_FAVOURITE_COUNT';
export const GET_INVOICES = 'GET_INVOICES';
export const GET_COUNTERS = 'GET_COUNTERS';

export const GET_SAVED_SEARCHES = 'GET_SAVED_SEARCHES';
export const GET_UNSUBSCRIBE_SAVED_SEARCHES = 'GET_UNSUBSCRIBE_SAVED_SEARCHES';
export const SAVE_SEARCH_SETTINGS_DRAFT = 'SAVE_SEARCH_SETTINGS_DRAFT';
export const CLEAR_SEARCH_SETTINGS_DRAFT = 'CLEAR_SEARCH_SETTINGS_DRAFT';
export const ADD_SAVED_SEARCH = 'ADD_SAVED_SEARCH';
export const REMOVE_SAVED_SEARCH = 'REMOVE_SAVED_SEARCH';
export const UPDATE_SAVED_SEARCH = 'UPDATE_SAVED_SEARCH';
export const SET_LATEST_SEARCHES = 'SET_LATEST_SEARCHES';

export const SET_PASSWORD_UPDATED = 'SET_PASSWORD_UPDATED';
export const PASSWORD_UPDATE_ERROR = 'PASSWORD_UPDATE_ERROR';
export const SET_PASSWORD_RESET_ERROR = 'SET_PASSWORD_RESET_ERROR';
export const SET_ACCOUNT_VERIFIED = 'SET_ACCOUNT_VERIFIED';
export const SET_UUID_VERIFIED = 'SET_UUID_VERIFIED';
export const UUID_VERIFICATION_ERROR = 'UUID_VERIFICATION_ERROR';
export const ACCOUNT_VERIFICATION_ERROR = 'ACCOUNT_VERIFICATION_ERROR';
export const SET_RECENTLY_VIEWED_OBJECTS = 'SET_RECENTLY_VIEWED_OBJECTS';
export const CLEAR_USER_ERRORS = 'CLEAR_USER_ERRORS';

// ADS
export const ADD_AD_MASTER = 'ADD_AD_MASTER';
export const REMOVE_AD_MASTER = 'REMOVE_AD_MASTER';
export const LOAD_AD_MASTER = 'LOAD_AD_MASTER';
export const LOAD_AD_SLAVE = 'LOAD_AD_SLAVE';
export const SET_AD_BANNER = 'SET_AD_BANNER';
export const SET_AD_KEY_VARS = 'SET_AD_KEY_VARS';
export const REMOVE_AD_SLAVE = 'REMOVE_AD_SLAVE';

export const REPORT_AD_ERROR = 'REPORT_AD_ERROR';

export const SET_OBJECT_NAV_COUNT = 'SET_OBJECT_NAV_COUNT';
// BROKER SEARCH
export const UPDATE_BROKER_SEARCH = 'UPDATE_BROKER_SEARCH';
export const SEARCH_BROKERS = 'SEARCH_BROKERS';
export const SEARCH_VIP_BROKERS = 'SEARCH_VIP_BROKERS';

// COMPANY SEARCH
export const UPDATE_COMPANY_SEARCH = 'UPDATE_COMPANY_SEARCH';
export const SEARCH_COMPANIES = 'SEARCH_COMPANIES';
export const SEARCH_BRANDED_COMPANIES = 'SEARCH_BRANDED_COMPANIES';

// OBJECT MANAGEMENT
export const SET_MY_OBJECT_STATUS = 'SET_MY_OBJECT_STATUS';
export const GET_MY_OBJECT = 'GET_MY_OBJECT';
export const GET_MY_OBJECT_LOCATION = 'GET_MY_OBJECT_LOCATION';
export const GET_MY_OBJECT_IMAGES = 'GET_MY_OBJECT_IMAGES';
export const GET_MY_OBJECT_PUBLISHED_SERVICES = 'GET_MY_OBJECT_PUBLISHED_SERVICES';

export const GET_MY_OBJECT_LIST = 'GET_MY_OBJECT_LIST';
export const UPDATE_MY_OBJECT = 'UPDATE_MY_OBJECT';
export const UNPUBLISH_MY_OBJECT = 'UNPUBLISH_MY_OBJECT';
export const DELETE_MY_OBJECT = 'DELETE_MY_OBJECT';

export const SET_MY_OBJECT_PROMO_CODE = 'SET_MY_OBJECT_PROMO_CODE';
export const SET_AD_CATEGORY = 'SET_AD_CATEGORY';

export const GET_OM_COUNTY_OPTIONS = 'GET_OM_COUNTY_OPTIONS';
export const GET_OM_CITY_PARISH_OPTIONS = 'GET_OM_CITY_PARISH_OPTIONS';
export const GET_OM_DISTRICT_SETTLEMENT_OPTIONS = 'GET_OM_DISTRICT_SETTLEMENT_OPTIONS';

export const SET_LAND_REGISTRY_SEARCH = 'SET_LAND_REGISTRY_SEARCH';
export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_POSITION = 'SET_POSITION';
export const SET_ZOOM = 'SET_ZOOM';
export const SET_STREETVIEW_POV = 'SET_STREETVIEW_POV';
export const SET_CADASTER_NUMBER = 'SET_CADASTER_NUMBER';
export const SET_ESTATE_REGISTER_NUMBER = 'SET_ESTATE_REGISTER_NUMBER';
export const SET_PERIOD_PACKAGE = 'SET_PERIOD_PACKAGE';
export const SET_AUTO_RENEWAL = 'SET_AUTO_RENEWAL';
export const UPDATE_PRODUCT_CONF = 'UPDATE_PRODUCT_CONF';
export const SETUP_PUBLISHING_PACKAGE = 'SETUP_PUBLISHING_PACKAGE';
export const GET_PUBLISHING_PACKAGE = 'GET_PUBLISHING_PACKAGE';
export const SET_KV_EXPORT_SERVICE = 'SET_KV_EXPORT_SERVICE';

export const SORT_IMAGES = 'SORT_IMAGES';
export const UPDATE_OBJECT_IMAGE = 'UPDATE_OBJECT_IMAGE';
export const UPLOAD_OBJECT_IMAGES = 'UPLOAD_OBJECT_IMAGES';
export const DELETE_OBJECT_IMAGE = 'DELETE_OBJECT_IMAGE';
export const SET_AD_INFO = 'SET_AD_INFO';
export const SET_APARTMENT_PLANS = 'SET_APARTMENT_PLANS';
export const SET_FLOOR_PLANS = 'SET_FLOOR_PLANS';
export const SET_VIDEO = 'SET_VIDEO';
export const CREATE_NEW_VIDEO = 'CREATE_NEW_VIDEO';
export const REMOVE_VIDEO = 'REMOVE_VIDEO';
export const SET_SUMMER_COMMUNALS_COST = 'SET_SUMMER_COMMUNALS_COST';
export const SET_WINTER_COMMUNALS_COST = 'SET_WINTER_COMMUNALS_COST';
export const SET_OBJECT_BOOKING = 'SET_OBJECT_BOOKING';

export const RESET_OBJECT = 'RESET_OBJECT';

// COMPANY DETAIL
export const GET_OFFICE_DETAILS = 'GET_OFFICE_DETAILS';
export const GET_OFFICE_STATISTICS = 'GET_OFFICE_STATISTICS';
export const GET_OFFICE_OBJECTS = 'GET_OFFICE_OBJECTS';
export const APPEND_OFFICE_OBJECTS = 'APPEND_OFFICE_OBJECTS';
export const RESET_OFFICE_OBJECTS = 'RESET_OFFICE_OBJECTS';

// Broker Detail
export const GET_BROKER_DETAILS = 'GET_BROKER_DETAILS';
export const GET_BROKER_STATISTICS = 'GET_BROKER_STATISTICS';
export const GET_BROKER_OBJECTS = 'GET_BROKER_OBJECTS';
export const GET_BROKER_RECENT_OBJECTS = 'GET_BROKER_RECENT_OBJECTS';
export const GET_BROKER_OFFICE = 'GET_BROKER_OFFICE';

export const UPDATE_PLACE_AD_STEP = 'UPDATE_PLACE_AD_STEP';
export const SET_BANK = 'SET_BANK';

// CALCULATORS

export const SET_HOME_LOAN_CALC = 'SET_HOME_LOAN_CALC';
export const SET_CONSUMER_LOAN_CALC = 'SET_CONSUMER_LOAN_CALC';
export const SET_MAX_LOAN_CAPABILITY_CALC = 'SET_MAX_LOAN_CAPABILITY_CALC';
export const SET_NOTARIOUS_COST_CALC = 'SET_NOTARIOUS_COST_CALC';

export const GET_CAMPAIGN_LANDING_PAGE_OBJECTS = 'GET_CAMPAIGN_LANDING_PAGE_OBJECTS';
export const SET_CAMPAIGN_LANDING_PAGE_LOADING = 'GET_CAMPAIGN_LANDING_PAGE_LOADING';
