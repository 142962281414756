import React from 'react';
import { Collection, Map, List } from 'immutable';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import ReactGA from '@/utils/ga';
import { createSelector } from 'reselect';

import api from '@/api/City24Api';
import { GA_SEARCH } from '@/constants/ga';
import { composeAutocompleteQuery } from '@/utils/search';
import { camelCase, identity, removeSpecialCharacters } from '@/utils/helpers';
import { addressTerm } from '@/utils/searchTerms';
import { getBrowser } from '@/selectors/appSelectors';

import Select from '@/components/select/Select';
import Button from '@/components/button/Button';
import ListSelect from '@/components/select/ListSelect';
import { getFiltersByObjectType, getSearchFilters } from '../searchSelectors';

function translateOption(option) {
  return option.get('name');
}
function searchQueryFromProps({ searchFilters, objectTypeFilters }) {
  return composeAutocompleteQuery(searchFilters, objectTypeFilters);
}
function mapAreaSelect(array, extra) {
  return List(
    array.map(({ name, id, type, count }) =>
      Map({
        name,
        value: addressTerm(type, id),
        extra: Map({ original: id, type, count }).merge(extra),
      })
    )
  );
}
function filterParents(parents, children) {
  return parents.filterNot((p) =>
    children.find((c) => c.getIn(['extra', p.getIn(['extra', 'type']), 'value']) === p.get('value'))
  );
}
const getUniqueTokens = createSelector(
  (state) => state.county,
  (state) => state.parish,
  (state) => state.cityOrDistrict,
  (county, parish, cityOrDistrict) => {
    if (parish.isEmpty() && cityOrDistrict.isEmpty()) {
      if (county.isEmpty()) {
        return List();
      }
      return List.of(county);
    }

    return filterParents(parish, cityOrDistrict).concat(cityOrDistrict);
  }
);

class AreaSelect extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      counties: List(),
      parishesCities: List(),
      citiesDistricts: List(),
      popularAreas: [],
      county: Map(),
      parish: List(),
      cityOrDistrict: List(),
      showCities: true,
    };
  }

  componentDidMount() {
    this.clearState();
    this.fetchPopularAreas();
    this.fetchCounties();
  }

  getTokenNames() {
    const tokenList = getUniqueTokens(this.state);
    return tokenList
      .map((token) => token.get('name'))
      .filter(identity)
      .toArray();
  }

  onCountySelect = (county) => {
    this.setState({
      parishesCities: List(),
      citiesDistricts: List(),
      county,
      parish: List(),
      cityOrDistrict: List(),
    });

    if (county.has('value')) {
      this.fetchChildrenByArea(county);
    }
  };

  onParishSelect = (parish) => {
    const { parish: prevParish } = this.state;
    this.setState({ parish, showCities: false });

    const selected = parish.toSet();
    const prevParishSet = prevParish.toSet();
    this.setState((state) => {
      const toRemove = prevParishSet.subtract(selected).map((p) => p.get('value'));
      return {
        citiesDistricts: state.citiesDistricts.filterNot((c) => toRemove.includes(c.get('value'))),
      };
    });

    selected.subtract(prevParishSet).forEach((p) => {
      this.fetchChildrenByArea(p);
    });
  };

  onCitySelect = (cityOrDistrict) => {
    this.setState({ cityOrDistrict });
  };

  getInsertText() {
    const { browser, t } = this.props;
    let tokenNames = this.getTokenNames();

    const maxTokens = browser.lessThan.large ? 1 : 3;

    if (tokenNames.length > maxTokens) {
      tokenNames = ` (${tokenNames.length})`;
    } else if (tokenNames.length) {
      tokenNames = ` (${tokenNames.join(', ')})`;
    }
    return `${t('common.insert')}${tokenNames || ''}`;
  }

  gaEventHandler = (label) => {
    ReactGA.event({
      category: GA_SEARCH,
      action: 'select_area',
      label,
    });
  };

  applyAreaSelect = () => {
    const { county, cityOrDistrict, parish } = this.state;
    const { onSubmit } = this.props;
    this.gaEventHandler('insert_button');
    const filteredParish = filterParents(parish, cityOrDistrict);
    const filteredCounty = filteredParish.isEmpty() && cityOrDistrict.isEmpty() ? county : Map();
    onSubmit(filteredCounty, filteredParish, cityOrDistrict);
    this.clearState();
  };

  setPopularArea = ({ id, name, type, promoted, parents }) => {
    [{ id, name, promoted, type }, ...parents].forEach((area) => {
      const val = Map({
        name: area.name,
        value: addressTerm(area.type, area.id),
        extra: Map({ original: area.id, type: area.type }),
      });
      if (area.promoted === 'city') {
        this.setState({ cityOrDistrict: List.of(val) });
      } else if (area.promoted === 'parish') {
        this.setState({ parish: List.of(val) });
      } else {
        this.setState((state) => ({
          county: state.counties.find((c) => c.get('value') === val.get('value')) || val,
        }));
      }
      this.fetchChildrenByType(area.type, val);
    });
  };

  clearState() {
    this.setState({
      parishesCities: List(),
      citiesDistricts: List(),
      county: Map(),
      parish: List(),
      cityOrDistrict: List(),
    });
  }

  fetchPopularAreas() {
    api.address
      .getPopularAreas(searchQueryFromProps(this.props))
      .then((res) => (res.ok ? res.json() : []))
      .then((areas) => {
        this.setState({ popularAreas: areas });
      });
  }

  fetchCounties() {
    const query = searchQueryFromProps(this.props);
    if (PORTAL_LV) {
      query.topLevel = true;
      const promises = [
        api.address.getCitiesByCountryWithCount(query).then((res) => (res.ok ? res.json() : [])),
        api.address.getCountiesWithCount(query).then((res) => (res.ok ? res.json() : [])),
      ];
      Promise.all(promises).then(([cities, counties]) => {
        this.setState({
          counties: mapAreaSelect(cities.concat(counties)),
        });
      });
    } else {
      api.address
        .getCountiesWithCount(query)
        .then((res) => (res.ok ? res.json() : []))
        .then((counties) =>
          this.setState({
            counties: mapAreaSelect(counties),
          })
        );
    }
  }

  fetchAfterCounty(county) {
    const id = county.getIn(['extra', 'original']);
    const query = searchQueryFromProps(this.props);
    if (PORTAL_LV) {
      const promises = [
        api.address.getParishesWithCount(id, query).then((res) => (res.ok ? res.json() : [])),
        api.address.getCitiesByCountyWithCount(id, query).then((res) => (res.ok ? res.json() : [])),
      ];
      Promise.all(promises).then(([parishes, cities]) => {
        this.setState({
          parishesCities: mapAreaSelect(parishes.concat(cities), { county }),
        });
      });
    } else {
      api.address
        .getParishesWithCount(id, query)
        .then((res) => (res.ok ? res.json() : []))
        .then((parishes) => {
          this.setState({
            parishesCities: mapAreaSelect(parishes, { county }),
          });
        });
    }
  }

  fetchAfterParish(parish) {
    const id = parish.getIn(['extra', 'original']);
    const query = searchQueryFromProps(this.props);

    if (PORTAL_LV) {
      api.address
        .getVillagesWithCount(id, query)
        .then((res) => (res.ok ? res.json() : []))
        .then((villages) => {
          if (villages.length > 0) {
            this.setState((state) => ({
              citiesDistricts: state.citiesDistricts.push(
                Map({
                  name: parish.get('name'),
                  value: parish.get('value'),
                  options: mapAreaSelect(villages, {
                    county: parish.getIn(['extra', 'county']),
                    parish,
                  }),
                })
              ),
            }));
          }
        });
    } else {
      api.address
        .getCitiesWithCount(id, query)
        .then((res) => (res.ok ? res.json() : []))
        .then((cities) => {
          if (cities.length > 0) {
            this.setState((state) => ({
              citiesDistricts: state.citiesDistricts.push(
                Map({
                  name: parish.get('name'),
                  value: parish.get('value'),
                  options: mapAreaSelect(cities, {
                    county: parish.getIn(['extra', 'county']),
                    parish,
                  }),
                })
              ),
            }));
          }
        });
    }
  }

  fetchAfterCity(city) {
    const id = city.getIn(['extra', 'original']);

    const stateVariable = PORTAL_LV && !city.getIn(['extra', 'county']) ? 'parishesCities' : 'citiesDistricts';

    api.address
      .getDistrictsWithCount(id, searchQueryFromProps(this.props))
      .then((res) => (res.ok ? res.json() : []))
      .then((districts) => {
        if (districts.length > 0) {
          this.setState((state) => ({
            [stateVariable]: state.citiesDistricts.push(
              Map({
                name: city.get('name'),
                value: city.get('value'),
                options: mapAreaSelect(districts, {
                  county: city.getIn(['extra', 'county']),
                  parish: city.getIn(['extra', 'parish']),
                  city,
                }),
              })
            ),
          }));
        }
      });
  }

  fetchChildrenByArea(area) {
    this.fetchChildrenByType(area.getIn(['extra', 'type']), area);
  }

  fetchChildrenByType(type, area) {
    if (type === 'county') {
      this.fetchAfterCounty(area);
    } else if (type === 'parish') {
      this.fetchAfterParish(area);
    } else if (type === 'city') {
      this.fetchAfterCity(area);
    }
  }

  render() {
    const { renderButton, browser, t } = this.props;
    const { counties, parishesCities, citiesDistricts, popularAreas, county, parish, cityOrDistrict, showCities } =
      this.state;

    return (
      <>
        <div className="area-select">
          {county.isEmpty() && (
            <div className="as-modal__popular">
              <h5 className="as-modal__popular-title">{t('areaSelectModal.popularPlaces')}</h5>
              <ul className="as-modal__popular-list">
                {popularAreas.map((area) => {
                  return (
                    <li key={area.id} className="as-modal__popular-list-item">
                      <Button
                        outline
                        small
                        text={area.name}
                        onClick={() => {
                          ReactGA.event({
                            category: GA_SEARCH,
                            action: ': select_area',
                            label: `popularPlaces_${removeSpecialCharacters(camelCase(area.name))}`,
                          });
                          this.setPopularArea(area);
                        }}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {browser.lessThan.large ? (
            <div className="area-select__content">
              <Select
                notched
                showCount
                native={isMobile}
                label={t('common.county')}
                options={counties}
                selected={county}
                empty="-"
                getValue={this.onCountySelect}
                gaEvent={() => this.gaEventHandler('county_dropdown')}
                gaEventSelect={false}
                translateOption={translateOption}
                t={t}
              />
              {county.has('value') && parishesCities.size > 0 && (
                <Select
                  multiple
                  notched
                  options={parishesCities}
                  selected={parish}
                  label={t(`common.cityParish.${PORTAL}`)}
                  getValue={this.onParishSelect}
                  gaEvent={() => this.gaEventHandler('cityParish_dropdown')}
                  gaEventSelect={false}
                  translateOption={translateOption}
                  t={t}
                  onDropdownOpen={() => this.setState({ showCities: false })}
                  onDropdownClose={() => this.setState({ showCities: true })}
                />
              )}
              {county.has('value') && citiesDistricts.size > 0 && showCities && (
                <div className="as-modal__districts">
                  <h5 className="as-modal__districts-title">{t(`common.cityDistrict.${PORTAL}`)}</h5>
                  <ListSelect
                    className="as-modal__districts-list"
                    multiselect
                    options={citiesDistricts}
                    selected={cityOrDistrict}
                    disabled={!parish.size}
                    getValue={this.onCitySelect}
                    gaEvent={() => this.gaEventHandler('cityDistrict_dropdown')}
                    gaEventSelect={false}
                    translateOption={translateOption}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="as-modal__desk-select">
              <div>
                <h4>{t('common.county')}</h4>
                <ListSelect arrows options={counties} selected={county} getValue={this.onCountySelect} />
              </div>
              <div>
                <h4>{t(`common.cityParish.${PORTAL}`)}</h4>
                <ListSelect
                  multiselect
                  options={parishesCities}
                  selected={parish}
                  disabled={!parishesCities.size}
                  getValue={this.onParishSelect}
                />
              </div>
              <div>
                <h4>{t(`common.cityDistrict.${PORTAL}`)}</h4>
                <ListSelect
                  multiselect
                  options={citiesDistricts}
                  selected={cityOrDistrict}
                  disabled={!citiesDistricts.size}
                  getValue={this.onCitySelect}
                  gaEvent={() => this.gaEventHandler('cityDistrict_dropdown')}
                  gaEventSelect={false}
                  translateOption={translateOption}
                />
              </div>
            </div>
          )}
        </div>
        {renderButton(this.getInsertText(), this.applyAreaSelect)}
      </>
    );
  }
}

AreaSelect.propTypes = {
  browser: PropTypes.objectOf(PropTypes.objectOf(PropTypes.bool)).isRequired,
  searchFilters: PropTypes.objectOf(PropTypes.instanceOf(Collection)).isRequired,
  objectTypeFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  t: PropTypes.func.isRequired,
  renderButton: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
// AreaSelect.defaultProps = {};

function mapStateToProps(state) {
  return {
    browser: getBrowser(state),
    searchFilters: getSearchFilters(state),
    objectTypeFilters: getFiltersByObjectType(state),
  };
}

export default connect(mapStateToProps)(AreaSelect);
