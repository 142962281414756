import { createSelector } from 'reselect';

import { RootState } from '@/store';
import { MY_OBJECTS_PER_PAGE } from '@/constants/object';
import { addFavouriteToObject } from '@/utils/helpers';

export const getUser = (state: RootState) => state.user;
export const getUserIsBroker = (state: RootState) => getUser(state).isBroker;
export const getInvoices = (state: RootState) => getUser(state).invoices;
export const getUserFirstName = (state: RootState) => getUser(state).firstName;
export const getUserLastName = (state: RootState) => getUser(state).lastName;
export const getUserUsername = (state: RootState) => getUser(state).username;
export const getUserEmail = (state: RootState) => getUser(state).email;
export const getUserPhone = (state: RootState) => getUser(state).phone;
export const getFavourites = (state: RootState) => getUser(state).favourites;
export const hasFavouritesChanged = (state: RootState) => getUser(state).favouritesChanged;
export const getFavouriteObjects = (state: RootState) => getUser(state).favouriteObjects;
export const getSavedSearches = (state: RootState) => getUser(state).savedSearches;
export const getSavedSearchToUnsubscribe = (state: RootState) => getUser(state).savedSearchToUnsubscribe;
export const getSaveSearchSettingsDraft = (state: RootState) => getUser(state).saveSearchSettingsDraft;
export const hasSearchesChanged = (state: RootState) => getUser(state).searchesChanged;
export const getLatestSearches = (state: RootState) => getUser(state).latestSearches;
export const hasPasswordResetError = (state: RootState) => getUser(state).passwordResetError;
export const getRecentlyViewedObjects = createSelector(
  (state) => getUser(state).recentlyViewedObjects,
  getFavourites,
  (objects, favourites) => {
    return objects.map((object) => addFavouriteToObject(favourites, object));
  }
);

export const getInvoicesPageCount = createSelector(
  (state: RootState) => getUser(state).invoicesTotalItems,
  (invoicesTotalItems) => {
    return Math.ceil(invoicesTotalItems ?? 0 / MY_OBJECTS_PER_PAGE);
  }
);

export const getPasswordUpdated = (state: RootState) => getUser(state).passwordUpdated;
export const getAccountVerified = (state: RootState) => getUser(state).accountVerified;
export const getAccountNeedsConfirmation = (state: RootState) => getUser(state).needsConfirmation;
export const getUuidVerified = (state: RootState) => getUser(state).uuid.valid;

export const getUserError = (state: RootState) => getUser(state).error;
export const getUserErrorMessage = (state: RootState) => getUser(state).errorMessage;
export const getUserViolations = (state: RootState) => getUser(state).violations;
export const isEmailNotUnique = createSelector(getUserViolations, (violations) => {
  return violations.find((violation) => violation.message === 'errors.email.unique')?.message ?? false;
});

export const getUserImpersonationStatus = (state: RootState) => getUser(state).impersonate;
