import { Map } from 'immutable';
import {
  SET_SEARCH_MODAL,
  SET_SAVE_SEARCH_CONFIRM_MODAL,
  SET_SAVED_SEARCH_REMOVE_MODAL,
  SET_SIGN_IN_MODAL,
  SET_REGISTER_MODAL,
  SET_REPORT_AD_MODAL,
  SET_REPORT_AD_CONFIRM_MODAL,
  SET_SAVE_FAVOURITE_MODAL,
  SET_AREA_SELECT_MODAL,
  SIGN_IN,
  SET_PASSWORD_RESET_MODAL,
  SET_DELETE_MY_OBJECT_MODAL,
  SET_INVOICES_SENT_MODAL,
  SET_SUCCESSFUL_ACTION_MODAL,
  SET_DETAIL_PREVIEW_MODAL,
  SET_SEB_CALCULATOR_MODAL,
  SET_OBJECT_LIST_MODAL,
} from '@/constants/actions';
import { omit } from '@/utils/object';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ModalName, ModalState } from './modalInterfaces';

const initialState = {
  open: false,
  state: { name: '' } as ModalState,
  // FIXME: The following properties are deprecated and should be removed
  // for each modal that is migrated to the new state structure
  searchModalOpened: false,
  saveSearchModal: Map({
    opened: false,
    item: Map(),
    edit: false,
  }),
  saveSearchConfirmModalOpened: false,
  savedSearchRemoveModal: Map({
    opened: false,
    id: null,
  }),
  reportAdConfirmModalOpened: false,
  saveFavouriteModalOpened: false,
  areaSelectModalOpened: false,
  deleteMyObjectModal: Map({
    opened: false,
    action: 'delete',
    id: null,
    friendlyId: null,
    address: null,
    location: null,
  }),
  invoicesSentModalOpened: false,
  successfulActionModal: Map({
    opened: false,
    actionType: null,
  }),
  SEBCalculatorModal: Map({
    opened: false,
    price: null,
    transactionType: null,
  }),
  detailPreviewModal: Map({
    opened: false,
    id: null,
  }),
  businessCardModal: Map({
    opened: false,
  }),
  objectListModal: Map({
    opened: false,
    objectId: null,
    projectName: null,
  }),
};

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal(state, action: PayloadAction<ModalState | ModalName>) {
      state.open = true;
      if (typeof action.payload === 'string') {
        state.state = { name: action.payload } as ModalState;
      } else {
        state.state = action.payload;
      }
    },
    closeModal(state) {
      state.open = false;
    },
  },
  extraReducers(builder) {
    /** @deprecated default case, create standard actions */
    builder.addDefaultCase((state, action) => {
      switch (action.type) {
        case SET_SEARCH_MODAL:
          state.searchModalOpened = action.searchModalOpened;
          break;
        case SET_SAVE_SEARCH_CONFIRM_MODAL:
          state.saveSearchConfirmModalOpened = action.saveSearchConfirmModalOpened;
          break;

        case SET_SAVED_SEARCH_REMOVE_MODAL:
          state.savedSearchRemoveModal = state.savedSearchRemoveModal
            .set('opened', action.opened)
            .set('id', action.id)
            .set('name', action.name)
            .set('emailAlerts', action.emailAlerts);
          break;

        case SET_SIGN_IN_MODAL:
          state.open = action.signInModalOpened;
          state.state = { name: ModalName.SignIn };
          break;

        case SET_REGISTER_MODAL:
          state.open = action.registerModalOpened;
          state.state = { name: ModalName.Register, confirmed: !!action.confirmed };
          break;

        case SET_REPORT_AD_MODAL:
          state.open = action.reportAdModalOpened;
          state.state = { name: ModalName.ReportAd };
          break;

        case SET_REPORT_AD_CONFIRM_MODAL:
          state.open = action.reportAdConfirmModalOpened;
          state.state = { name: ModalName.ReportAd, confirmed: true };
          break;

        case SET_SAVE_FAVOURITE_MODAL:
          state.saveFavouriteModalOpened = action.saveFavouriteModalOpened;
          break;

        case SET_AREA_SELECT_MODAL:
          state.areaSelectModalOpened = action.areaSelectModalOpened;
          break;

        case SIGN_IN:
          state.open = false;
          break;

        case SET_PASSWORD_RESET_MODAL:
          state.open = action.passwordResetModalOpened;
          state.state = { name: ModalName.Register, confirmed: !!action.confirmed };
          break;

        case SET_DELETE_MY_OBJECT_MODAL:
          state.deleteMyObjectModal = state.deleteMyObjectModal.merge(omit(action, 'type'));
          break;

        case SET_INVOICES_SENT_MODAL:
          state.invoicesSentModalOpened = action.invoicesSentModalOpened;
          break;

        case SET_SUCCESSFUL_ACTION_MODAL:
          state.successfulActionModal = state.successfulActionModal
            .set('opened', action.opened)
            .set('actionType', action.actionType);
          break;

        case SET_SEB_CALCULATOR_MODAL:
          state.SEBCalculatorModal = state.SEBCalculatorModal.set('opened', action.opened)
            .set('price', action.price)
            .set('transactionType', action.transactionType);
          break;

        case SET_DETAIL_PREVIEW_MODAL:
          state.detailPreviewModal = state.detailPreviewModal.set('opened', action.opened).set('id', action.id);
          break;

        case SET_OBJECT_LIST_MODAL:
          state.objectListModal = state.objectListModal
            .set('opened', action.opened)
            .set('objectId', action.objectId)
            .set('projectName', action.projectName);
          break;
        default:
          break;
      }
    });
  },
});

export const {
  actions: { openModal, closeModal },
} = modalsSlice;

export default modalsSlice.reducer;
