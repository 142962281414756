import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import ReactGA from '@/utils/ga';
import type { TFunction } from 'next-i18next';

import { RootState } from '@/store';
import { GA_SIGNIN } from '@/constants/ga';
import { clearUserErrors, signIn } from '@/actions/userActions';
import { getModalState, isModalOpen } from '@/selectors/appSelectors';
import { getUserErrorMessage } from '@/selectors/userSelectors';

import Button from '@/components/button/NextButton';
import Input from '@/components/input/Input';
import ButtonGroup from '@/components/button/ButtonGroup';
import Separator from '@/components/separator/Separator';
import FacebookLogin from '@/components/login/FacebookLogin';
import GoogleLogin from '@/components/login/GoogleLogin';
import Alert from '@/components/alert/Alert';
import Icon from '@/components/icon/Icon';

import { closeModal, openModal } from '../modalReducer';
import { ModalName } from '../modalInterfaces';
import Modal from '../Modal';
import ModalHeader from '../ModalHeader';

import css from './SignInModal.module.scss';

interface Props extends PropsFromRedux {
  t: TFunction;
  error: string;
}

interface State {
  email: string;
  emailMissing: boolean;
  password: string;
  passwordMissing: boolean;
  passwordFieldType: 'password' | 'text';
}

class SignInModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      email: '',
      emailMissing: false,
      password: '',
      passwordMissing: false,
      passwordFieldType: 'password',
    };
  }

  componentDidMount(): void {
    const { dispatch } = this.props;
    dispatch(clearUserErrors());
  }

  signIn = () => {
    const { dispatch } = this.props;
    const { email, password } = this.state;

    const formErrors = {
      emailMissing: !email,
      passwordMissing: !password,
    };

    if (!formErrors.emailMissing && !formErrors.passwordMissing) {
      dispatch(signIn(email, password));

      ReactGA.event({
        category: GA_SIGNIN,
        action: 'submit_button',
      });
    }

    this.setState(formErrors);
  };

  createAccount = () => {
    const { dispatch } = this.props;

    dispatch(openModal(ModalName.Register));

    ReactGA.event({
      category: GA_SIGNIN,
      action: 'create_account_button',
    });
  };

  togglePasswordVisibility = () => {
    const { passwordFieldType } = this.state;
    this.setState({
      passwordFieldType: passwordFieldType === 'password' ? 'text' : 'password',
    });
  };

  renderShowPasswordButton = () => {
    return (
      <button
        type="button"
        onClick={() => {
          this.togglePasswordVisibility();
        }}
      >
        <Icon name="eye" />
      </button>
    );
  };

  render() {
    const { isOpen, dispatch, error, t } = this.props;
    const { emailMissing, passwordMissing, email, password, passwordFieldType } = this.state;

    return (
      <Modal
        className={css.modal}
        isOpen={isOpen}
        onRequestClose={() => {
          dispatch(closeModal());
          ReactGA.event({
            category: GA_SIGNIN,
            action: 'close_button',
          });
        }}
        contentLabel={t('signInModal.title')}
      >
        <div>
          <ModalHeader title={t('signInModal.title')} />
          {!emailMissing && !passwordMissing && error && (
            <Alert error text={error.startsWith('errors.') ? t(error) : t('errors.unauthorized')} />
          )}
          <ButtonGroup vertical wide>
            <Input
              gaEvent={() =>
                ReactGA.event({
                  category: GA_SIGNIN,
                  action: 'insert_email',
                })
              }
              name="username"
              type="email"
              allowAutocomplete
              notched
              label={t('common.email')}
              value={email}
              getValue={(value: string) => this.setState({ email: value })}
              error={emailMissing && t('errors.fillField')}
              onBlur={() => {
                this.setState({ emailMissing: false });
              }}
            />
            <div>
              <Input
                gaEvent={() =>
                  ReactGA.event({
                    category: GA_SIGNIN,
                    action: 'insert_password',
                  })
                }
                name="current-password"
                type={passwordFieldType}
                allowAutocomplete
                notched
                label={t('common.password')}
                value={password}
                getValue={(value: string) => this.setState({ password: value })}
                error={passwordMissing && t('errors.fillField')}
                onBlur={() => {
                  this.setState({ passwordMissing: false });
                }}
                unit={this.renderShowPasswordButton()}
              />
              <Button
                btnType="link"
                className={css.forgot}
                onClick={() => {
                  dispatch(openModal(ModalName.PasswordReset));
                  ReactGA.event({
                    category: GA_SIGNIN,
                    action: 'forgot_password',
                  });
                }}
              >
                {t('signInModal.forgotPassword')}
              </Button>
            </div>
            <Button btnType="primary" onClick={this.signIn}>
              {t('common.signIn')}
            </Button>
            <Button outline onClick={this.createAccount}>
              {t('common.createAccount')}
            </Button>
          </ButtonGroup>
          <Separator text={t('common.or')} />
          <h5 className="modal__section-title">{t('signInModal.socialMedia')}</h5>
          <ButtonGroup vertical>
            <FacebookLogin
              render={(renderProps) => (
                <Button
                  className={css.fbBtn}
                  icon="facebook"
                  bgColor="fb"
                  onClick={() => {
                    renderProps.onClick?.();
                    ReactGA.event({
                      category: GA_SIGNIN,
                      action: 'open_facebook_button',
                    });
                  }}
                >
                  {t('signInModal.facebookSignIn')}
                </Button>
              )}
            />
            <GoogleLogin
              render={(renderProps) => (
                <Button
                  className={css.googleBtn}
                  icon="google-color"
                  bgColor="google"
                  onClick={() => {
                    renderProps.onClick();
                    ReactGA.event({
                      category: GA_SIGNIN,
                      action: 'open_google_button',
                    });
                  }}
                >
                  {t('signInModal.googleSignIn')}
                </Button>
              )}
            />
          </ButtonGroup>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    isOpen: isModalOpen(getModalState(state), ModalName.SignIn),
    error: getUserErrorMessage(state),
  };
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SignInModal);
