import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Collection } from 'immutable';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter, generatePath, Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import {
  ALTERO_CAMPAIGN_RUNNING,
  INBANK_CAMPAIGN_RUNNING,
  LOAN_APPLICATION_TYPE,
  showAlteroInsuranceContent,
  showInBankCalculator,
  showMultiBankCalculator,
} from '@/constants/finance';
import { AGENT_SHOW_PHONE } from '@/constants/statistics';
import { getSingleBankCalculatorVariant } from '@/selectors/calculatorsSelectors';
import {
  addressComponent,
  locationComponent,
  formatPrice,
  applyImageFormat,
  formatDate,
  urlFormatLocation,
} from '@/utils/formatting';
import { getBookedUntil, isBooked, isInactive } from '@/utils/objectStates';
import { showSlogan, getPricePerUnitType } from '@/utils/object';
import { getMultibankCalculatorUnitType, makeFavourite } from '@/utils/helpers';
import { getBrokerName, getContact } from '@/utils/contacts';

import {
  M_DETAIL,
  M_NP_DETAIL,
  S_DETAIL_CONTACT,
  S_DETAIL_NP_CONTACT,
  D_NP_DETAIL,
  D_DETAIL,
  D_NP_DETAIL_CONTACT,
  D_DETAIL_CONTACT,
  D_DETAIL_INFO,
} from '@/constants/ad';
import {
  FLOOR,
  TOTAL_FLOORS,
  NO_OF_APARTMENTS,
  NUMBER_OF_BEDROOMS,
  NUMBER_OF_BATHROOMS,
  LIFT,
  BALCONY_TYPE,
  SANITARY_ARRANGEMENTS,
  CONSTRUCTION_YEAR,
  STORAGE_ROOM,
  STORAGE_SPACE,
  ENERGY_CERTIFICATE_TYPE,
  IMMEDIATELY_AVAILABLE,
  FURNITURE,
  ELECTRICITY,
  GAS,
  WATER,
  ROAD,
  HAS_SEWERAGE,
  HEATING_SYSTEM,
  VENTILATION_TYPE,
  MEETING_ROOM,
  RENT_TO_OWN,
  ELEVATOR,
  SHARED_WAREHOUSE,
  SHARED_LOUNGE,
  SMART_HOME_FEATURES,
  HEATED_FLOORS,
  GARDEN,
  VAT,
  COMING_SOON,
} from '@/constants/attributes';
import { LOCATION_NAME_RIGA, OBJECT_TYPES, SIZE_UNIT_VALUES, TRANSACTION_TYPES } from '@/constants/object';
import {
  PERIOD_OPTIONS,
  DEFAULT_INTEREST,
  DEFAULT_SELF_FINANCE,
  SELF_FINANCE_UNIT_OPTIONS,
} from '@/constants/SEBCalculator';
import { GA_DETAIL_VIEW } from '@/constants/ga';

import { getLanguage, getPrefixedRoutes, getSignedIn, getBrowser, getLocale } from '@/selectors/appSelectors';
// import { getGetAProVariant } from '@/selectors/integrationsSelectors';
import { getObjectFavouriteCount, getProjectRealties } from './objectDetailSelectors';
import { getCertificatesFromBusinessCard } from '../broker/BrokerSearchSelectors';
import { sendStatistics } from '@/actions/statisticsActions';
import * as modalActions from '../modals/ModalActions';
import * as userActions from '../../actions/userActions';
import { setObjectNavCount } from './ObjectDetailsActions';

import { computeLoanSale } from '../SEBCalculator/SEBCalculatorFunctions';

import BrokerSticky from '../broker/BrokerSticky/BrokerSticky';
import Dropdown from '@/components/dropdown/Dropdown';
import Accordion from '@/components/accordion/Accordion';
import AccordionHead from '@/components/accordion/AccordionHead';
import AccordionBody from '@/components/accordion/AccordionBody';
import Button from '../button/Button';
import ButtonGroup from '../button/ButtonGroup';
import OpenHouse from './OpenHouse/OpenHouse';
import MainSpecs from './MainSpecs/MainSpecs';
import SecondarySpecs from './SecondarySpecs/SecondarySpecs';
import FullSpecs from './FullSpecs/FullSpecs';
// import Broker from '../broker/Broker';
import Label from '../label/Label';
import LabelGroup from '../label/LabelGroup';
import LoadingDetailsImg from '../../img/loading-details.svg';
import NearbyPlaces from './NearbyPlaces/NearbyPlaces';
import ObjectList from './ObjectsList/ObjectList';
import Ad from '../Ad/Ad';
import Loading from '../loading/Loading';
import ContactCard, { CONTACT_CARD_LAYOUT } from '../ContactCard/ContactCard';
import Separator from '../separator/Separator';
import Description from './Description/Description';
import Timeline from './Timeline/Timeline';
import ObjectPrice from './ObjectPrice/ObjectPrice';
import Icon from '@/components/icon/Icon';
import Tooltip from '@/components/tooltip/Tooltip';
import LeadForm from '@/components/LeadForm/LeadForm';
import ObjectBreadcrumbs from '@/components/object/ObjectBreadcrumbs';
import GetAProServices from '@/components/integrations/GetAPro/GetAProServices';
import { getObjectDetailRoute, typeCombinationString } from '@/utils/route';
import { convertIRI } from '@/api/City24Api';
import MultiBankCalculator from '@/components/finance/Calculators/MultiBankCalculator/MultiBankCalculator';
import SingleBankCalculator from '@/components/finance/Calculators/SingleBankCalculator/SingleBankCalculator';
import AlteroInsuranceCalculator from '@/components/finance/Calculators/Altero/AlteroInsuranceCalculator';
import coopLogo from '@/img/calculators/coop.svg';
import tbbLogo from '@/img/calculators/tbb.svg';
import { AsyncDataStatus } from '@/types/collections';
import { openModal } from '../modals/modalReducer';
import { ModalName } from '../modals/modalInterfaces';
import BusinessCardModal from '../modals/BusinessCardModal/BusinessCardModal';
import InbankCalculator from '@/components/integrations/Inbank/InbankCalculator';
import { getParkingSelectionAttributeName, getPurposeOfUseAttributeName } from '@/utils/realty';

const CenuBanka = React.lazy(() => import('@/components/integrations/CenuBanka/CenuBanka'));

const propTypes = {
  object: PropTypes.instanceOf(Collection.Keyed).isRequired,
  t: PropTypes.func,
  gaEvent: PropTypes.func,
  addressClickHandler: PropTypes.func,
  requestNearbyPlaces: PropTypes.func,
};

const defaultProps = {
  t: (a) => a,
  gaEvent: null,
  addressClickHandler: null,
  requestNearbyPlaces: () => {},
};

const getLoanSum = createSelector(
  (object) => object.get('price_min') || object.get('price'),
  (price) =>
    computeLoanSale(
      price,
      PERIOD_OPTIONS.find((option) => option.get('default')).get('value'),
      DEFAULT_INTEREST,
      DEFAULT_SELF_FINANCE,
      SELF_FINANCE_UNIT_OPTIONS.find((option) => option.get('default')).get('value')
    )
);

class ObjectDetailInfo extends React.PureComponent {
  objDetailRef = React.createRef();

  brokerRef = React.createRef();

  constructor(props) {
    super(props);
    this.defaultOjectsToShow = 10;
    this.state = {
      monthlyPayment: null,
      objectsToShow: this.defaultOjectsToShow,
      calculatorTab: LOAN_APPLICATION_TYPE.homeLoan,
      objectActionDropdownOpened: false,
      showBrokerPhone: false,
      favouritesTooltipVisible: true,
    };
    this.oaDropdownRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ monthlyPayment: null });
    window.addEventListener('scroll', this.handleScroll, { passive: true });
    document.addEventListener('click', this.handleObjectActionDropdownClickOutside);
    setTimeout(() => this.setState({ favouritesTooltipVisible: false }), 5000);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleObjectActionDropdownClickOutside);
    window.removeEventListener('scroll', this.handleScroll);
  }

  closeObjectActionDropdown = () => {
    this.setState({ objectActionDropdownOpened: false });
  };

  handleObjectActionDropdownClickOutside = (e) => {
    if (!this.oaDropdownRef.current) return;
    if (!this.oaDropdownRef.current.contains(e.target)) {
      this.closeObjectActionDropdown();
    }
  };

  handleObjectActionDropdownClick = () => {
    const { objectActionDropdownOpened } = this.state;
    this.setState({ objectActionDropdownOpened: !objectActionDropdownOpened });
  };

  handleScroll = () => {
    const { handleScroll } = this.props;
    if (handleScroll) {
      const objDetail = this.objDetailRef.current;
      const b = objDetail.getBoundingClientRect().bottom;
      const h = window.innerHeight;

      const offset = h - b;
      if (offset < 0) {
        handleScroll();
      }
    }
  };

  sendStatistics = (action) => {
    const { object } = this.props;
    sendStatistics(action, object.get('guid'));
  };

  getTeliaOffer = () => {
    const { object } = this.props;

    if (!PORTAL_EE) {
      return null;
    }
    if (object.get('unit_type') === OBJECT_TYPES.Commercial && object.get('for_private_user')) {
      return 'commercial';
    }
    if (
      [OBJECT_TYPES.Apartment, OBJECT_TYPES.House, OBJECT_TYPES.HouseShare, OBJECT_TYPES.CottageOrVilla].includes(
        object.get('unit_type')
      )
    ) {
      return 'home';
    }
    return null;
  };

  getAlexelaOffer = () => {
    const { object } = this.props;
    if (object.get('unit_type') === OBJECT_TYPES.Commercial) {
      return 'business';
    }
    if (
      [OBJECT_TYPES.Apartment, OBJECT_TYPES.House, OBJECT_TYPES.HouseShare, OBJECT_TYPES.CottageOrVilla].includes(
        object.get('unit_type')
      )
    ) {
      return 'private';
    }
    return null;
  };

  renderBrokers = () => {
    const { broker, office, object } = this.props;
    const additionalBrokers = object.get('additional_brokers');
    return (
      broker.size > 0 && (
        <>
          {this.renderBroker(broker, office)}
          {additionalBrokers &&
            !object.hasIn(['attributes', COMING_SOON]) &&
            additionalBrokers.map((b) => {
              return this.renderBroker(b, office);
            })}
        </>
      )
    );
  };

  renderBroker = (broker, office) => {
    const { routes, t, object, preview } = this.props;

    return object.hasIn(['attributes', COMING_SOON]) ? (
      <LeadForm object={object} broker={broker} />
    ) : (
      <ContactCard
        preview={preview}
        layout={CONTACT_CARD_LAYOUT.HORIZONTAL}
        key={broker.get('id')}
        t={t}
        brokerId={broker.get('id')}
        isVipBroker={broker.get('is_vip')}
        isDeveloper={office && office.get('developer')}
        isLeadRequest={object.hasIn(['attributes', COMING_SOON])}
        image={broker && applyImageFormat(broker.getIn(['business_card', 'picture_url']), 'photo', 'user')}
        name={getBrokerName(broker)}
        nameClickRoute={broker && generatePath(routes.brokerDetail, { id: broker.get('id'), slug: broker.get('slug') })}
        company={office && office.getIn(['business_card', 'office_name'])}
        companyLogo={
          office && applyImageFormat(office.getIn(['business_card', 'logo_url']), 'thumb130x136', 'officeMainLogo')
        }
        companyClickRoute={
          office &&
          generatePath(routes.companyDetail, {
            id: office.get('id'),
            slug: office.get('slug'),
          })
        }
        rating={broker.get('average_rating')}
        showRatingNumber
        email={broker.get('email_address')}
        phone={getContact(broker, 'phone')}
        awards={broker.get('awards')}
        certificates={getCertificatesFromBusinessCard(broker.get('business_card'))}
        sendStatistics={this.sendStatistics}
        hideMissingImg
        gaEvent={this.gaEventHandler}
        className="obj-detail__contact"
        object={object}
      />
    );
  };

  saveFavourite = () => {
    const { saveFavourite, object } = this.props;
    if (saveFavourite) saveFavourite(object.get('id'), object.get('guid'), object.get('unit_type'), true);
  };

  removeFavourite = () => {
    const { removeFavourite, object } = this.props;
    if (removeFavourite)
      removeFavourite(object.get('favourite'), makeFavourite(object.get('guid'), object.get('unit_type')), true);
  };

  hasPriceDrop = () => {
    const { object } = this.props;
    return Number(object.get('price')) < Number(object.get('old_price'));
  };

  renderPrice = () => {
    const { object } = this.props;
    const priceDrop = this.hasPriceDrop() ? <span className="icon icon-arrow-down obj-detail__pricedrop" /> : null;

    const minPrice = object.get('price_min') ? formatPrice(object.get('price_min'), true) : '...';
    const maxPrice = object.get('price_max') ? formatPrice(object.get('price_max'), true) : '...';

    const price = () => {
      if (object.get('price_min') || object.get('price_max')) {
        return `${minPrice} - ${maxPrice} €`;
      }
      if (object.get('price')) {
        return formatPrice(object.get('price'));
      }
      return null;
    };

    const oldPrice = Number(object.get('old_price')) > Number(object.get('price')) && (
      <span className="obj-detail__old-price">{`${formatPrice(object.get('old_price'))}`}</span>
    );

    return (
      <>
        {priceDrop && priceDrop}
        {price() && <span>{price()}</span>}
        {oldPrice && oldPrice}
      </>
    );
  };

  getMonthlyPayment = () => {
    const { object } = this.props;
    const val = getLoanSum(object);
    return val.monthlyPayment;
  };

  gaEventHandler = (action) => {
    const { gaEvent } = this.props;
    if (gaEvent) {
      gaEvent(action);
    }
  };

  renderObjectList = () => {
    const { t, object, projectRealties: realtiesProxy, routes, history, setObjectNavCount } = this.props;
    const { objectsToShow } = this.state;

    if (!realtiesProxy) {
      return null;
    }

    const realties = realtiesProxy.data;
    if (
      realtiesProxy.status === AsyncDataStatus.None ||
      (realtiesProxy.status === AsyncDataStatus.Loaded && !realties.length)
    ) {
      return null;
    }

    let projectNameLink;
    if (object.get('unit_type') === OBJECT_TYPES.NewProject) {
      const path = getObjectDetailRoute(
        routes.projectDetail,
        object.get('friendly_id'),
        typeCombinationString(convertIRI(object.get('transaction_type')), OBJECT_TYPES.NewProject, 'url'),
        urlFormatLocation(object.get('address'))
      );
      projectNameLink = (
        <Link to={path} className="obj-detail__project-link">
          {object.get('project_name')}
        </Link>
      );
    } else if (object.get('project')) {
      const project = object.get('project');
      const path = getObjectDetailRoute(
        routes.projectDetail,
        project.get('friendly_id'),
        typeCombinationString(convertIRI(project.get('transaction_type')), OBJECT_TYPES.NewProject, 'url'),
        urlFormatLocation(project.get('address'))
      );
      projectNameLink = (
        <Link to={path} className="obj-detail__project-link">
          {project.get('project_name')}
        </Link>
      );
    }

    return (
      <>
        <h4 className="obj-detail__slogan">
          {t('objectDetail.realtiesInProject')} {projectNameLink}
        </h4>
        {realtiesProxy.status === AsyncDataStatus.Loaded ? (
          <>
            <ObjectList
              onlyMainFeatures
              objects={realties}
              t={t}
              itemsToShow={objectsToShow}
              routes={routes}
              history={history}
              onClick={() => {
                this.gaEventHandler('click_realtiesInProject');
                setObjectNavCount('push', 0);
              }}
            />
            {objectsToShow < realties.length && objectsToShow !== null && (
              <div className="accordion__footer">
                <Button
                  outline
                  extended
                  text={`${t('common.showMore')} (${realties.length})`}
                  onClick={() => {
                    this.gaEventHandler('click_showMore');
                    this.setState({ objectsToShow: null });
                  }}
                />
              </div>
            )}
            {this.defaultOjectsToShow < realties.length && objectsToShow === null && (
              <div className="accordion__footer">
                <Button
                  outline
                  extended
                  text={t('common.showLess')}
                  onClick={() => {
                    this.gaEventHandler('click_showLess');
                    this.setState({ objectsToShow: this.defaultOjectsToShow });
                  }}
                />
              </div>
            )}
          </>
        ) : (
          <Loading />
        )}
      </>
    );
  };

  renderPricePerSqrm = () => {
    const { object } = this.props;

    const pricePerUnit = object.get('price_per_unit');
    const pricePerUnitMin = object.get('price_per_unit_min');
    const pricePerUnitMax = object.get('price_per_unit_max');

    if (object && (pricePerUnit || pricePerUnitMin || pricePerUnitMax)) {
      return (
        <span className="obj-detail__m2-price-val">
          {(() => {
            if (pricePerUnitMin || pricePerUnitMax) {
              return `${pricePerUnitMin ? formatPrice(pricePerUnitMin, true) : '...'}- ${
                pricePerUnitMax ? formatPrice(pricePerUnitMax) : '...'
              }`;
            }
            return formatPrice(pricePerUnit);
          })()}
          {`/${getPricePerUnitType(
            object.get('unit_type'),
            object.get('size_unit_id'),
            object.get('lot_size_unit_id')
          )}`}
        </span>
      );
    }
    return null;
  };

  renderFavouriteButton = () => {
    const { object, t, browser } = this.props;
    const isFavourite = object.get('favourite');
    const saveFavouriteLabel = isFavourite ? t('saveFavourite.saved') : t('saveFavourite.save');

    return (
      <Button
        link={browser.greaterThan.small}
        secondary={browser.lessThan.medium}
        small={browser.lessThan.medium}
        className={`obj-detail__action-btn${isFavourite ? ' obj-detail__action-btn--selected' : ''}`}
        icon={isFavourite ? 'star' : 'star-o'}
        text={saveFavouriteLabel}
        onClick={() => {
          if (isFavourite) {
            this.gaEventHandler('unsave_favourite');
            this.removeFavourite();
          } else {
            this.gaEventHandler('save_favourite');
            this.saveFavourite();
          }
        }}
      />
    );
  };

  renderFavouriteButtonWithTooltip = () => {
    const { favouriteCount, browser, t } = this.props;
    const { favouritesTooltipVisible } = this.state;

    return favouriteCount > 3 ? (
      <>
        <div role="tooltip" data-tooltip-id="favorite-tooltip">
          {this.renderFavouriteButton()}
        </div>
        <Tooltip
          id="favorite-tooltip"
          content={t('objectDetail.favouriteCount', { count: favouriteCount })}
          isOpen={favouritesTooltipVisible}
          place={browser.lessThan.medium ? 'bottom' : 'top'}
        />
      </>
    ) : (
      this.renderFavouriteButton()
    );
  };

  render() {
    const {
      object,
      openHouseDetails,
      broker,
      nearbyPlaces,
      requestNearbyPlaces,
      setReportAdModalOpened,
      setShareModal,
      t,
      loading,
      full,
      language,
      browser,
      prevNext,
      isPrint,
      showPrintView,
      preview,
      openModal,
      locale,
      addressClickHandler,
      // getAProVariant,
      singleBankCalculatorVariant,
      routes,
      currentPath,
      projectRealties,
    } = this.props;
    const { objectActionDropdownOpened, showBrokerPhone } = this.state;

    const constructionPhase = object.getIn(['attributes', 'PHASE_OF_CONSTRUCTION']);
    const objectType = object.get('unit_type');

    const objDetailStyle = classNames({
      'obj-detail__content': true,
      'obj-detail__content--loading': loading,
    });

    const totalArea = () => {
      if (object.get('property_size')) {
        return object.get('property_size');
      }
      if (object.get('size_min') && object.get('size_max')) {
        return `${object.get('size_min')} - ${object.get('size_max')}`;
      }
      return 0;
    };

    const numberOfRooms = () => {
      if (object.get('room_count')) {
        return object.get('room_count');
      }
      if (object.get('room_count_min') && object.get('room_count_max')) {
        return `${object.get('room_count_min')} - ${object.get('room_count_max')}`;
      }
      return 0;
    };

    let adIds;
    if (objectType) {
      if (objectType === OBJECT_TYPES.NewProject) {
        adIds = {
          master: isMobile ? M_NP_DETAIL : D_NP_DETAIL,
          id: isMobile ? S_DETAIL_NP_CONTACT : D_NP_DETAIL_CONTACT,
        };
      } else {
        adIds = {
          master: isMobile ? M_DETAIL : D_DETAIL,
          id: isMobile ? S_DETAIL_CONTACT : D_DETAIL_CONTACT,
        };
      }
    }
    const status = object && object.get('status_id');

    const bookedUntil = (() => {
      if (object) {
        return getBookedUntil(object);
      }
      return false;
    })();

    return (
      <div className="obj-detail">
        {prevNext}
        <div className={objDetailStyle} ref={this.objDetailRef}>
          {loading ? (
            <div className="obj-detail__loading">
              <img src={LoadingDetailsImg} alt="loading animation" />
            </div>
          ) : (
            <>
              {browser.greaterThan.medium && (
                <ObjectBreadcrumbs object={object} t={t} routes={routes} currentPath={currentPath} />
              )}
              <div className="obj-detail__desktop-actions">
                {!preview && browser.greaterThan.small && (
                  <ButtonGroup className="obj-detail__actions">
                    {this.renderFavouriteButtonWithTooltip()}
                    <Button
                      link
                      className="obj-detail__action-btn"
                      icon="share"
                      text={t('common.share')}
                      onClick={() => {
                        setShareModal(true, object.get('friendly_id'), object.get('unit_type'), true);
                        this.gaEventHandler('click_shareAd');
                      }}
                    />
                    <Button
                      link
                      className="obj-detail__action-btn"
                      icon="print"
                      text={t('common.print')}
                      onClick={() => showPrintView(true)}
                    />
                    <div ref={this.oaDropdownRef}>
                      <Button
                        link
                        className="obj-detail__action-btn"
                        highlightOnFocus
                        icon="more"
                        onClick={this.handleObjectActionDropdownClick}
                      />
                      {objectActionDropdownOpened ? (
                        <Dropdown
                          opened={objectActionDropdownOpened}
                          closeDropdown={this.closeObjectActionDropdown}
                          floatRight
                        >
                          <ul className="obj-detail__action-list">
                            <li>
                              <Button
                                link
                                className="obj-detail__action-btn"
                                icon="flag"
                                text={t('common.reportAd')}
                                onClick={() => {
                                  setReportAdModalOpened(true);
                                  this.gaEventHandler('click_reportAd');
                                }}
                              />
                            </li>
                          </ul>
                        </Dropdown>
                      ) : null}
                    </div>
                  </ButtonGroup>
                )}
              </div>
              <h1 className="obj-detail__title">
                {!isInactive(status) && (
                  <ObjectPrice
                    t={t}
                    layout="detail"
                    objectType={object && object.get('unit_type')}
                    price={object.get('price')}
                    oldPrice={object.get('old_price')}
                    minPrice={object.get('price_min')}
                    maxPrice={object.get('price_max')}
                    pricePerUnit={object.get('price_per_unit')}
                    pricePerUnitMin={object.get('price_per_unit_min')}
                    pricePerUnitMax={object.get('price_per_unit_max')}
                    pricePerUnitType={getPricePerUnitType(
                      object.get('unit_type'),
                      object.get('size_unit_id'),
                      object.get('lot_size_unit_id')
                    )}
                    rentToOwn={
                      object.getIn(['attributes', RENT_TO_OWN]) ? (
                        <div className="obj-detail__rent-to-own">{t('objectDetail.label.rentToOwn')}</div>
                      ) : null
                    }
                    // 'yes' and 'no' values are deprecated, remove if CNP-3676 is released
                    addVAT={object.getIn(['attributes', VAT]) === false || object.getIn(['attributes', VAT]) === 'no'}
                    loading={loading}
                  />
                )}
                {isBooked(bookedUntil) || isInactive(status) ? (
                  <LabelGroup className="obj-detail__labels">
                    {isBooked(bookedUntil) ? (
                      <Label bg="primary">
                        {bookedUntil instanceof Date
                          ? t('objectDetail.label.bookedUntil', { date: formatDate(bookedUntil) })
                          : t('objectDetail.label.booked')}
                      </Label>
                    ) : null}
                    {isInactive(status) ? <Label red>{t('objectDetail.label.archived')}</Label> : null}
                  </LabelGroup>
                ) : null}
                <div className="obj-detail__address-grid">
                  <div>
                    <Button link onClick={addressClickHandler} icon="map-pin" />
                  </div>
                  <div className="obj-detail__address">
                    {object.get('project_name') ? `${object.get('project_name')}, ` : null}
                    {addressComponent(object.get('address'))}
                  </div>
                  <div className="obj-detail__area">{locationComponent(object.get('address'))}</div>
                </div>
              </h1>
              <div className="obj-detail__main-specs">
                <MainSpecs
                  objectType={objectType}
                  totalArea={totalArea()}
                  totalAreaUnit={object.get('size_unit_id')}
                  numberOfRooms={numberOfRooms()}
                  floor={object.getIn(['attributes', FLOOR])}
                  totalFloors={object.getIn(['attributes', TOTAL_FLOORS])}
                  lotSize={object.get('lot_size')}
                  lotSizeUnit={object.get('lot_size_unit_id')}
                  t={t}
                />
              </div>
              <div className="obj-detail__secondary-specs">
                <SecondarySpecs
                  nrOfApartments={object.getIn(['attributes', NO_OF_APARTMENTS])}
                  objectType={object.get('unit_type')}
                  transactionType={object.get('transaction_type')}
                  bedrooms={object.getIn(['attributes', NUMBER_OF_BEDROOMS])}
                  bathrooms={object.getIn(['attributes', NUMBER_OF_BATHROOMS])}
                  elevator={object.getIn(['attributes', LIFT]) || object.getIn(['attributes', ELEVATOR])}
                  storage={object.getIn(['attributes', STORAGE_ROOM])}
                  storageSpace={object.getIn(['attributes', STORAGE_SPACE])}
                  balcony={
                    object.hasIn(['attributes', 'BALCONY_TYPE'])
                      ? object.getIn(['attributes', 'BALCONY_TYPE']).includes(BALCONY_TYPE.BALCONY)
                      : false
                  }
                  terrace={
                    object.hasIn(['attributes', 'BALCONY_TYPE'])
                      ? object.getIn(['attributes', 'BALCONY_TYPE']).includes(BALCONY_TYPE.TERRACE)
                      : false
                  }
                  sauna={
                    object.hasIn(['attributes', 'SANITARY_ARRANGEMENTS'])
                      ? object.getIn(['attributes', 'SANITARY_ARRANGEMENTS']).includes(SANITARY_ARRANGEMENTS.SAUNA)
                      : false
                  }
                  constructionYear={object.getIn(['attributes', CONSTRUCTION_YEAR])}
                  // storage={object.getIn(['attributes', STORAGE_ROOM])}
                  energyCertificate={object.get('attributes', ENERGY_CERTIFICATE_TYPE)}
                  parking={object.getIn(['attributes', getParkingSelectionAttributeName(objectType)])}
                  immediatelyAvailable={object.getIn(['attributes', IMMEDIATELY_AVAILABLE])}
                  furniture={object.getIn(['attributes', FURNITURE])}
                  purposeOfUse={object.getIn(['attributes', getPurposeOfUseAttributeName(objectType)])}
                  eletricity={object.getIn(['attributes', ELECTRICITY])}
                  garden={object.getIn(['attributes', GARDEN])}
                  gas={object.getIn(['attributes', GAS])}
                  water={object.getIn(['attributes', WATER])}
                  road={object.getIn(['attributes', ROAD])}
                  sewerage={object.getIn(['attributes', HAS_SEWERAGE])}
                  heating={object.getIn(['attributes', HEATING_SYSTEM])}
                  ventilation={object.getIn(['attributes', VENTILATION_TYPE])}
                  meetingRoom={object.getIn(['attributes', MEETING_ROOM])}
                  sharedWarehouse={object.getIn(['attributes', SHARED_WAREHOUSE])}
                  sharedLounge={object.getIn(['attributes', SHARED_LOUNGE])}
                  smartHome={object.getIn(['attributes', SMART_HOME_FEATURES])}
                  heatedFloors={object.getIn(['attributes', HEATED_FLOORS])}
                  finishStage={object.getIn(['attributes', 'FINISH_STAGE'])}
                  nearbySurroundings={object.getIn(['attributes', 'NEARBY_SURROUNDINGS'])}
                  achievements={object.getIn(['attributes', 'ACHIEVEMENTS'])}
                  outdoorSpaces={object.getIn(['attributes', 'OUTDOOR_SPACES'])}
                  // tetAvailability={object.getIn(['attributes', 'TET_OPTICS_AVAILABILITY'])}
                  elektrumOffer={PORTAL_LV}
                  alteroOffer={PORTAL_LV && ALTERO_CAMPAIGN_RUNNING}
                  inbankOffer={PORTAL_LV && INBANK_CAMPAIGN_RUNNING}
                  teliaOffer={this.getTeliaOffer()}
                  alexelaOffer={PORTAL_EE && this.getAlexelaOffer()}
                  // getAProVariant={getAProVariant}
                  language={language}
                  t={t}
                />
              </div>

              {browser.greaterThan.small && !preview && !object.hasIn(['attributes', COMING_SOON]) && (
                <div className="obj-detail__contact-buttons">
                  <ButtonGroup fixed>
                    {broker.get('email_address') && (
                      <Button
                        primary
                        icon="envelope"
                        text={t('broker.sendEmail')}
                        onClick={() => {
                          openModal(ModalName.BusinessCard);
                          this.gaEventHandler('send_message');
                        }}
                      />
                    )}
                    {getContact(broker, 'phone') && getContact(broker, 'phone').get(0) && (
                      <Button
                        primary={!showBrokerPhone}
                        inactive={showBrokerPhone}
                        disabled={showBrokerPhone}
                        icon="phone"
                        text={!showBrokerPhone ? t('common.showNr') : getContact(broker, 'phone').get(0)}
                        onClick={() => {
                          this.sendStatistics(AGENT_SHOW_PHONE);
                          this.setState({ showBrokerPhone: true });
                          this.gaEventHandler('call_number');
                        }}
                      />
                    )}
                  </ButtonGroup>
                </div>
              )}
              {!preview && browser.lessThan.medium && (
                <ButtonGroup className="obj-detail__actions">
                  {this.renderFavouriteButtonWithTooltip()}
                  <Button
                    small
                    secondary
                    icon="share"
                    text={t('common.share')}
                    onClick={() => {
                      setShareModal(true, object.get('friendly_id'), object.get('unit_type'), true);
                      this.gaEventHandler('click_shareAd');
                    }}
                  />
                  {browser.greaterThan.small && (
                    <Button small secondary icon="print" onClick={() => showPrintView(true)} />
                  )}
                  <Button
                    small
                    transparent
                    icon="flag"
                    onClick={() => {
                      setReportAdModalOpened(true);
                      this.gaEventHandler('click_reportAd');
                    }}
                  />
                </ButtonGroup>
              )}
              {!object.hasIn(['attributes', COMING_SOON]) &&
                showMultiBankCalculator(object.get('transaction_type'), object.get('unit_type')) && (
                  <div className="obj-detail__multi-bank-calc">
                    <MultiBankCalculator
                      t={t}
                      objectPrice={object.get('price_min') || object.get('price')}
                      unitType={getMultibankCalculatorUnitType(object)}
                      compact
                      placementName="detailView_sidebar_compact"
                    />
                  </div>
                )}
              {!object.hasIn(['attributes', COMING_SOON]) && singleBankCalculatorVariant && (
                <div className="obj-detail__multi-bank-calc">
                  <SingleBankCalculator
                    selectedBank={singleBankCalculatorVariant}
                    compact
                    placementName="detailView_sidebar_compact"
                  />
                </div>
              )}

              <div className="obj-detail__banner">
                <Ad master={D_DETAIL} id={D_DETAIL_INFO} />
              </div>

              {openHouseDetails && openHouseDetails.length > 0 && (
                <OpenHouse
                  title={t('openHouse.title')}
                  className="obj-detail__open-house"
                  details={openHouseDetails[0]}
                  buttonText={t('openHouse.button')}
                  id={openHouseDetails[0].reo.friendlyId}
                  language={language}
                  t={t}
                />
              )}

              <Description
                showSlogan={showSlogan(object)}
                slogan={object.get('slogan')}
                description={object.get('description')}
                opened={isPrint}
                maxHeight={150}
                gaEvent={this.gaEventHandler}
                t={t}
              />

              {constructionPhase && constructionPhase.size > 0 && (
                <div>
                  <Separator className="obj-detail__separator" />
                  <h4>{t('objectDetail.projectTimeline')}</h4>
                  <Timeline items={constructionPhase} t={t} />
                </div>
              )}

              {projectRealties.status !== AsyncDataStatus.None && (
                <div>
                  <Separator className="obj-detail__separator" />
                  <div className="obj-detail__realties">{this.renderObjectList()}</div>
                </div>
              )}
              {object.get('date_modified') && (
                <div className="obj-detail__updated-time">
                  {t('objectDetail.adInfoUpdated')}&nbsp;
                  {formatDate(object.get('date_modified'), {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </div>
              )}

              {!isPrint && (
                <div className="obj-detail__accordions">
                  <Accordion
                    className="obj-detail__accordion"
                    sticky
                    gaCategory={GA_DETAIL_VIEW}
                    gaLabel="objectDetails"
                  >
                    <AccordionHead>{t('objectDetail.projectDetails')}</AccordionHead>
                    <AccordionBody>
                      <FullSpecs
                        object={object}
                        hideBankOffers={object.hasIn(['attributes', COMING_SOON])}
                        t={t}
                        gaEvent={this.gaEventHandler}
                        locale={locale}
                      />
                      <Suspense fallback={<Loading />}>
                        {PORTAL_LV && browser.lessThan.medium && <GetAProServices />}
                      </Suspense>
                    </AccordionBody>
                  </Accordion>
                  {object.get('latitude') && object.get('longitude') && (
                    <Accordion
                      className="obj-detail__accordion"
                      sticky
                      gaCategory={GA_DETAIL_VIEW}
                      gaLabel="publicTransportSchools"
                    >
                      <AccordionHead>{t('objectDetail.nearbyPlaces')}</AccordionHead>
                      <AccordionBody>
                        <NearbyPlaces t={t} latitude={object.get('latitude')} longitude={object.get('longitude')} />
                      </AccordionBody>
                    </Accordion>
                  )}
                  {!preview &&
                    !object.hasIn(['attributes', COMING_SOON]) &&
                    showMultiBankCalculator(object.get('transaction_type'), object.get('unit_type')) && (
                      <Accordion
                        className="obj-detail__accordion"
                        sticky
                        defaultOpened={PORTAL_EE}
                        gaCategory={GA_DETAIL_VIEW}
                        gaLabel="MultiBank_calculator"
                      >
                        <AccordionHead>
                          <div className="obj-detail__accordion-logo">{t('objectDetail.multiBankCalculator')}</div>
                        </AccordionHead>
                        <AccordionBody>
                          <MultiBankCalculator
                            t={t}
                            objectPrice={object.get('price_min') || object.get('price')}
                            unitType={getMultibankCalculatorUnitType(object)}
                            placementName="detailView_sidebar_full"
                          />
                        </AccordionBody>
                      </Accordion>
                    )}
                  {!preview && !object.hasIn(['attributes', COMING_SOON]) && singleBankCalculatorVariant && (
                    <Accordion
                      className="obj-detail__accordion"
                      sticky
                      defaultOpened={PORTAL_EE}
                      gaCategory={GA_DETAIL_VIEW}
                      gaLabel="SingleBank_calculator"
                    >
                      <AccordionHead>
                        <div className="obj-detail__accordion-logo">
                          <img src={singleBankCalculatorVariant === 'tbb' ? tbbLogo : coopLogo} alt="" />
                          {t(`loanCalc.singleBankTitle.${singleBankCalculatorVariant}`)}
                        </div>
                      </AccordionHead>
                      <AccordionBody>
                        <SingleBankCalculator
                          selectedBank={singleBankCalculatorVariant}
                          placementName="detailView_sidebar_full"
                        />
                      </AccordionBody>
                    </Accordion>
                  )}
                </div>
              )}

              {PORTAL_LV &&
                showInBankCalculator(object.get('unit_type'), object.get('property_size') ?? object.get('size_min')) &&
                !object.hasIn(['attributes', COMING_SOON]) && (
                  <div className="obj-detail__inbank-calc">
                    <InbankCalculator
                      amount={(object.get('property_size') ?? object.get('size_min')) * 100}
                      compact
                      t={t}
                    />
                  </div>
                )}

              {PORTAL_LV && showAlteroInsuranceContent(objectType) && !object.hasIn(['attributes', COMING_SOON]) && (
                <div className="obj-detail__altero-insurance-calc">
                  <AlteroInsuranceCalculator
                    t={t}
                    unitSize={object.get('property_size') ?? object.get('size_min') ?? object.get('lot_size')}
                  />
                </div>
              )}
              {/* {!isPrint && */}
              {/*   PORTAL_LV && */}
              {/*   isMobile && */}
              {/*   object.getIn(['address', 'county_name']) === LOCATION_NAME_RIGA && */}
              {/*   object.get('unit_type') === OBJECT_TYPES.Apartment && */}
              {/*   object.get('transaction_type') === TRANSACTION_TYPES.SALE && ( */}
              {/*     <Suspense fallback={<Loading />}> */}
              {/*       <CenuBanka location={object.getIn(['address', 'city_name'])} /> */}
              {/*     </Suspense> */}
              {/*   )} */}

              <div ref={this.brokerRef}>{this.renderBrokers()}</div>
              {!isPrint && adIds && (
                <>
                  <Separator className="obj-detail__separator" />
                  <Ad {...adIds} spacingY />
                  <Separator className="obj-detail__separator" />
                </>
              )}
            </>
          )}
          {browser.lessThan.large && !loading && broker.size > 0 && !preview && (
            <BrokerSticky
              brokerRef={this.brokerRef}
              phone={getContact(broker, 'phone').get(0)}
              mail={getContact(broker, 'email').get(0)}
              full={full}
              t={t}
              sendStatistics={this.sendStatistics}
            />
          )}
        </div>
        <BusinessCardModal t={t} />
      </div>
    );
  }
}

ObjectDetailInfo.propTypes = propTypes;
ObjectDetailInfo.defaultProps = defaultProps;
function mapStateToProps(state) {
  return {
    language: getLanguage(state),
    routes: getPrefixedRoutes(state),
    favouriteCount: getObjectFavouriteCount(state),
    signedIn: getSignedIn(state),
    browser: getBrowser(state),
    locale: getLocale(state),
    singleBankCalculatorVariant: getSingleBankCalculatorVariant(state),
    // getAProVariant: getGetAProVariant(state),
    projectRealties: getProjectRealties(state),
  };
}

const mapDispatchToProps = {
  ...modalActions,
  ...userActions,
  openModal,
  setObjectNavCount,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ObjectDetailInfo);
