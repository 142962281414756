import React from 'react';
import Language from '@city24/common/enums/Language';
import { TFunction } from 'next-i18next';
import { INBANK_LAND_OFFER_LINKS, INBANK_OFFER_LINKS, INBANK_RENT_OFFER_LINKS } from '@/constants/integrations';
import { GA_DETAIL_VIEW } from '@/constants/ga';
import ReactGA from '@/utils/ga';
import Icon from '@/components/icon/Icon';
import Anchor from '@/components/link/Anchor';
import css from '../SpecsItem.module.scss';
import { InBankCalculatorType } from '@/components/integrations/Inbank/InbankCalculator';

interface Props {
  language: Language;
  type: InBankCalculatorType;
  t: TFunction;
}

const InbankSpecsItem = ({ language, type, t }: Props): React.ReactElement => {
  let links;
  switch (type) {
    case 'land':
      links = INBANK_LAND_OFFER_LINKS;
      break;
    case 'rent':
      links = INBANK_RENT_OFFER_LINKS;
      break;
    default:
      links = INBANK_OFFER_LINKS;
  }

  return (
    <div className={`${css.item} ${css.inbank}`}>
      <div>
        <Icon name="inbank" />
      </div>
      <div>
        <Anchor
          href={links[language]}
          onClick={() => {
            ReactGA.event({
              category: GA_DETAIL_VIEW,
              action: 'InbankLinkClick',
              label: 'offer_1',
            });
          }}
        >
          <span className={css.content}>{t(`objectSpec.${type}.inbankOffer`)}</span>
          <Icon name="angle-right" className={css.icon} />
        </Anchor>
        <Anchor
          href={links[language]}
          onClick={() => {
            ReactGA.event({
              category: GA_DETAIL_VIEW,
              action: 'InbankLinkClick',
              label: 'offer_2',
            });
          }}
        >
          <span className={css.content}>{t(`objectSpec.${type}.inbankOffer2`)}</span>
          <Icon name="angle-right" className={css.icon} />
        </Anchor>
      </div>
    </div>
  );
};

export default InbankSpecsItem;
