import { combineReducers } from 'redux';

import modals from '@/components/modals/modalReducer';
import notifications from '@/components/notifications/notificationReducer';
import search from '@/components/search/SearchReducer';
import objectDetails from '@/components/object/objectDetailsReducer';
import mapReducer from '@/components/map/Map/mapReducer';
import adReducer from '@/components/Ad/AdReducer';
import brokerSearch from '@/components/broker/BrokerSearchReducer';
import companySearch from '@/components/company/CompanySearchReducer';
import companyDetailReducer from '@/legacyPages/companyDetail/companyDetailReducer';
import brokerDetailOld from '@/legacyPages/brokerDetail/brokerDetailReducers';
import brokerDetail from '@/components/broker/brokerDetailReducers';
import myObject from '@/legacyPages/MyObject/MyObjectReducer';
import locationView from '@/legacyPages/MyObject/MyObjectLocationReducer';
import news from '@/components/news/newsReducer';
import landingPages from '@/components/landingPages/LandingPagesReducer';
import app from './app';
import browser from './browser';
import user from './user';
import mainPage from './mainPage';
import myObjects from './myObjects';
import calculators from './calculators';
import savedSearches from '@/components/savedSearches/savedSearchesReducer';

export default combineReducers({
  app,
  modals,
  notifications,
  search,
  user,
  objectDetails,
  map: mapReducer,
  mainPage,
  broker: combineReducers({
    search: brokerSearch,
    detailsOld: brokerDetailOld,
    details: brokerDetail,
  }),
  companySearch,
  myObject,
  myObjects,
  locationView,
  news,
  calculators,
  browser,
  office: companyDetailReducer,
  ad: adReducer,
  landingPages,
  savedSearches,
});
