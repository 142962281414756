import produce, { freeze } from 'immer';
import { GET_CAMPAIGN_LANDING_PAGE_OBJECTS, SET_CAMPAIGN_LANDING_PAGE_LOADING } from '@/constants/actions';

const initialState: {
  [placement: string]: any;
  loading: boolean;
} = {
  HOT_OFFERS_HOUSE_LAND: [],
  OPEN_DAYS: [],
  HOT_OFFERS_LANDING: [],
  loading: false,
};

const landingPages = produce((state, action) => {
  switch (action.type) {
    case GET_CAMPAIGN_LANDING_PAGE_OBJECTS:
      state[action.placement] = freeze(action.blocks);
      state.loading = false;
      break;
    case SET_CAMPAIGN_LANDING_PAGE_LOADING:
      state.loading = action.loading;
      break;
    default:
      break;
  }
}, initialState);

export default landingPages;
