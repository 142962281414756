export const SAVED_SEARCH_FREQUENCY = {
  INSTANT: 'INSTANT',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  DISABLED: 'DISABLED',
};

export const EMAIL_ALERT_OFF = 0;
export const EMAIL_ALERT_ONCE_A_DAY = 1;
export const EMAIL_ALERT_ONCE_A_WEEK = 2;
export const EMAIL_ALERT_ASAP = 3;

export const SEARCH_MODE_LIST = 2;
export const SEARCH_MODE_NEW_PROJECTS = 8;
