import Immutable, { Collection, fromJS, isImmutable, List } from 'immutable';
import { AddressPublic } from '@city24/common/types/public/RealtyPublic';
import { LocationLevel } from '@city24/common/enums/location/LocationLevel';

import { localStorage } from '@city24/common/browserStorage';

import {
  HAS_OPEN_DAYS,
  HAS_VIRTUAL_TOUR,
  OBJECT_TYPE_OPTIONS_NP,
  ON_LAST_FLOOR,
  RENT_TO_OWN,
} from '@/constants/attributes';
import { OBJECT_TYPES } from '@/constants/object';
import { DATE_ADDED_OPTIONS, SEARCH_URL_LABELS, shouldSearchByTotalFloors, SIZE_ID_HECTARE } from '@/constants/filters';
import { SEO_URL_PAGE, SEO_URL_SORT } from '@/constants/seo';
import { SEARCH_MODE_NEW_PROJECTS } from '@/constants/savedSearches';

import { NameValue } from './collections';
import { stringify as qsStringify } from './queryString';
import { addressTerm, addressTypeFromLevel, AddressTypeName, isAddressTerm, isKeywordTerm } from './searchTerms';
import { typeCombinationString } from './route';
import { fromISOString } from './date';
import { combineLocationString, identity, keysAreEqual, removeSpecialCharacters } from './helpers';
import { NameValueExtra as NameValueType } from '@/types/collections';
import { groupBy } from './object';
import { getPurposeOfUseAttributeName } from './realty';

type AddressSearchQuery = {
  county?: number[];
  parish?: number[];
  city?: number[];
  district?: number[];
  village?: number[];
  street?: number[];
};

export type ObjectSearchQuery = {
  address: { cc: string } & AddressSearchQuery;
  q?: string | string[];
  tsType?: string;
  unitType?: string;
  order?: { [sortKey: string]: 'asc' | 'desc' };
};

function processRangeParam(param: { [key: string]: string }) {
  const filtered = Object.keys(param).filter((key) => param[key] !== '');
  return filtered.length > 0
    ? filtered.reduce((obj, key) => {
        obj[key] = param[key];
        return obj;
      }, {} as { [key: string]: string })
    : undefined;
}

function processDate(relativeString: string) {
  // Ignore milliseconds
  const time = Math.floor(Date.now() / 1000);
  // Start of current day
  const dayStart = time - (time % (24 * 3600));
  if (relativeString === 'today') {
    return { gte: dayStart };
  }

  if (relativeString === 'this_week') {
    return { gte: dayStart - 7 * 24 * 3600 };
  }

  if (relativeString === 'this_month') {
    return { gte: dayStart - 30 * 24 * 3600 };
  }

  if (relativeString === 'last_three_months') {
    return { gte: dayStart - 90 * 24 * 3600 };
  }

  return undefined;
}

function processSorting(sorting: string | undefined) {
  if (!sorting || sorting === 'relevance') {
    return undefined;
  }

  const [prop, dir] = sorting.split('-');

  // use camel case for prop name for api
  let propName;
  switch (prop) {
    case 'datepublished':
      propName = 'datePublished';
      break;
    case 'priceperunit':
      propName = 'pricePerUnit';
      break;
    case 'pricemin':
      propName = 'priceMin';
      break;
    case 'priceperunitmin':
      propName = 'pricePerUnitMin';
      break;
    default:
      propName = prop;
  }

  return { [propName]: dir };
}

type ValuesList = List<typeof NameValue | Collection.Keyed<string, any>>;

function getValues(list: ValuesList) {
  return list.map((entry) => entry.get('value'));
}

function getUrlIds(list: ValuesList) {
  return list.map((entry) => entry.get('urlId'));
}

function getOriginalValues(list: [string, NameValueType<{ original: number }>][]) {
  return list.map(([, entry]) => entry.extra.original);
}

// type GroupedTerms = Collection.Keyed<string, List<typeof NameValue>>;
type GroupedTerms = { [group: string]: [string, NameValueType<{ original: number }>][] };
function keywordTermsToString(groupedTerms: GroupedTerms) {
  const { q } = groupedTerms;
  if (q?.length) {
    const keywords = q.map(([, v]) => v.name);
    if (keywords.length === 1) {
      return keywords[0];
    }
    return keywords;
  }

  return undefined;
}

function addressTermsToQuery(groupedTerms: GroupedTerms) {
  const addressQuery: AddressSearchQuery = {};

  const counties = groupedTerms['address.county'];
  if (counties?.length) {
    addressQuery.county = getOriginalValues(counties);
  }

  const parishes = groupedTerms['address.parish'];
  if (parishes?.length) {
    addressQuery.parish = getOriginalValues(parishes);
  }

  const cities = groupedTerms['address.city'];
  if (cities?.length) {
    addressQuery.city = getOriginalValues(cities);
  }

  const districts = groupedTerms['address.district'];
  if (districts?.length) {
    addressQuery.district = getOriginalValues(districts);
  }

  const villages = groupedTerms['address.village'];
  if (villages?.length) {
    addressQuery.village = getOriginalValues(villages);
  }

  const streets = groupedTerms['address.street'];
  if (streets?.length) {
    addressQuery.street = getOriginalValues(streets);
  }
  return addressQuery;
}

function filtersToQuery(params: { [key: string]: any }, allowedFilters: string[]) {
  const query: { [key: string]: any } = { attributes: {} };
  const objectType = params.objectType.get('value');
  const subTypes = params.subObjectTypes as ValuesList;
  const constructionPhase = params.constructionPhase ? params.constructionPhase.get('value') : null;

  if (objectType === OBJECT_TYPES.NewProject && subTypes && subTypes.size > 0) {
    query.unitType = subTypes.map((t) => t.get('value')).toArray();
    query.unitType.unshift(objectType);
  }
  if (objectType === OBJECT_TYPES.NewProject && constructionPhase && constructionPhase !== 'all') {
    query.cp = constructionPhase;
  }

  if (allowedFilters.includes('price')) {
    const priceRange = processRangeParam({
      gte: params.minPrice,
      lte: params.maxPrice,
    });

    if (priceRange) {
      if (params.priceType && params.priceType.get('value') === 'm2') {
        query.pricePerUnit = priceRange;
      } else {
        query.price = priceRange;
      }
    }

    if (params.pricedrop) {
      query.pricedrop = true;
    }
  }

  if (allowedFilters.includes('size')) {
    const sizeRange = processRangeParam({
      gte: params.minSize,
      lte: params.maxSize,
    });

    if (sizeRange) {
      query.size = sizeRange;
    }
  }

  if (allowedFilters.includes('lotSize')) {
    const lotSizeType = params.lotSizeType.get('id');
    const sizeRange = processRangeParam({
      gte: lotSizeType === SIZE_ID_HECTARE && params.minLotSize ? params.minLotSize * 10000 : params.minLotSize,
      lte: lotSizeType === SIZE_ID_HECTARE && params.maxLotSize ? params.maxLotSize * 10000 : params.maxLotSize,
    });

    if (sizeRange) {
      query.lotSize = sizeRange;
    }
  }

  if (allowedFilters.includes('yearBuilt')) {
    const yearBuiltRange = processRangeParam({
      gte: params.minYearBuilt,
      lte: params.maxYearBuilt,
    });

    if (yearBuiltRange) {
      query.yearBuilt = yearBuiltRange;
    }
  }

  const { rooms, condition, material, energyCertificate } = params;
  if (rooms.size > 0 && allowedFilters.includes('rooms')) {
    query.roomCount = getValues(rooms).join(',');
  }

  if (condition.size > 0 && allowedFilters.includes('condition')) {
    query.attributes.CONDITION = getValues(condition).toArray();
  }

  if (material.size > 0 && allowedFilters.includes('material')) {
    query.attributes.BUILDING_MATERIAL = getValues(material).toArray();
  }

  if (energyCertificate && energyCertificate.size > 0 && allowedFilters.includes('energyCertificate')) {
    query.attributes.ENERGY_CERTIFICATE_TYPE = getValues(energyCertificate).toArray();
  }

  const purpose = params.purpose.get(objectType);

  if (purpose && purpose.size > 0 && allowedFilters.includes('purpose')) {
    const values = getValues(purpose).toArray();
    query.attributes[getPurposeOfUseAttributeName(objectType)] = values;
  }

  const extras = params.extras.get(objectType);

  if (extras && extras.size > 0) {
    /* eslint-disable no-param-reassign */
    query.attributes = extras.reduce((obj, item) => {
      const value = item.get('value');
      if (item.has('group')) {
        const group = item.get('group');
        if (!obj[group]) {
          obj[group] = [value];
        } else {
          obj[group].push(value);
        }
        return obj;
      }

      obj[value] = true;
      return obj;
    }, query.attributes);
    /* eslint-enable no-param-reassign */
  }

  if (params.virtualTour) {
    query.attributes[HAS_VIRTUAL_TOUR] = true;
  }

  if (params.rentToOwn) {
    query.attributes[RENT_TO_OWN] = true;
  }

  if (params.openHouse) {
    query.attributes[HAS_OPEN_DAYS] = true;
  }

  if (params.specialOffers) {
    query.service = 'SPECIAL_OFFER';
  }

  if (params.auction) {
    // TO DO
  }

  if (PORTAL_LV && params.projectType && params.projectType.size > 0) {
    query.attributes.HOUSE_TYPE = getValues(params.projectType).toArray();
  }

  if (allowedFilters.includes('floor')) {
    const floorRange = processRangeParam({
      gte: params.minFloor,
      lte: params.maxFloor,
    });

    if (shouldSearchByTotalFloors(objectType)) {
      if (floorRange) {
        query.totalFloors = floorRange;
      }
    } else {
      if (floorRange) {
        query.floor = floorRange;
      }

      if (params.onlyLastFloor) {
        query.attributes[ON_LAST_FLOOR] = params.onlyLastFloor;
      }
    }
  }

  if (params.dateAdded) {
    const date = processDate(params.dateAdded.get('value'));
    if (date) {
      query.datePublished = date;
    }
  }

  if (params.lastRun) {
    query.lastRun = params.lastRun;
  }

  if (params.searchId) {
    query.searchId = params.searchId;
  }

  if (params.fromOwner) {
    query.privateUser = true;
  }

  return query;
}

export function composeSearchQuery(params: { [key: string]: any }, allowedFilters: string[]): ObjectSearchQuery {
  const groupedTerms = groupBy(Array.from(params.searchTerm.entries()), ([key]) => key.split('|')[0]);

  const query: ObjectSearchQuery = {
    q: keywordTermsToString(groupedTerms),
    address: { cc: PORTAL, ...addressTermsToQuery(groupedTerms) },
    tsType: params.transactionType.get('value'),
    unitType: params.objectType.get('value'),
    ...filtersToQuery(params, allowedFilters),
  };

  const sorting = processSorting(params.sorting.get('value'));

  if (sorting) {
    query.order = sorting;
  }

  return query;
}

export function composeAutocompleteQuery(params: { [key: string]: any }, allowedFilters: string[]) {
  return {
    address: { cc: PORTAL },
    tsType: params.transactionType.get('value'),
    unitType: params.objectType.get('value'),
    ...filtersToQuery(params, allowedFilters),
  };
}

type RegionalUnit = { name: string; id: number; location_level: number; parent?: RegionalUnit };
type NestedLocationSearchTerm = { name: string; value: LocationLevel };
type LocationSearchTerm = NameValueType<{
  original: number;
  type: string;
  secondaryName?: string;
  county?: NestedLocationSearchTerm;
  parish?: NestedLocationSearchTerm;
  city?: NestedLocationSearchTerm;
  district?: NestedLocationSearchTerm;
  village?: NestedLocationSearchTerm;
  street?: NestedLocationSearchTerm;
}>;

export function mapAreaSelectExtra(
  county?: RegionalUnit,
  parish?: RegionalUnit,
  city?: RegionalUnit
): Partial<LocationSearchTerm['extra']> {
  const extras: Partial<LocationSearchTerm['extra']> = {};
  if (county) {
    extras.county = { name: county.name, value: county.id };
  }
  if (parish) {
    extras.parish = { name: parish.name, value: parish.id };
  }
  if (city) {
    extras.city = { name: city.name, value: city.id };
  }
  return extras;
}

function getLocationTermExtra(value: RegionalUnit): NestedLocationSearchTerm {
  return { name: value.name, value: value.id };
}

export function createLocationSearchTermFromAddress(
  type: 'county' | 'parish' | 'city' | 'village' | 'district',
  address: AddressPublic | Collection<keyof AddressPublic, any>
) {
  let term: LocationSearchTerm;
  /** @deprecated branch */
  if (isImmutable(address)) {
    term = {
      name: address.getIn([type, 'name']),
      value: addressTerm(type, address.getIn([type, 'id'])),
      extra: {
        original: address.getIn([type, 'id']),
        type,
      },
    };

    if (address.get('county')) {
      term.extra.county = getLocationTermExtra({
        name: address.getIn(['county', 'name']),
        id: address.getIn(['county', 'id']),
        location_level: LocationLevel.County,
      });
    }
    if (address.get('parish')) {
      term.extra.parish = getLocationTermExtra({
        name: address.getIn(['parish', 'name']),
        id: address.getIn(['parish', 'id']),
        location_level: LocationLevel.Parish,
      });
    }
    if (address.get('city')) {
      term.extra.city = getLocationTermExtra({
        name: address.getIn(['city', 'name']),
        id: address.getIn(['city', 'id']),
        location_level: LocationLevel.City,
      });
    }
  } else {
    const { city, county, parish, village } = address;
    const primary = address[type]!;
    term = {
      name: primary.name,
      value: addressTerm(type, `${primary.id}`),
      extra: {
        original: primary.id,
        type,
      },
    };

    if (county) {
      term.extra.county = getLocationTermExtra({
        name: county.name,
        id: county.id,
        location_level: PORTAL_LV ? LocationLevel.LvCounty : LocationLevel.County,
      });
    }
    if (parish) {
      term.extra.parish = getLocationTermExtra({
        name: parish.name,
        id: parish.id,
        location_level: PORTAL_LV ? LocationLevel.LvParish : LocationLevel.Parish,
      });
    }
    if (city) {
      term.extra.city = getLocationTermExtra({
        name: city.name,
        id: city.id,
        location_level: PORTAL_LV ? LocationLevel.LvCity : LocationLevel.City,
      });
    }
    if (village) {
      term.extra.village = getLocationTermExtra({
        name: village.name,
        id: village.id,
        location_level: LocationLevel.LvVillage,
      });
    }
  }

  return new Map([[term.value, term]]);
}

export function getAreaSelectSecondaryName(
  matches: string,
  value: {
    county?: RegionalUnit | NestedLocationSearchTerm;
    parish?: RegionalUnit | NestedLocationSearchTerm;
    city?: RegionalUnit | NestedLocationSearchTerm;
    district?: RegionalUnit | NestedLocationSearchTerm;
  }
): string {
  if (!matches || matches === 'county') {
    return '';
  }
  const county = value.county?.name ?? '';
  if (matches === 'parish') {
    return county;
  }
  const parish = value.parish?.name ?? '';
  if (matches === 'city') {
    return parish || county;
  }
  const city = value.city?.name ?? '';
  const district = value.district?.name ?? '';
  if (district && matches === 'street') {
    return [district, city, parish, county].filter(identity).slice(0, 2).join(', ');
  }

  return city || parish || county;
}

function setLocationExtraHierarchy(term: LocationSearchTerm, item: RegionalUnit) {
  const addressType = addressTypeFromLevel(item.location_level);
  term.extra[addressType] = getLocationTermExtra(item);
  if (item.parent) {
    setLocationExtraHierarchy(term, item.parent);
  }
}
export function createLocationSearchTerm(type: AddressTypeName, value: RegionalUnit): LocationSearchTerm {
  const term: LocationSearchTerm = {
    name: value.name,
    value: addressTerm(type, String(value.id)),
    extra: { original: value.id, type, [type]: getLocationTermExtra(value), secondaryName: '' },
  };

  // add self and parent to extra
  if (value.parent) {
    setLocationExtraHierarchy(term, value.parent);
  }
  term.extra.secondaryName = getAreaSelectSecondaryName(type, term.extra);
  return term;
}

function mapValuesAsString(params: ValuesList) {
  return getValues(params).join(',');
}

function urlFriendly(value: string) {
  return value.replace(/\s+/g, '-').toLowerCase();
}

export function extractSearchTerm(searchTerm: NameValueType<any>[]): {
  keywords: string[];
  locations: string[];
  locationIds: string[];
} {
  const keywords: string[] = [];
  const locations: string[] = [];
  const locationIds: string[] = [];

  searchTerm.forEach((i) => {
    const { value, name } = i;
    if (isKeywordTerm(value)) {
      keywords.push(name);
    } else if (isAddressTerm(value)) {
      const [classifier, id] = value.split('|');
      const type = classifier.split('.')[1];

      locations.push(urlFriendly(name));

      locationIds.push(`${id.replace('d-', '')}-${type}`);
    }
  });

  return {
    keywords,
    locations,
    locationIds,
  };
}

function encodeSpaces(string: string) {
  return string.replace(/\s/g, '-');
}

export function encodeUrlAttribute(id: number | string, value: string | Collection.Indexed<any>) {
  const jsValue = isImmutable(value) ? value.toJS() : value;
  const val = Array.isArray(jsValue) ? jsValue.join(',') : jsValue;
  return `${id}=${encodeSpaces(val)}`;
}

function encodeNuberRangeAttribute(id: number | string, min: number, max: number, option?: string) {
  let str = '';
  if (option) str += `${option}-`;
  return encodeUrlAttribute(id, `${str}${min}-${max}`);
}

export function composeSearchURL(labels, filters) {
  const na = 'na';
  const {
    objectType,
    subObjectTypes,
    transactionType,
    constructionPhase,
    searchTerm,
    minPrice,
    maxPrice,
    priceType,
    minSize,
    maxSize,
    minLotSize,
    maxLotSize,
    lotSizeType,
    minFloor,
    maxFloor,
    onlyLastFloor,
    rooms,
    fromOwner,
    condition,
    material,
    extras,
    projectType,
    purpose,
    virtualTour,
    openHouse,
    specialOffers,
    rentToOwn,
    auction,
    pricedrop,
    dateAdded,
    lastRun,
    searchId,
    minYearBuilt,
    maxYearBuilt,
    energyCertificate,
    sorting,
  } = filters;

  const URL = [];
  const query = {};

  // Transaction and object types
  URL.push(typeCombinationString(transactionType, objectType, 'url', 'en'));
  if (subObjectTypes && objectType === OBJECT_TYPES.NewProject && subObjectTypes.size > 0) {
    URL.push(
      encodeUrlAttribute(
        labels.objectTypes,
        subObjectTypes.map((o) => typeCombinationString('all', o.get('value'), 'url', 'en')).join(',')
      )
    );
  }
  if (constructionPhase && objectType === OBJECT_TYPES.NewProject) {
    // todo: if default selected, skip
    if (constructionPhase !== 'all') {
      URL.push(encodeUrlAttribute(labels.constructionPhase, constructionPhase));
    }
  }

  const { keywords, locationIds } = searchTerm ? extractSearchTerm(searchTerm) : filters;
  // Location
  if (searchTerm) {
    const searchLocation = combineLocationString(searchTerm, '-')
      .map((term) => removeSpecialCharacters(encodeSpaces(term)).toLowerCase())
      .join(',');
    if (searchLocation) {
      URL.push(searchLocation);
    }
  }
  // Search tem
  if (keywords && keywords.length) {
    URL.push(encodeUrlAttribute(labels.keyword, keywords));
  }
  // Price
  if (minPrice || maxPrice) {
    const minPriceVal = minPrice || na;
    const maxPriceVal = maxPrice || na;
    URL.push(encodeNuberRangeAttribute(labels.price, minPriceVal, maxPriceVal, priceType || 'eur'));
  }
  // Rooms
  if (rooms && rooms.size) {
    URL.push(encodeUrlAttribute(labels.rooms, getValues(rooms)));
  }
  // Size
  if (minSize || maxSize) {
    const minSizeVal = minSize || na;
    const maxSizeVal = maxSize || na;
    URL.push(encodeNuberRangeAttribute(labels.size, minSizeVal, maxSizeVal));
  }
  // Lot size
  if (minLotSize || maxLotSize) {
    const minLotSizeVal = minLotSize || na;
    const maxLotSizeVal = maxLotSize || na;
    URL.push(encodeNuberRangeAttribute(labels.lotSize, minLotSizeVal, maxLotSizeVal, lotSizeType));
  }
  // Floor
  if (minFloor || maxFloor || onlyLastFloor) {
    const minFloorVal = minFloor || na;
    const maxFloorVal = maxFloor || na;
    const lastFloor = onlyLastFloor ? labels.onlyLastFloor : '';

    URL.push(encodeNuberRangeAttribute(labels.floor, minFloorVal, maxFloorVal, lastFloor));
  }
  // YearBuilt
  if (minYearBuilt || maxYearBuilt) {
    const minYearBuiltVal = minYearBuilt || na;
    const maxYearBuiltVal = maxYearBuilt || na;
    URL.push(encodeNuberRangeAttribute(labels.built, minYearBuiltVal, maxYearBuiltVal));
  }
  // Condition
  if (condition && condition.size) {
    URL.push(encodeUrlAttribute(labels.condition, getUrlIds(condition)));
  }
  // Material
  if (material && material.size) {
    URL.push(encodeUrlAttribute(labels.material, getUrlIds(material)));
  }
  // energyCertificate
  if (energyCertificate && energyCertificate.size) {
    URL.push(encodeUrlAttribute(labels.energyCertificate, getUrlIds(energyCertificate)));
  }
  // Purpose
  if (purpose && purpose.get(objectType) && purpose.get(objectType).size) {
    URL.push(encodeUrlAttribute(labels.purpose, getUrlIds(purpose.get(objectType))));
  }
  // Extras
  if (extras && extras.get(objectType) && extras.get(objectType).size) {
    URL.push(encodeUrlAttribute(labels.extras, getUrlIds(extras.get(objectType))));
  }
  // Private user
  if (fromOwner) {
    URL.push(labels.fromOwner);
  }
  // Virtual tour
  if (virtualTour) {
    URL.push(labels.virtualTour);
  }

  if (auction) {
    URL.push(`${labels.auction}`);
  }
  // Open house
  if (openHouse) {
    URL.push(labels.openHouse);
  }

  if (specialOffers) {
    URL.push(labels.specialOffers);
  }

  if (rentToOwn) {
    URL.push(labels.rentToOwn);
  }

  // Pricedrop
  if (pricedrop) {
    URL.push(labels.pricedrop);
  }

  // Project type
  if (projectType && projectType.size) {
    URL.push(encodeUrlAttribute(labels.projectType, mapValuesAsString(projectType)));
  }

  // Date
  if (dateAdded) {
    URL.push(encodeUrlAttribute(labels.dateAdded, dateAdded));
  }

  // Location IDs
  if (locationIds && locationIds.length) {
    URL.push(encodeUrlAttribute(labels.locationId, locationIds));
  }

  if (sorting && !sorting.get('default')) {
    URL.push(encodeUrlAttribute(labels.sort, sorting.get('value')));
  }

  if (lastRun) {
    query.lastRun = lastRun;
  }

  if (searchId) {
    query.searchId = searchId;
  }

  const qs = qsStringify(query);
  return `/${URL.join('/')}${qs.length > 0 ? `?${qs}` : ''}`;
}

export function composeSearchURLFromParams(
  routes: string | { objectResults: string },
  objectType: string,
  transactionType: string,
  otherParams: Parameters<typeof composeSearchURL>[1] = {}
) {
  const params = {
    objectType,
    transactionType,
    ...otherParams,
  };

  const route = typeof routes === 'string' ? routes : routes.objectResults;

  return `${route}${composeSearchURL(SEARCH_URL_LABELS, params)}`;
}

export function composeSearchURLFromFilters(labels, filters) {
  const { objectType, subObjectTypes, transactionType, constructionPhase, priceType, lotSizeType, dateAdded } = filters;

  return composeSearchURL(labels, {
    ...filters,
    objectType: objectType && objectType.get('value'),
    subObjectTypes:
      subObjectTypes && subObjectTypes.size > 0 && subObjectTypes.size < OBJECT_TYPE_OPTIONS_NP.size
        ? subObjectTypes
        : undefined,
    transactionType: transactionType && transactionType.get('value'),
    constructionPhase: constructionPhase && constructionPhase.get('value'),
    priceType: priceType && priceType.get('value'),
    lotSizeType: lotSizeType && lotSizeType.get('value'),
    dateAdded:
      dateAdded && DATE_ADDED_OPTIONS.find((o) => o.get('default')).get('value') !== dateAdded.get('value')
        ? dateAdded.get('value')
        : undefined,
  });
}

export function getUnitTypeFromSavedSearch(searchMode, unitTypes) {
  if (searchMode === SEARCH_MODE_NEW_PROJECTS) {
    return OBJECT_TYPES.NewProject;
  }
  if (unitTypes && unitTypes.length > 0) {
    const objectType = unitTypes[0].type_name;
    if (objectType === OBJECT_TYPES.Plot) {
      return OBJECT_TYPES.Land;
    }
    return objectType;
  }
  return null;
}

export function composeSearchURLFromSavedSearch(labels, { search_mode, criteria, last_run }) {
  const {
    unit_types,
    transaction_type,
    min_price,
    max_price,
    min_price_per_unit,
    max_price_per_unit,
    min_build_year,
    max_build_year,
    min_size,
    max_size,
    min_lot_size,
    max_lot_size,
    measurement_unit,
    min_floor,
    max_floor,
    on_top_floor,
    room_counts,
    only_private_ads,
    conditions,
    materials,
    categories,
    virtual_tours,
    open_house,
    free_text_search,
    locations,
  } = criteria;

  const filters: { [key: string]: any } = {
    transactionType: transaction_type && transaction_type.transaction_type_code,
    fromOwner: only_private_ads,
    virtualTour: virtual_tours,
    openHouse: open_house,
    keywords: free_text_search,
    locations: [],
    locationIds: [],
  };

  filters.objectType = getUnitTypeFromSavedSearch(search_mode, unit_types);

  filters.typeCombination = typeCombinationString(filters.transactionType, filters.objectType, 'url');

  if (locations.length) {
    const typeMapping = {
      REGION: 'county',
      TOWN: 'parish',
      QUARTER: 'city',
      NEIGHBORHOOD: 'district',
      STREET: 'street',
    };

    locations.forEach((loc) => {
      filters.locations.push(urlFriendly(loc.display_value));
      const type = typeMapping[loc.type];
      if (type) {
        filters.locationIds.push(`${loc.long_value}-${type}`);
      }
    });
  }

  if (min_price || max_price) {
    filters.minPrice = min_price;
    filters.maxPrice = max_price;
    filters.priceType = 'eur';
  } else if (min_price_per_unit || max_price_per_unit) {
    filters.minPrice = min_price_per_unit;
    filters.maxPrice = max_price_per_unit;
    filters.priceType = 'eurpersqrm';
  }

  if (min_build_year || max_build_year) {
    filters.minYearBuilt = min_build_year;
    filters.maxYearBuilt = max_build_year;
  }

  if (min_size || max_size) {
    filters.minSize = min_size;
    filters.maxSize = max_size;
  }
  if (min_lot_size || max_lot_size) {
    filters.minLotSize = min_lot_size;
    filters.maxLotSize = max_lot_size;
  }

  if (min_floor || max_floor || on_top_floor) {
    filters.minFloor = min_floor;
    filters.maxFloor = max_floor;
    filters.onlyLastFloor = on_top_floor;
  }

  if (room_counts.length > 0) {
    filters.rooms = List(room_counts.map((value) => Immutable.Map({ value })));
  }

  if (conditions.length) {
    filters.condition = List(conditions.map((c) => Immutable.Map({ value: c.string_value })));
  }

  if (materials.length) {
    filters.material = List(materials.map((c) => Immutable.Map({ value: c.string_value })));
  }

  if (categories.length) {
    filters.purpose = Immutable.Map({
      [filters.objectType]: List(categories.map((c) => Immutable.Map({ value: c.string_value }))),
    });
  }

  filters.lotSizeType = measurement_unit === 'ha' ? 'ha' : 'm2';

  if (last_run) {
    filters.lastRun = Math.floor(new Date(last_run).getTime() / 1000);
  }

  return composeSearchURL(labels, filters);
}

const pageParamRegex = new RegExp(`/${SEO_URL_PAGE['en']}=([\\d,\\w]+)`);

export function getSearchUrlWithPagination(url, page) {
  const pageValue = `/${SEO_URL_PAGE['en']}=${page}`;

  if (pageParamRegex.test(url)) {
    return url.replace(pageParamRegex, pageValue);
  }

  return `${url}${pageValue}`;
}

export function getPaginationFromUrl(url) {
  const pageValue = url.match(pageParamRegex);
  if (pageValue) {
    return parseInt(pageValue[1].replace(/[^0-9]/g, ''), 10);
  }
  return pageValue;
}

export function getLatestSearchFromStorage() {
  const latestSearches = localStorage.getItem('latestSearches') || '{}';
  let searches = fromJS(JSON.parse(latestSearches));

  // convert immutable types
  searches = searches.map((search) => {
    const filters = search.get('filters');
    if (!filters.get('searchTerm')) {
      return search;
    }
    const searchTerm = new Map();
    filters.get('searchTerm').forEach((term, key) => {
      searchTerm.set(key, term.toJS());
    });
    return search.setIn(['filters', 'searchTerm'], searchTerm);
  });
  return searches;
}

export function parseQuickSearchDate(dateString: string) {
  // TODO: Remove the locale string check (deprecated since 2020-03-03)
  if (dateString.includes('/')) {
    const [day, month, year] = dateString.split('/');
    return new Date(year, month, day);
  }
  return fromISOString(dateString, true);
}

export function areSearchFiltersEquivalent(filters1, filters2) {
  if (filters1 === filters2) {
    return true;
  }

  const keys1 = Object.keys(filters1);
  const keys2 = Object.keys(filters2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let index = 0; index < keys1.length; index += 1) {
    const key = keys1[index];
    const element1 = filters1[key];
    const element2 = filters2[key];
    if (element1 !== element2) {
      // Compare keys only for search terms
      if (key === 'searchTerm') {
        if (!keysAreEqual(element1, element2)) {
          return false;
        }
      } else if (!Immutable.is(element1, element2)) {
        return false;
      }
    }
  }

  return true;
}
