import { apiFetch } from '@/api/City24Api/basics';
import { authorizedFetch } from '@/api/City24Api/user';
import { ApiBody, ApiQuery, ApiRequestInit } from '@city24/common/api';
import { ApiJsonResponse } from '@city24/common/api/city24';
import { SavedSearchPublic } from '@/types/api/savedSearch';
import config from '@/api/City24Api/config';

export function getSavedSearch(
  id: string,
  query: ApiQuery,
  init?: ApiRequestInit,
  anonymous = false
): Promise<ApiJsonResponse<SavedSearchPublic>> {
  return anonymous
    ? apiFetch(`/saved_searches/${id}`, query, init)
    : authorizedFetch(`/saved_searches/${id}`, query, init);
}

export function getSavedSearches(
  query: ApiQuery,
  init?: ApiRequestInit,
  anonymous = false
): Promise<ApiJsonResponse<SavedSearchPublic[]>> {
  return anonymous ? apiFetch('/saved_searches', query, init) : authorizedFetch('/saved_searches', query, init);
}

export function updateSavedSearch(
  id: string,
  body: ApiBody,
  query: ApiQuery = {},
  init = {}
): Promise<ApiJsonResponse<SavedSearchPublic>> {
  return authorizedFetch(`/saved_searches/${id}`, query, {
    ...init,
    method: 'PUT',
    body,
  });
}

export function saveSavedSearch(body: ApiBody, query: ApiQuery = {}, init = {}) {
  return authorizedFetch(`/${config.locale}/saved_searches/search_query`, query, {
    ...init,
    method: 'POST',
    body,
  });
}

export function deleteSavedSearch(id: string, init = {}): Promise<ApiJsonResponse> {
  return authorizedFetch(
    `/saved_searches/${id}`,
    {},
    {
      ...init,
      method: 'DELETE',
    }
  );
}

export function unsubscribeSavedSearch(id: string, query: ApiQuery, init = {}) {
  return apiFetch(`/saved_searches/${id}/unsubscribe`, query, {
    ...init,
    method: 'PUT',
    body: {},
  });
}
