import auth from '@city24/common/api/city24/auth';
import { AppThunk } from '@/store';
import {
  deleteSavedSearch,
  getSavedSearch,
  getSavedSearches,
  saveSavedSearch,
  unsubscribeSavedSearch,
  updateSavedSearch,
} from '@/api/City24Api/savedSearch';
import { actions } from '@/components/savedSearches/savedSearchesReducer';
import { SavedSearch } from '@/types/api/savedSearch';
import { hasSearchesChanged } from '@/components/savedSearches/savedSearchesSelectors';
import { getFiltersByObjectType, getSearchFilters } from '@/components/search/searchSelectors';
import { composeSearchQuery } from '@/utils/search';
import { OBJECT_TYPES } from '@/constants/object';
import { SEARCH_MODE_LIST, SEARCH_MODE_NEW_PROJECTS } from '@/constants/savedSearches';
import { ApiQuery } from '@city24/common/api';
import { cacheReload } from '@/api/City24Api';

export function fetchSavedSearch(id: string, query: ApiQuery): AppThunk {
  return async (dispatch) => {
    const res = await getSavedSearch(id, query);
    const savedSearch = res.ok ? await res.json() : null;
    if (savedSearch) {
      return dispatch(actions.getSavedSearch(savedSearch));
    }
    return dispatch(actions.setSavedSearchLoaded());
  };
}

export function fetchSavedSearches(): AppThunk {
  return async (dispatch, getState) => {
    const changed = hasSearchesChanged(getState());
    const res = await getSavedSearches(
      { identifier: auth.getToken('anonToken'), order: { dateCreated: 'desc' } },
      changed ? cacheReload() : undefined
    );
    const savedSearches = res.ok ? await res.json() : [];
    return dispatch(actions.getSavedSearches(savedSearches));
  };
}

export function storeSavedSearchChanges(item: SavedSearch): AppThunk {
  return async (dispatch) => {
    const res = await updateSavedSearch(item.id, { ...item, portal: PORTAL });
    const savedSearch = res.ok ? await res.json() : null;
    if (savedSearch) {
      return dispatch(actions.updateSavedSearch(savedSearch));
    }
  };
}

export function storeSavedSearch(item: Omit<SavedSearch, 'id'>): AppThunk {
  return async (dispatch, getState) => {
    const state = getState();

    const filters = getSearchFilters(state);
    const criteria = composeSearchQuery(filters, getFiltersByObjectType(state));

    const objectType = filters.objectType && filters.objectType.get('value');
    if (objectType === OBJECT_TYPES.NewProject) {
      criteria.unitTypes = criteria.unitType;
    } else {
      criteria.unitTypes = [criteria.unitType];
    }
    criteria.searchMode = objectType === OBJECT_TYPES.NewProject ? SEARCH_MODE_NEW_PROJECTS : SEARCH_MODE_LIST;
    delete criteria.unitType;

    const res = await saveSavedSearch({
      frequency: item.frequency,
      name: item.name,
      email_address: item.email_address,
      portal: PORTAL,
      criteria,
      price_alert: item.price_alert,
    });

    const savedSearch = res.ok ? await res.json() : null;
    if (savedSearch) {
      return dispatch(actions.addSavedSearch(savedSearch));
    }
  };
}

export function removeSavedSearch(id: string): AppThunk {
  return async (dispatch) => {
    const res = await deleteSavedSearch(id);
    if (res.ok) {
      return dispatch(actions.deleteSavedSearch(id));
    }
  };
}

export async function unsubscribeFromSavedSearch(id: string, query: ApiQuery) {
  const res = await unsubscribeSavedSearch(id, query);
  return res.ok;
}
