import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isRealtyPublic, ProjectLinkedRealty } from '@city24/common/types/public/RealtyPublic';
import { PartnerNewsArticle } from '@city24/common/types/integrations';
import {
  GET_OBJECT_DETAILS,
  GET_PROJECT_DETAILS,
  GET_MODULAR_HOUSE_DETAILS,
  GET_PROJECT_REALTIES,
  SET_OBJECT_LOADING,
  SET_OBJECT_MEDIA_FULL,
  SET_SLIDE_COUNT,
  SET_OPEN_HOUSE_DETAILS,
  GET_OBJECT_FAVOURITE_COUNT,
  GET_SEB_NEWS_ARTICLES,
  GET_INTERNAL_FEED_ARTICLES,
  OBJECT_STATUS_ERROR,
  SET_STREET_VIEW_AVAILABILITY,
  SET_OBJECT_NAV_COUNT,
  GET_CENU_BANKA_DATA,
} from '@/constants/actions';
import { areValidStreetviewParams } from '@/utils/objectStates';
import hydrateAction from '@/actions/hydrateAction';
import { OpenDaysDetails } from '@/api/openDaysApi';
import { cenuBanka } from '@/types/cenuBanka';
import { AsyncData, AsyncDataStatus, createAsyncData } from '@/types/collections';
import { ObjectDetailsState, ObjectDetailTab } from './types';

const initialState: {
  details: ObjectDetailsState;
  projectRealties: {
    id: string;
    items: AsyncData<ProjectLinkedRealty[]>;
  };
  streetViewAvailable: boolean;
  openDaysDetails: OpenDaysDetails[];
  loading: boolean;
  status: 'ok' | 'error' | 'gone' | '';
  favouriteCount: number;
  SEBCalculatorArticles: PartnerNewsArticle[];
  internalFeedArticles: PartnerNewsArticle[];
  objectNavCount: number[];
  cenuBankaData: cenuBanka;
  slideCount: number;
  view: {
    gridView: boolean;
    fullscreen: boolean;
    selectedTab: ObjectDetailTab;
  };
} = {
  // Populated on hydrate
  details: {} as ObjectDetailsState,
  projectRealties: { id: '', items: createAsyncData([], AsyncDataStatus.None) },
  streetViewAvailable: false,
  openDaysDetails: [],
  loading: false,
  status: '',
  favouriteCount: 0,
  SEBCalculatorArticles: [],
  internalFeedArticles: [],
  objectNavCount: [],
  cenuBankaData: {},
  slideCount: 0,
  view: {
    gridView: false,
    fullscreen: false,
    selectedTab: 'gallery',
  },
};
/** @deprecated */
function setObjectDetailsSuccess<T extends typeof initialState>(state: T, details: ObjectDetailsState) {
  state.status = 'ok';
  state.loading = false;
  const { streetview_params } = details;
  state.streetViewAvailable = areValidStreetviewParams(streetview_params);
  state.details = details;
  if (
    (isRealtyPublic(details) && state.projectRealties.id !== details.project?.id) ||
    state.projectRealties.id !== details.id
  ) {
    state.projectRealties = { id: '', items: createAsyncData([], AsyncDataStatus.None) };
  }
  state.view.selectedTab = 'gallery';
}
const objectDetailsSlice = createSlice({
  name: 'objectDetails',
  initialState,
  reducers: {
    getObjectDetails(state, action: PayloadAction<ObjectDetailsState>) {
      state.status = 'ok';
      state.loading = false;
      const { streetview_params } = action.payload;
      state.streetViewAvailable = areValidStreetviewParams(streetview_params);
      state.details = action.payload;
      if (
        (isRealtyPublic(state.details) && state.projectRealties.id !== state.details.project?.id) ||
        state.projectRealties.id !== state.details.id
      ) {
        state.projectRealties = { id: '', items: createAsyncData([], AsyncDataStatus.None) };
      }
      state.view.selectedTab = 'gallery';
    },
    updateStreetViewAvailability(state, action: PayloadAction<boolean>) {
      state.streetViewAvailable = action.payload;
    },
    objectStatusError(state, action: PayloadAction<typeof initialState.status>) {
      state.status = action.payload;
      state.loading = false;
    },
    getProjectRealties(state, action: PayloadAction<typeof initialState.projectRealties>) {
      state.projectRealties = action.payload;
    },
    updateLoadingStatus(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      state.status = '';
      state.favouriteCount = 0;
    },
    updateView(state, action: PayloadAction<Partial<typeof initialState.view>>) {
      state.view = { ...state.view, ...action.payload };
    },
    updateSlideCount(state, action: PayloadAction<number>) {
      state.slideCount = action.payload;
    },
    updateOpenDaysDetails(state, action: PayloadAction<OpenDaysDetails[]>) {
      state.openDaysDetails = action.payload.filter((od) => {
        const [year, month, day] = od.openDate.split('-').map(Number);
        const [hours, minutes] = od.endTime.split(':').map(Number);
        return new Date() < new Date(year, month - 1, day, hours, minutes);
      });
    },
    updateFavouriteCount(state, action: PayloadAction<number>) {
      state.favouriteCount = action.payload;
    },
    getSEBNewsArticles(state, action: PayloadAction<typeof initialState.SEBCalculatorArticles>) {
      state.SEBCalculatorArticles = action.payload;
    },
    getInternalFeedArticles(state, action: PayloadAction<typeof initialState.internalFeedArticles>) {
      state.internalFeedArticles = action.payload;
    },
    getCenuBankaData(state, action: PayloadAction<cenuBanka>) {
      state.cenuBankaData = action.payload;
    },
    updateObjectNavCount(state, action: PayloadAction<{ listAction: 'push' | 'pop' | 'set'; count: number }>) {
      const { count, listAction } = action.payload;
      const { objectNavCount } = state;
      switch (listAction) {
        case 'push':
          objectNavCount.push(count);
          break;
        case 'pop':
          objectNavCount.pop();
          break;
        case 'set':
          objectNavCount[count] = 0;
          break;
        default:
          break;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(hydrateAction, (state, action) => {
        const { objectDetails } = action.payload;
        state.details = objectDetails.details;
        state.projectRealties = objectDetails.projectRealties;
        state.status = objectDetails.status;
        state.loading = objectDetails.loading;
        state.streetViewAvailable = objectDetails.streetViewAvailable;
        state.openDaysDetails = objectDetails.openDaysDetails;
        state.favouriteCount = objectDetails.favouriteCount;
      })
      /** @deprecated replace with slice actions above */
      .addDefaultCase((state, action) => {
        switch (action.type) {
          case GET_PROJECT_DETAILS:
          case GET_MODULAR_HOUSE_DETAILS:
            setObjectDetailsSuccess(state, action.object);
            break;
          case GET_OBJECT_DETAILS:
            setObjectDetailsSuccess(state, action.object);
            break;
          case SET_STREET_VIEW_AVAILABILITY:
            state.streetViewAvailable = action.available;
            break;
          case OBJECT_STATUS_ERROR:
            state.status = action.status;
            state.loading = false;
            break;
          case GET_PROJECT_REALTIES:
            state.projectRealties = { id: action.projectId, items: action.realties };
            break;
          case SET_OBJECT_LOADING:
            state.loading = action.loading;
            state.status = '';
            state.favouriteCount = 0;
            break;
          case SET_OBJECT_MEDIA_FULL:
            state.view.fullscreen = action.full;
            break;
          case SET_SLIDE_COUNT:
            state.slideCount = action.slideCount;
            break;
          case SET_OPEN_HOUSE_DETAILS:
            state.openDaysDetails = action.openHouseDetails;
            break;
          case GET_OBJECT_FAVOURITE_COUNT:
            state.favouriteCount = action.count;
            break;
          case GET_SEB_NEWS_ARTICLES:
            state.SEBCalculatorArticles = action.articles;
            break;
          case GET_INTERNAL_FEED_ARTICLES:
            state.internalFeedArticles = action.articles;
            break;
          case GET_CENU_BANKA_DATA:
            state.cenuBankaData = action.data;
            break;
          case SET_OBJECT_NAV_COUNT: {
            const { objectNavCount } = state;
            switch (action.listAction) {
              case 'push':
                objectNavCount.push(action.count);
                break;
              case 'pop':
                objectNavCount.pop();
                break;
              case 'set':
                objectNavCount[objectNavCount.length - 1] = action.count;
                break;
              default:
                break;
            }
            break;
          }
          default:
            break;
        }
      });
  },
});

export const { actions } = objectDetailsSlice;

export default objectDetailsSlice.reducer;
