import React, { useContext } from 'react';
import AppContext from '../appContext';
import { Trans as ReactTrans } from 'react-i18next';

/**
 * Translation component that uses the context of the app. This should only be used in components that are used in legacy and NextJS contexts.
 * Otherwise use the `Trans` component from `react-i18next` or `next-i18next`.
 */
const Trans: typeof ReactTrans = (props) => {
  const { useTranslation } = useContext(AppContext);
  const [t, i18n] = useTranslation();
  return <ReactTrans t={t} i18n={i18n} {...props} />;
};

export default Trans;
