import { combineReducers } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Broker } from '@city24/common/types/User';
import { Office } from '@city24/common/types/Company';
import { RealtyListingShort } from '@city24/common/types/Realty';
import { TransactionTypeLiteral } from '@city24/common/enums/realty/TransactionType';
import { UnitTypeLiteral } from '@city24/common/enums/realty/UnitType';
import { RealEstateStatistics } from '@city24/common/types/public/Statistics';
import hydrateAction from '@/actions/hydrateAction';
import { GET_BROKER_STATISTICS } from '@/constants/actions';

const brokerInfoState: {
  broker: Broker;
  regions: string[];
  office: Office;
} = {
  broker: {} as Broker,
  regions: [],
  office: {} as Office,
};

const brokerInfoSlice = createSlice({
  name: 'broker-info',
  initialState: brokerInfoState,
  reducers: {
    getBrokerDetails(state, action: PayloadAction<Broker>) {
      state.broker = action.payload;
    },
    getBrokerOffice(state, action: PayloadAction<Office>) {
      state.office = action.payload;
    },
    getBrokerRegions(state, action: PayloadAction<string[]>) {
      state.regions = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(hydrateAction, (state, action) => {
      const {
        broker: { details },
      } = action.payload;
      return details.info;
    });
  },
});

export const { getBrokerDetails, getBrokerRegions, getBrokerOffice } = brokerInfoSlice.actions;

interface BrokerObjectsState {
  objects: RealtyListingShort[];
  total: number;
  transactionType: TransactionTypeLiteral | null;
  unitType: UnitTypeLiteral | null;
  recentObjects: RealtyListingShort[];
}
const brokerObjectsState: BrokerObjectsState = {
  objects: [],
  total: 0,
  transactionType: null,
  unitType: null,
  recentObjects: [],
};

const brokerObjectsSlice = createSlice({
  name: 'broker-objects',
  initialState: brokerObjectsState,
  reducers: {
    getBrokerObjects(
      state,
      action: PayloadAction<Pick<BrokerObjectsState, 'transactionType' | 'unitType' | 'objects' | 'total'>>
    ) {
      const { transactionType, unitType, objects, total } = action.payload;
      state.transactionType = transactionType;
      state.unitType = unitType;
      // state.objects = objects.map((obj) => fromJS(obj));
      state.objects = objects;
      state.total = total;
    },
    getBrokerRecentObjects(state, action: PayloadAction<BrokerObjectsState['recentObjects']>) {
      state.recentObjects = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(hydrateAction, (state, action) => {
      const {
        broker: { details },
      } = action.payload;
      return details.objects;
    });
  },
});

export const { getBrokerObjects, getBrokerRecentObjects } = brokerObjectsSlice.actions;

interface BrokerStatisticsState {
  id: number;
  user: string;
  properties: RealEstateStatistics;
}

const statisticsState: BrokerStatisticsState = {
  id: 0,
  user: '',
  properties: {} as RealEstateStatistics,
};

const brokerStatisticsSlice = createSlice({
  name: 'broker-statistics',
  initialState: statisticsState,
  reducers: {
    getBrokerStatistics(state, action: PayloadAction<BrokerStatisticsState>) {
      return action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(hydrateAction, (state, action) => {
        const {
          broker: { details },
        } = action.payload;
        return details.statistics;
      })
      .addCase(getBrokerObjects, (state, action) => {
        const { transactionType, unitType, total } = action.payload;
        if (transactionType && unitType) {
          state.properties[transactionType] = state.properties[transactionType] || {};
          state.properties[transactionType][unitType] = total;
        }
        // TODO: Remove this block when promo page is ready for deployment
      })
      .addDefaultCase((state, action) => {
        switch (action.type) {
          case GET_BROKER_STATISTICS:
            return action.statistics;
          default:
            break;
        }
      });
  },
});

export const { getBrokerStatistics } = brokerStatisticsSlice.actions;

export default combineReducers({
  info: brokerInfoSlice.reducer,
  objects: brokerObjectsSlice.reducer,
  statistics: brokerStatisticsSlice.reducer,
});
