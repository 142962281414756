import React from 'react';
import type { TFunction } from 'next-i18next';
import Anchor from '@/components/link/Anchor';
import Icon from '@/components/icon/Icon';

import hotOffersIcon from '@/img/campaign/hot-offers-icon.svg';
import Image from '@/components/img/Image';

interface Props {
  t: TFunction;
  href: string;
}

function HotOffersLinkTag({ t, href }: Props): React.ReactElement {
  return (
    <Anchor href={href} className="object__tags-campaign">
      <Image src={hotOffersIcon} alt="" className="object__tags-campaign__icon" />
      {t('common.campaign')}
      <Icon name="angle-right" />
    </Anchor>
  );
}

export default HotOffersLinkTag;
