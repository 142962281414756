import React, { useMemo } from 'react';
import { Map, Seq } from 'immutable';
import type { TFunction } from 'next-i18next';
import { formatPrice } from '@city24/common/utils/formatting';
import { openModal } from '@/components/modals/modalReducer';
import { ModalName } from '@/components/modals/modalInterfaces';
import { useAppDispatch } from '@/hooks';
import {
  InbankCalculatorState,
  InBankCalculatorType,
  PaymentInfoInterface,
} from '@/components/integrations/Inbank/InbankCalculator';
import { INBANK_CALCULATOR_DEFAULTS } from '@/constants/finance';
import { createRange } from '@/utils/collections';
import Select from '@/components/select/Select';
import Anchor from '@/components/link/Anchor';
import Image from '@/components/img/Image';
import Icon from '@/components/icon/Icon';

import css from '@/components/integrations/Inbank/InbankCalculator.module.scss';
import inbankLogo from '@/img/inbank-logo-black.svg';

interface Props {
  formState: InbankCalculatorState;
  defaultAmount: number;
  setFormState: React.Dispatch<React.SetStateAction<InbankCalculatorState>>;
  paymentInfo: PaymentInfoInterface;
  buttonLink: string;
  t: TFunction;
  gaEventHandler: (action: string, label?: string) => void;
  type: InBankCalculatorType;
}

const CompactInbankCalculator = ({
  formState,
  defaultAmount,
  setFormState,
  paymentInfo,
  buttonLink,
  t,
  gaEventHandler,
  type,
}: Props): React.ReactElement => {
  const dispatch = useAppDispatch();
  const defaults = INBANK_CALCULATOR_DEFAULTS[type];

  const periodRange = Seq.Indexed(createRange(defaults.minPeriod, defaults.maxPeriod + 1, 6)).map((i) =>
    Map({ name: 'inbankCalc.range.period', value: `${i}` })
  );

  const amountRange = useMemo(() => {
    // inject defaultAmount if not exist in range
    let range = createRange(defaults.minAmount, defaults.maxAmount + 1, defaults.amountStep);

    if (!range.includes(defaultAmount)) {
      const idx = range.findIndex((item) => defaultAmount - defaults.amountStep < item);
      if (idx !== -1) {
        range = [...range.slice(0, idx + 1), defaultAmount, ...range.slice(idx + 1)];
      }
    }

    return Seq.Indexed(range).map((i) => Map({ name: 'inbankCalc.range.amount', value: `${i}` }));
  }, [defaultAmount]);

  return (
    <>
      <div className={css.compactCalcHeader}>
        <div>
          <Image src={inbankLogo} alt={t(`inbankCalc.title.${type}`)} />
        </div>
        <div className={css.title}>
          <b>{t(`inbankCalc.title.${type}.compact`)}</b>{' '}
          <div className={css.subtitle}>{t(`inbankCalc.paymentTitle.${type}.compact`)}</div>
        </div>
      </div>
      <div className={css.compactCalc}>
        <div className={css.monthlyPayment}>
          <div className={css.amount}>{formatPrice(paymentInfo.monthlyPaymentDec)}</div>
          <div className={css.amountLabel}>{t('inbankCalc.perMonth')}</div>
          <div
            onClick={() => {
              dispatch(
                openModal({
                  name: ModalName.InbankCalculator,
                  loanAmount: paymentInfo.loanAmount,
                  period: formState.period,
                  totalAmount: paymentInfo.totalAmount,
                  gpl: Number(paymentInfo.apr),
                  type,
                })
              );
              gaEventHandler('openModal', 'compact');
            }}
            className={css.modalIcon}
          >
            <Icon name="question" />
          </div>
          <div>{t('inbankCalc.monthlyPaymentLabelCompact')}</div>
        </div>
        <div className={css.input}>
          <div className={css.inputItem}>
            <div className="">{t('inbankCalc.amountCompact')}</div>
            <Select
              extraSmall
              options={amountRange}
              selected={amountRange.find((item) => Number(item.get('value')) === formState.amount)}
              getValue={(val) => setFormState((prevState) => ({ ...prevState, amount: Number(val.get('value')) }))}
              translateOption={(option) => t(option.get('name'), { count: parseInt(option.get('value'), 10) })}
              gaEvent={() => gaEventHandler('amountInput')}
            />
          </div>
          <div className={css.inputItem}>
            <div className="">{t('inbankCalc.periodCompact')}</div>
            <Select
              extraSmall
              options={periodRange}
              selected={periodRange.find((item) => Number(item.get('value')) === formState.period)}
              getValue={(val) => setFormState((prevState) => ({ ...prevState, period: Number(val.get('value')) }))}
              translateOption={(option) => t(option.get('name'), { count: parseInt(option.get('value'), 10) })}
              gaEvent={() => gaEventHandler('periodInput')}
            />
          </div>
        </div>
        <Anchor
          href={buttonLink}
          target="_blank"
          className={css.button}
          onClick={() => gaEventHandler('buttonClick', 'compact')}
        >
          {t('inbankCalc.button')}
        </Anchor>
      </div>
    </>
  );
};

export default CompactInbankCalculator;
