import ReactGA3, { EventArgs } from 'react-ga';
import ReactGA4 from 'react-ga4';

/**
 * GA State interface for keeping track of custom parameters
 */
export interface GoogleAnalyticsUserProperties {
  is_authenticated?: boolean;
  is_broker?: boolean;
  search_view_type?: string;
}

export interface GoogleAnalyticsParams {
  user_properties: GoogleAnalyticsUserProperties;
}

/**
 * Custom wrapper for Google Analytics for GA3 (Universal analytics) and GA4 to work in parallel
 */
class GA {
  static initialize = (): void => {
    // ReactGA3.initialize(process.env.NEXT_PUBLIC_GA_TRACKING_ID!, {
    //   debug: process.env.DEBUG !== '0',
    //   titleCase: false,
    // });
    // ReactGA3.set({ anonymizeIp: true });

    ReactGA4.initialize(process.env.NEXT_PUBLIC_GA_4_TRACKING_ID!);
    ReactGA4.set({ anonymizeIp: true });
  };

  static setUserProperties = (user_properties: GoogleAnalyticsUserProperties): void => {
    ReactGA4.set({ user_properties });
  };

  static pageview = (path: string): void => {
    // ReactGA3.pageview(path);
  };

  static event = (args: EventArgs) => {
    // ReactGA3.event(args as EventArgs);
    ReactGA4.event(args.category, args);
  };
}

export default GA;
