import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SavedSearch } from '@/types/api/savedSearch';
import { freeze } from 'immer';

const initialState: {
  searches: any[];
  search: {
    search: SavedSearch | null;
    loading: boolean;
  };
  searchesChanged: boolean;
} = {
  searches: [],
  search: {
    search: null,
    loading: true,
  },
  searchesChanged: false,
};

const savedSearchesSlice = createSlice({
  name: 'savedSearches',
  initialState,
  reducers: {
    getSavedSearch(state, action: PayloadAction<SavedSearch>) {
      state.search.search = freeze(action.payload);
      state.search.loading = false;
    },
    setSavedSearchLoaded(state) {
      state.search.loading = false;
    },
    getSavedSearches(state, action: PayloadAction<SavedSearch[]>) {
      state.searches = freeze(action.payload);
      state.searchesChanged = false;
    },
    addSavedSearch(state, action: PayloadAction<SavedSearch>) {
      state.searches.unshift(action.payload);
      state.searchesChanged = true;
    },
    updateSavedSearch(state, action: PayloadAction<SavedSearch>) {
      const index = state.searches.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) {
        state.searches[index] = action.payload;
        state.searchesChanged = true;
      }
    },
    deleteSavedSearch(state, action: PayloadAction<string>) {
      const index = state.searches.findIndex((item) => item.id === action.payload);
      if (index !== -1) {
        state.searches.splice(index, 1);
        state.searchesChanged = true;
      }
    },
  },
});

export const actions = savedSearchesSlice.actions;
export default savedSearchesSlice.reducer;
