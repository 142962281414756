import { AddressPublic } from '@city24/common/types/public/RealtyPublic';
import { identity } from '../helpers';

export function houseAndApartmentComponent(address: AddressPublic) {
  return [address.house_number, address.apartment_number].filter(identity).join('-');
}

export function streetComponent(address: AddressPublic, houseAndApartment: string | null = null) {
  return address.street_name && address.street_name + (houseAndApartment && ` ${houseAndApartment}`);
}

export function addressComponent(address: AddressPublic) {
  return [streetComponent(address, houseAndApartmentComponent(address)), address.location_name, address.district_name]
    .filter(identity)
    .join(', ');
}

export function locationComponent(address: AddressPublic) {
  return [address.village_name, address.city_name, address.parish_name, address.county_name]
    .filter(identity)
    .join(', ');
}

export function fullAddress(address: AddressPublic) {
  return `${locationComponent(address)} - ${addressComponent(address)}`;
}
