import { List, Map, Set } from 'immutable';
import {
  GET_OM_COUNTY_OPTIONS,
  GET_OM_CITY_PARISH_OPTIONS,
  GET_OM_DISTRICT_SETTLEMENT_OPTIONS,
} from '../../constants/actions';
import api from '../../api/City24Api';
import { addressTerm, addressTypeFromLevel } from '../../utils/searchTerms';
import { DataLoadProxy } from '../../utils/collections';

// LOCATION

function mapToAddressTerm({ id, name, location_level, alternate_ids }) {
  return Map({
    name,
    value: addressTerm(addressTypeFromLevel(location_level), id),
    extra: Map({ original: id, altIds: Set(alternate_ids) }),
  });
}

export function fetchCounties() {
  return (dispatch) => {
    dispatch({
      type: GET_OM_COUNTY_OPTIONS,
      counties: new DataLoadProxy(List()),
    });

    return api.address
      .getCounties()
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return [];
      })
      .then((counties) => {
        return dispatch({
          type: GET_OM_COUNTY_OPTIONS,
          counties: DataLoadProxy.loaded(List(counties.map(mapToAddressTerm))),
        });
      });
  };
}

export function fetchCitiesParishes(county) {
  return (dispatch) => {
    dispatch({
      type: GET_OM_CITY_PARISH_OPTIONS,
      citiesParishes: new DataLoadProxy(List()),
    });

    return api.address
      .getParishesCities(county)
      .then((res) => (res.ok ? res.json() : []))
      .then((parishesCities) => {
        return dispatch({
          type: GET_OM_CITY_PARISH_OPTIONS,
          citiesParishes: DataLoadProxy.loaded(List(parishesCities.map(mapToAddressTerm))),
        });
      });
  };
}

export function fetchDistrictsSettlements(parish) {
  return (dispatch) => {
    dispatch({
      type: GET_OM_DISTRICT_SETTLEMENT_OPTIONS,
      settlements: new DataLoadProxy(List()),
    });
    return api.address
      .getCitiesDistricts(parish)
      .then((res) => (res.ok ? res.json() : []))
      .then((citiesDistricts) => {
        let settlements = List();

        if (citiesDistricts.length > 0) {
          const cities = [];
          const districts = [];
          citiesDistricts.forEach((item) => {
            const mapped = mapToAddressTerm(item);
            if (item.location_level === 3) {
              cities.push(mapped);
            } else {
              districts.push(mapped.setIn(['extra', 'city'], item.city.id));
            }
          });
          if (cities.length > 0 && districts.length > 0) {
            settlements = List([
              Map({ name: 'common.cityOrSettlement', value: 'city', options: List(cities) }),
              Map({ name: 'common.district', value: 'district', options: List(districts) }),
            ]);
          } else if (cities.length > 0) {
            settlements = List(cities);
          } else {
            settlements = List(districts);
          }
        }

        return dispatch({
          type: GET_OM_DISTRICT_SETTLEMENT_OPTIONS,
          settlements: DataLoadProxy.loaded(settlements),
        });
      });
  };
}

export function fetchTopLevelLocations() {
  return (dispatch) => {
    dispatch({
      type: GET_OM_COUNTY_OPTIONS,
      counties: new DataLoadProxy(List()),
    });

    return api.address
      .getTopLevelLocations()
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return [];
      })
      .then((counties) => {
        return dispatch({
          type: GET_OM_COUNTY_OPTIONS,
          counties: DataLoadProxy.loaded(List(counties.map(mapToAddressTerm))),
        });
      });
  };
}

export function fetchLocationsByParent(parentId, actionType) {
  const key = actionType === GET_OM_CITY_PARISH_OPTIONS ? 'citiesParishes' : 'settlements';

  return (dispatch) => {
    dispatch({
      type: actionType,
      [key]: new DataLoadProxy(List()),
    });

    return api.address
      .getTopLocationsByParent({ parent: parentId })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return [];
      })
      .then((regionalUnits) => {
        return dispatch({
          type: actionType,
          [key]: DataLoadProxy.loaded(List(regionalUnits.map(mapToAddressTerm))),
        });
      });
  };
}
