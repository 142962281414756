import ReactGA from '@/utils/ga';
import { hotjar } from 'react-hotjar';

import adOcean from './adOcean';

function createScript(src: string) {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = src;
  script.async = true;
  script.defer = true;
  return script;
}

window.dataLayer = window.dataLayer || [];
window.gtag = function() {
  window.dataLayer.push(arguments);
};

// @ts-ignore
window.gtag('consent', 'default', {
  ad_storage: 'denied',
  ad_user_data: 'denied',
  ad_personalization: 'denied',
  analytics_storage: 'denied',
});

function adOceanScript() {
  const script = createScript('https://ao.city24.lv/files/js/ado.js');
  script.addEventListener('load', () => adOcean.onLoad());
  document.body.appendChild(script);
}

adOceanScript();

if (process.env.NEXT_PUBLIC_APP_ENV !== 'development') {
  hotjar.initialize(Number(process.env.NEXT_PUBLIC_HJID), 6);
}

// Google remarketing
function conversionParams() {
  window.google_conversion_id = process.env.NEXT_PUBLIC_CONVERSION_ID;
  window.google_custom_params = window.google_tag_params;
  window.google_remarketing_only = true;
}

function conversionScript() {
  const script = createScript('https://www.googleadservices.com/pagead/conversion.js');
  document.body.appendChild(script);
}

function loadOneTrustScripts() {
  document.body.appendChild(
    createScript(`https://cdn.cookielaw.org/consent/${process.env.NEXT_PUBLIC_ONETRUSTID}/OtAutoBlock.js`)
  );
  const otScript = createScript('https://cdn.cookielaw.org/scripttemplates/otSDKStub.js');
  otScript.setAttribute('data-domain-script', process.env.NEXT_PUBLIC_ONETRUSTID!);
  document.body.appendChild(otScript);
}

conversionParams();
conversionScript();

// temporary enable on dev
if (true || process.env.NEXT_PUBLIC_APP_ENV !== 'development') {
  window.setTimeout(() => {
    loadOneTrustScripts();
  }, 3000);
}

window.OptanonWrapper = () => {
  // C0004 - Targeting Cookies
  if (OnetrustActiveGroups.indexOf('C0004') != -1 && window.fbq) {
    window.fbq('consent', 'grant');
  }
};

ReactGA.initialize();
