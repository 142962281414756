import { Map, fromJS } from 'immutable';
import { combineReducers } from 'redux';
import produce, { freeze } from 'immer';
import {
  GET_MAIN_PAGE_OBJECTS,
  GET_MAIN_PAGE_CAMPAIGN,
  GET_OBJECT_TYPE_COUNTS,
  GET_MAIN_PAGE_CAMPAIGNS_INFO,
} from '@/constants/actions';
import { shuffleArray } from '@/utils/helpers';
import { DataLoadProxy } from '@/utils/collections';
import { fromISOString } from '@/utils/date';
import { MainCampaignInfo } from '@/types/api/campaign';
import promoPackage from '@/components/promoPackageBlock/promoPackageBlockReducer';

const entries = [
  'topOffers',
  'specialOffers',
  'projects',
  'csProjects',
  'apartmentsFromOwner',
  'apartmentsSale',
  'apartmentsRent',
  'houseSale',
  'houseRent',
  'npCommercialRent',
  'commercialSale',
  'commercialRent',
  'land',
  'pricedrop',
  'openHouseProjects',
  'freshPublishedProjects',
].map((section) => [
  section,
  {
    objects: [],
    total: 0,
  },
]);

entries.push(['projectsByLocation', []]);
const sectionsInitialState = Map(entries);

function sections(state = sectionsInitialState, action) {
  switch (action.type) {
    case GET_MAIN_PAGE_OBJECTS: {
      const section = {
        objects: action.objects.map((o) => fromJS(o)),
        total: action.total,
      };

      if (['topOffers', 'projects'].includes(action.sectionType)) {
        section.objects = shuffleArray(section.objects);
      } else if (action.sectionType === 'projectsByLocation') {
        section.location = action.location;
        return state.setIn([action.sectionType, action.position], section);
      }

      return state.set(action.sectionType, section);
    }
    default:
      return state;
  }
}

// TODO: refactor to plain JS and use MainCampaignInfo['campaign_type'] as keys for less pointless transformations
// Also, refactor to use dynamically populated state here
export type CampaignsState = Record<
  | 'hotOffers'
  | 'modularHouseOffers'
  | 'openHouseOffers'
  | 'projectsOffers'
  | 'hotOffersHousesAndLands'
  | 'hotspecial'
  | 'openDays',
  {
    objects: DataLoadProxy;
    total: number;
    startDate: Date | null;
    endDate: Date | null;
  }
>;

const campaignsInitialState = (
  [
    'hotOffers',
    'modularHouseOffers',
    'openHouseOffers',
    'projectsOffers',
    'hotOffersHousesAndLands',
    'hotspecial',
    'openDays',
  ] as (keyof CampaignsState)[]
).reduce((state, section) => {
  state[section] = {
    objects: new DataLoadProxy([]),
    total: 0,
    startDate: null,
    endDate: null,
  };
  return state;
}, {} as CampaignsState);

function campaignTypeToSectionName(campaignType: MainCampaignInfo['campaign_type']): keyof CampaignsState | null {
  switch (campaignType) {
    case 'hot':
      return 'hotOffers';
    case 'modhouse':
      return 'modularHouseOffers';
    case 'openhouse':
      return 'openHouseOffers';
    case 'projects':
      return 'projectsOffers';
    case 'hot_offers_houses_lands':
      return 'hotOffersHousesAndLands';
    case 'hotspecial':
      return 'hotspecial';
    case 'opendays':
      return 'openDays';
    default:
      return null;
  }
}

const campaigns = produce((state, action) => {
  switch (action.type) {
    case GET_MAIN_PAGE_CAMPAIGN: {
      const sectionType = action.sectionType as keyof CampaignsState;
      state[sectionType].objects = action.objects;
      state[sectionType].total = action.total;
      break;
    }
    case GET_MAIN_PAGE_CAMPAIGNS_INFO: {
      action.campaigns.forEach((campaign: MainCampaignInfo) => {
        const sectionType = campaignTypeToSectionName(campaign.campaign_type);
        if (sectionType) {
          state[sectionType].startDate = fromISOString(campaign.start_date);
          state[sectionType].endDate = campaign.end_date ? fromISOString(campaign.end_date) : null;
        }
      });
      break;
    }
    default:
      break;
  }
}, campaignsInitialState);

const searchInitialState = Map({ objectTypeCounts: {} });
function search(state = searchInitialState, action) {
  switch (action.type) {
    case GET_OBJECT_TYPE_COUNTS:
      return state.set('objectTypeCounts', action.objectTypeCounts);
    default:
      return state;
  }
}

export default combineReducers({ sections, campaigns, search, blocks: combineReducers({ promoPackage }) });
