import Language from '@city24/common/enums/Language';
import { SITE_NAME } from './index';

export type SEOText = Record<Language, string>;

export const SEO_GLOBAL_TITLE_WITH_SLOGAN: SEOText = {
  en: `${SITE_NAME} real estate marketplace`,
  et: `${SITE_NAME} kinnisvaraportaal`,
  lv: `${SITE_NAME} nekustamo īpašumu sludinājumu portāls`,
  ru: `${SITE_NAME} портал недвижимости`,
};

export const SEO_MAIN_TITLE: SEOText = {
  en: `Real estate marketplace ${SITE_NAME}`,
  et: `Kinnisvaraportaal ${SITE_NAME}`,
  lv: `Nekustamo īpašumu sludinājumu portāls ${SITE_NAME}`,
  ru: `Портал недвижимости ${SITE_NAME}`,
};

export const SEO_MAIN_DESCRIPTION: SEOText = {
  en: PORTAL_EE
    ? 'City24.ee is the leading Estonian real estate marketplace. Search for-sale and rental apartments, houses, commercial properties, land and new projects.'
    : 'City24.lv is the leading Latvian real estate marketplace. Search for-sale and rental apartments, houses, commercial properties, land and new projects.',
  et: 'Leia sobiv kinnisvara tuhandete korterite, majade, kruntide ja äripindade seast. Ole kursis viimaste kinnisvarauudistega.',
  lv: `Atrodi savu nekustamo īpašumu starp tūkstošiem dzīvokļu, māju, zemes un komercplatību sludinājumiem. Plašākā Jauno projektu sludinājumu izvēle. Esi informēts par aktualitātēm nekustamo īpašumu tirgū.`,
  ru: 'Найдите подходящую недвижимость из тысячи квартир, домов, грунтов и коммерческих площадей. Будьте в курсе последних новостей недвижимости.',
};

export const SEO_NEWS_TITLE: SEOText = {
  en: `Real estate news - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.en}`,
  et: `Kinnisvarauudised - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.et}`,
  lv: `Nekustamo īpašumu ziņas - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.lv}`,
  ru: `Новости недвижимости - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.ru}`,
};
export const SEO_NEWS_DESCRIPTION: SEOText = {
  en: `Real estate market news and trends, events in the New development building market - all this and more on the real estate marketplace ${SITE_NAME}`,
  et: `Uudised ja trendid kinnisvaraturul, turuülevaated ja arendused - kõik portaalis ${SITE_NAME}`,
  lv: `Nekustamā īpašuma tirgus ziņas un tendences, aktualitātes jauno projektu tirgū - tas viss un vēl vairāk nekustamo īpašumu portālā ${SITE_NAME}`,
  ru: `Новости и тенденции рынка недвижимости, события на рынке новостроек - все это и многое другое на торговой площадке ${SITE_NAME}`,
};

export const SEO_COMPANY_SEARCH_TITLE: SEOText = {
  en: `Find real estate company - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.en}`,
  et: `Otsi bürood - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.et}`,
  lv: `Meklēt nekustamo īpašumu kompāniju - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.lv}`,
  ru: `Найти компанию по недвижимости - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.ru}`,
};
export const SEO_COMPANY_SEARCH_DESCRIPTION: SEOText = {
  en: `Search real estate companies, agencies, new construction building developers. Take a closer look at real estate marketplace ${SITE_NAME} search!`,
  et: `Otsi kinnisvarafirmat, kinnisvarabürood, kinnisvaraarendajat! Vaata ${SITE_NAME}!`,
  lv: `Nekustamā īpašuma kompāniju, aģentūru, jauno projektu attīstītāju meklēšana. Uzzini vairāk ${SITE_NAME} meklētājā!`,
  ru: `Поиск риэлторских компаний, агентств, застройщиков новостроек. Ознакомьтесь с результатом поиска на торговой площадке ${SITE_NAME}!`,
};
export const SEO_COMPANY_DETAIL_TITLE: SEOText = {
  en: `real estate company - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.en}`,
  et: `kinnisvarabüroo - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.et}`,
  lv: `nekustamo īpašumu kompānija - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.lv}`,
  ru: `компания по недвижимости - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.ru}`,
};
export const SEO_COMPANY_DETAIL_DESCRIPTION: SEOText = {
  en: 'Real estate company',
  et: 'Kinnisvarabüroo',
  lv: 'Nekustamo īpašumu kompānija',
  ru: 'Kомпания по недвижимости',
};

export const SEO_BROKER_SEARCH_TITLE: SEOText = {
  en: `Find real estate agent - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.en}`,
  et: `Otsi maaklerit - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.et}`,
  lv: `Meklēt nekustamo īpašumu aģentu - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.lv}`,
  ru: `Найти маклера - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.ru}`,
};
export const SEO_BROKER_SEARCH_DESCRIPTION: SEOText = {
  en: `Search real estate agents, new construction building developers. Take a closer look at real estate marketplace ${SITE_NAME} search!`,
  et: `Otsi kinnisvaramaaklerit, kinnisvaraarendajat. Vaata ${SITE_NAME}!`,
  lv: `Nekustamā īpašuma aģentu, jauno projektu attīstītāju meklēšana. Uzzini vairāk ${SITE_NAME} meklētājā!`,
  ru: `Поиск агентов по недвижимости, застройщиков новостроек. Ознакомьтесь с результатом поиска на торговой площадке ${SITE_NAME}!`,
};
export const SEO_BROKER_DETAIL_TITLE: SEOText = {
  en: `real estate agent - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.en}`,
  et: `kinnisvaramaakler - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.et}`,
  lv: `nekustamā īpašuma aģents - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.lv}`,
  ru: `маклер по недвижимости - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.ru}`,
};
export const SEO_BROKER_DETAIL_DESCRIPTION: SEOText = {
  en: `real estate agent`,
  et: 'kinnisvaramaakler',
  lv: `nekustamo īpašumu aģents`,
  ru: `маклер по недвижимости`,
};

export const SEO_NP_LANDING_TITLE: SEOText = {
  en: `The widest selection of New construction project advertisements on the real estate marketplace ${SITE_NAME}`,
  et: `Kõige rohkem uusarendusi kinnisvaraportaalis ${SITE_NAME}!`,
  lv: `Plašākā jauno projektu sludinājumu izvēle nekustamo īpašumu sludinājumu portālā ${SITE_NAME}`,
  ru: `Самый широкий выбор объявлений новостроек на площадке ${SITE_NAME}`,
};
export const SEO_NP_LANDING_DESCRIPTION: SEOText = {
  en: PORTAL_EE
    ? `The latest New construction projects in Tallin, near and elsewhere in Estonia! Take a closer look at new project offers!`
    : `The latest New construction projects in Riga, near, Jurmala and elsewhere in Latvia! Take a closer look at new project offers!`,
  et: 'Värsked uusarendused Tallinnas ja kogu Eestis! Vaata uusarenduste pakkumisi!',
  lv: `Aktuālākie jauno projektu piedāvājumi Rīgā, pierīgā, Jūrmalā un citviet Latvijā! Ieskaties jauno projektu sludinājumos!`,
  ru: PORTAL_EE
    ? `Свежие новостройки в Таллинне, рядом и в других местах Эстонии! Ознакомьтесь с предложениями новых проектов!`
    : `Новые проекты и новостройки в Риге, Юрмале и других местах Латвии. Ознакомиться с предложениями!`,
};

export const SEO_USER_OBJECTS_TITLE: SEOText = {
  en: `My objects - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.en}`,
  et: `Minu kuulutused - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.et}`,
  lv: `Mani sludinājumi - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.lv}`,
  ru: `Мои объекты - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.ru}`,
};
export const SEO_USER_INVOICES_TITLE: SEOText = {
  en: `Invoices - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.en}`,
  et: `Arved - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.et}`,
  lv: `Rēķini - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.lv}`,
  ru: `Счета - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.ru}`,
};
export const SEO_USER_SETTINGS_TITLE: SEOText = {
  en: `Settings - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.en}`,
  et: `Konto seaded - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.et}`,
  lv: `Uzstādījumi - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.lv}`,
  ru: `Настройки - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.ru}`,
};
export const SEO_USER_FAVOURITES_TITLE: SEOText = {
  en: `Favorite properties - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.en}`,
  et: `Lemmikobjektid - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.et}`,
  lv: `Saglabātie nekustamo īpašumu sludinājumi - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.lv}`,
  ru: `Избранные объекты - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.ru}`,
};
export const SEO_USER_SAVED_SEARCHES_TITLE: SEOText = {
  en: `Searches and notifications - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.en}`,
  et: `Otsingud ja teavitused - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.et}`,
  lv: `Nekustamo īpašumu meklējumi un paziņojumi - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.lv}`,
  ru: `Поиски и напоминания - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.ru}`,
};
export const SEO_OBJECT_INSERT_TITLE: SEOText = {
  en: `Place real estate ad - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.en}`,
  et: `Lisa kuulutus - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.et}`,
  lv: `Ievietot nekustamo īpašumu sludinājumu - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.lv}`,
  ru: `Добавить объявление - ${SEO_GLOBAL_TITLE_WITH_SLOGAN.ru}`,
};
export const SEO_OBJECT_INSERT_DESCRIPTION: SEOText = {
  en: PORTAL_EE
    ? 'Place your real estate sale or rent advertisement on the most visited real estate portal in Estonia - City24.ee'
    : 'Place your real estate sale or rent advertisement on the most visited real estate portal in Latvia - City24.lv',
  et: 'Lisa oma müügi- või üürikuulutus enim külastatud kinnisvaraportaali City24.ee!',
  lv: `Ievieto sava nekustamā īpašuma pārdošanas vai īres sludinājumu apmeklētākājā nekustamo īpašumu portālā Latvijā - City24.lv`,
  ru: PORTAL_EE
    ? 'Разместите объявление о продаже или аренде недвижимости на самом посещаемом портале недвижимости в Эстонии - City24.ee'
    : 'Разместите объявление о продаже или аренде недвижимости на самом посещаемом портале недвижимости в Латвии - City24.lv',
};

export const SEO_NP_SEARCH_DESCRIPTION: SEOText = {
  en: PORTAL_EE
    ? 'The latest New construction projects in Tallinn, near and elsewhere in Estonia! Take a closer look at new project offers!'
    : 'The latest New construction projects in Riga, near, Jurmala and elsewhere in Latvia! Take a closer look at new project offers!',
  et: 'Värsked uusarendused Tallinnas ja kogu Eestis! Vaata uusarenduste pakkumisi!',
  lv: 'Aktuālākie jauno projektu piedāvājumi Rīgā, pierīgā, Jūrmalā un citviet Latvijā! Ieskaties jauno projektu sludinājumos!',
  ru: PORTAL_EE
    ? 'Свежие новостройки в Таллинне, рядом и в других местах Эстонии! Ознакомьтесь с предложениями новых проектов!'
    : 'Новые проекты и новостройки в Риге, Юрмале и других местах Латвии. Ознакомиться с предложениями!',
};
export const SEO_MH_SEARCH_DESCRIPTION: SEOText = {
  en: `The latest Pre-Fab houses, modular houses offers! Take a closer look at ${SITE_NAME} ads!`,
  et: 'Parim valik valmismaju kinnisvaraportaalis City24.ee!',
  lv: 'Aktuālākie māju būvprojektu, moduļu māju, koka karkasa māju piedāvājumi! Ieskaties City24.lv sludinājumos!',
  ru: `Новейшие дома Pre-Fab, предложения модульных домов! Ознакомьтесь с объявлениями ${SITE_NAME}!`,
};
export const SEO_SEARCH_DESCRIPTION: SEOText = {
  en: 'The latest real estate ads! %typeLocationCombo%. Take a closer look at the real estate offers!',
  et: 'Kõige värskemad kinnisvarakuulutused! %typeLocationCombo%. Vaata pakkumisi!',
  lv: 'Aktuālākie nekustamo īpašumu sludinājumi! %typeLocationCombo%. Ieskaties nekustamo īpašumu sludinājumos!',
  ru: 'Свежие объявления о недвижимости! %typeLocationCombo%. Ознакомьтесь с предложениями недвижимости!',
};

export const SEO_CONTENT_CTA: SEOText = {
  en: 'Take a look at the offers!',
  et: 'Tutvu pakkumistega lähemalt!',
  lv: 'Ieskaties nekustamo īpašumu piedāvājumos!',
  ru: 'Ознакомиться с предложениями!',
};

export const SEO_META_CONTENT: SEOText = {
  en: `${SITE_NAME} real estate marketplace`,
  et: `${SITE_NAME} kinnisvarakuulutused`,
  lv: `${SITE_NAME} nekustamo īpašumu sludinājumi`,
  ru: `Объявления по недвижимости ${SITE_NAME}`,
};

export const SEO_PAGE: SEOText = {
  en: 'Page',
  et: 'Leht',
  lv: 'Lapa',
  ru: 'Cтраница',
};

export const SEO_URL_SEARCH_TERM: SEOText = {
  en: 'search-term',
  et: 'otsisona',
  lv: 'meklet-vardu',
  ru: 'klyuchevoye-slovo',
};

export const SEO_URL_PRICE: SEOText = {
  et: 'hind',
  lv: 'cena',
  ru: 'tsena',
  en: 'price',
};

export const SEO_URL_ROOMS: SEOText = {
  et: 'tubade-arv',
  lv: 'istabu-skaits',
  ru: 'kolichestvo-komnat',
  en: 'rooms',
};

export const SEO_URL_SIZE: SEOText = {
  et: 'suurus',
  lv: 'platiba',
  ru: 'diapazon-ploshchadi',
  en: 'size',
};

export const SEO_URL_LOT: SEOText = {
  et: 'lot-size',
  lv: 'lot-size',
  ru: 'lot-size',
  en: 'lot-size',
};

export const SEO_URL_FLOOR: SEOText = {
  et: 'korrus',
  lv: 'stavs',
  ru: 'etazh',
  en: 'floor',
};

export const SEO_URL_LAST_FLOOR: SEOText = {
  et: 'viimane',
  lv: 'pedeja',
  ru: 'posledniy',
  en: 'last',
};

export const SEO_URL_CONDITION: SEOText = {
  et: 'seisukord',
  lv: 'stavoklis',
  ru: 'sostoyaniye',
  en: 'condition',
};

export const SEO_URL_MATERIAL: SEOText = {
  et: 'materjal',
  lv: 'tips',
  ru: 'material',
  en: 'material',
};
export const SEO_URL_PURPOSE: SEOText = {
  et: 'sihtotstarve',
  lv: 'pielietosanas-merkis',
  ru: 'naznacheniye',
  en: 'purpose',
};

export const SEO_URL_OWNER: SEOText = {
  et: 'tavakasutaja',
  lv: 'privatpersona',
  ru: 'chastnoye-litso',
  en: 'private-user',
};

export const SEO_URL_VIRTUAL: SEOText = {
  et: 'virtuaaltuurid',
  lv: 'virtualas-tures',
  ru: 'virtualnyye-tury',
  en: 'virtual-tours',
};

export const SEO_URL_OPEN_HOUSE: SEOText = {
  et: 'kliendipaevad',
  lv: 'atverto-durvju-dienas',
  ru: 'dni-klienta',
  en: 'open-house',
};

export const SEO_URL_SPECIAL_OFFERS: SEOText = {
  et: 'special-offers',
  lv: 'special-offers',
  ru: 'special-offers',
  en: 'special-offers',
};

export const SEO_URL_RENT_TO_OWN: SEOText = {
  et: 'rent-to-own',
  lv: 'rent-to-own',
  ru: 'rent-to-own',
  en: 'rent-to-own',
};

export const SEO_URL_YEAR_BUILT: SEOText = {
  et: 'built',
  lv: 'built',
  ru: 'built',
  en: 'built',
};

export const SEO_URL_ENERGY_CERTIFICATE: SEOText = {
  et: 'ec',
  lv: 'ec',
  ru: 'ec',
  en: 'ec',
};

export const SEO_URL_PAGE: SEOText = {
  et: 'lk',
  lv: 'lapa',
  ru: 'st',
  en: 'pg',
};

export const SEO_URL_SORT: SEOText = {
  et: 'sort',
  lv: 'sort',
  ru: 'sort',
  en: 'sort',
};
