import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

type Props = {
  className?: string;
  children?: React.ReactNode;
  center?: boolean;
  extended?: boolean;
  small?: boolean;
  spaceY?: boolean;
};

function Container({
  className,
  children = null,
  center = false,
  extended = false,
  small = false,
  spaceY = false,
}: Props): React.ReactElement {
  const containerStyle = classNames(
    'container',
    {
      'container--center': center,
      'container--extended': extended,
      'container--small': small,
      'container--space-y': spaceY,
    },
    className
  );

  return <section className={containerStyle}>{children}</section>;
}

Container.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  small: PropTypes.bool,
  spaceY: PropTypes.bool,
};

export default Container;
