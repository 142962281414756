import React from 'react';
import { useAppDispatch } from '@/hooks';
import type { TFunction } from 'next-i18next';
import { INBANK_CALCULATOR_DEFAULTS } from '@/constants/finance';
import { getDecimalPart, twoZeroFill } from '@/utils/string';
import { openModal } from '@/components/modals/modalReducer';
import { ModalName } from '@/components/modals/modalInterfaces';
import {
  InbankCalculatorState,
  InBankCalculatorType,
  PaymentInfoInterface,
} from '@/components/integrations/Inbank/InbankCalculator';
import Image from '@/components/img/Image';
import InputRangeSlider from '@/components/input/InputRangeSlider';
import Anchor from '@/components/link/Anchor';

import inbankLogo from '@/img/inbank-logo-black.svg';
import css from '@/components/integrations/Inbank/InbankCalculator.module.scss';

interface Props {
  formState: InbankCalculatorState;
  setFormState: React.Dispatch<React.SetStateAction<InbankCalculatorState>>;
  paymentInfo: PaymentInfoInterface;
  buttonLink: string;
  t: TFunction;
  gaEventHandler: (action: string, label?: string) => void;
  type: InBankCalculatorType;
}

const ExtendedInbankCalculator = ({
  formState,
  setFormState,
  paymentInfo,
  buttonLink,
  t,
  gaEventHandler,
  type,
}: Props): React.ReactElement => {
  const dispatch = useAppDispatch();
  const defaults = INBANK_CALCULATOR_DEFAULTS[type];

  return (
    <>
      <h3>{t(`inbankCalc.title.${type}`)}</h3>
      <div className={css.calc}>
        <div className={css.content}>
          <div className={css.sliders}>
            <div className={css.logo}>
              <Image src={inbankLogo} alt={t(`inbankCalc.title.${type}`)} />
            </div>
            <div className={css.sliderItem}>
              <div className={css.header}>
                <div>{t('inbankCalc.amount')}</div>
                <div className={css.value}>{formState.amount}</div>
              </div>
              <div className={css.rangeSliderWrapper}>
                <InputRangeSlider
                  value={formState.amount}
                  min={defaults.minAmount}
                  max={defaults.maxAmount}
                  step={200}
                  getValue={(val) => {
                    setFormState((prevState) => ({ ...prevState, amount: Number(val) }));
                    gaEventHandler('amountInput', val);
                  }}
                  className={css.rangeSlider}
                />
              </div>
            </div>
            <div className={css.sliderItem}>
              <div className={css.header}>
                <div>{t('inbankCalc.period')}</div>
                <div className={css.value}>{formState.period}</div>
              </div>
              <div className={css.rangeSliderWrapper}>
                <InputRangeSlider
                  value={formState.period}
                  min={defaults.minPeriod}
                  max={defaults.maxPeriod}
                  step={6}
                  getValue={(val) => {
                    setFormState((prevState) => ({ ...prevState, period: Number(val) }));
                    gaEventHandler('periodInput', val);
                  }}
                  className={css.rangeSlider}
                />
              </div>
            </div>
          </div>
          <div className={css.summary}>
            <div className={css.downPayment}>{t('inbankCalc.paymentTitle')}</div>
            <div className={css.label}>{t('inbankCalc.monthlyPaymentLabel')}</div>
            <div className={css.payment}>
              <div className={css.amountBase}>{Math.trunc(paymentInfo.monthlyPaymentDec)}</div>
              <div>
                <div className={css.amountDecimal}>{twoZeroFill(getDecimalPart(paymentInfo.monthlyPaymentDec))}</div>
                <div>{t('inbankCalc.paymentUnit')}</div>
              </div>
            </div>
            <a
              className={css.modalButton}
              onClick={() => {
                dispatch(
                  openModal({
                    name: ModalName.InbankCalculator,
                    loanAmount: paymentInfo.loanAmount,
                    period: formState.period,
                    totalAmount: paymentInfo.totalAmount,
                    gpl: Number(paymentInfo.apr),
                    type,
                  })
                );
                gaEventHandler('openModal', 'large');
              }}
            >
              {t('inbankCalc.disclaimerButton')}
            </a>
            <Anchor
              href={buttonLink}
              target="_blank"
              className={css.button}
              onClick={() => gaEventHandler('buttonClick', 'large')}
            >
              {t('inbankCalc.button')}
            </Anchor>
          </div>
        </div>
      </div>
      <div className={css.disclaimer}>{t(`inbankCalc.disclaimer.${type}`)}</div>
    </>
  );
};

export default ExtendedInbankCalculator;
