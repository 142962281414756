import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Collection } from 'immutable';
import { FacebookShareButton } from 'react-share';

import { useAppDispatch, useAppSelector } from '@/hooks';
import { AGENT_SHOW_PHONE } from '@/constants/statistics';
import { setShareModal } from '@/components/modals/ModalActions';
import { getBrowser } from '@/selectors/appSelectors';

import AppContext from '@/components/appContext';
import Avatar from '@/components/Avatar/Avatar';
import Rating from '@/components/rating/Rating';
import Contact from '@/components/contact/Contact/Contact';
import AchievementsList from '@/components/list/AchievementsList/AchievementsList';
import Button from '@/components/button/Button';
import ButtonGroup from '@/components/button/ButtonGroup';
import ContactBrokerForm from '@/components/contact/ContactBrokerForm/ContactBrokerForm';
import Icon from '@/components/icon/Icon';
import VerifiedBadge from '@/components/user/VerifiedBadge';
import LegacyToNextLink from '@/components/link/LegacyToNextLink';

export const CONTACT_CARD_LAYOUT = {
  HORIZONTAL: 'HORIZONTAL',
  VERTICAL: 'VERTICAL',
};

// TODO: needs refactoring into smaller components, too many props and dependencies
const ContactCard = (props) => {
  const {
    t,
    layout,
    image,
    imagePlaceholder,
    name,
    nameClickRoute,
    company,
    companyLogo,
    companyClickRoute,
    rating,
    showRatingNumber,
    email,
    showEmail,
    address,
    phone,
    showPhone,
    renderPhone,
    disableLinks,
    www,
    facebook,
    twitter,
    instagram,
    linkedin,
    awards,
    certificates,
    gaEvent,
    className,
    sendStatistics,
    compact,
    raised,
    hideCompanyName,
    hideContactButtons,
    fbShareUrl,
    object,
    brokerId,
    preview,
    noIcon,
    children,
    renderEmail,
    isVipBroker,
    isDeveloper,
    isVerifiedUser = false,
    shareObject,
  } = props;

  const dispatch = useAppDispatch();
  const { Link } = useContext(AppContext);
  const browser = useAppSelector(getBrowser);

  const classes = classNames(
    'contact-card',
    {
      'contact-card--horizontal': layout === CONTACT_CARD_LAYOUT.HORIZONTAL,
      'contact-card--vertical': layout === CONTACT_CARD_LAYOUT.VERTICAL,
      'contact-card--compact': compact,
      'contact-card--raised': raised,
      'contact-card--vip': isVipBroker,
    },
    className
  );

  const gaEventHandler = (label) => {
    if (gaEvent) {
      gaEvent(label);
    }
  };

  const NameLink = ({ children, className }) => {
    if (nameClickRoute) {
      return (
        <LegacyToNextLink href={preview ? '#' : nameClickRoute} className={className}>
          {children}
        </LegacyToNextLink>
      );
    }
    return <div className={className}>{children}</div>;
  };

  const renderName = () => {
    if (nameClickRoute && company) {
      return (
        <NameLink>
          <div className="h3 contact-card__name">
            {name}
            {isVerifiedUser && <VerifiedBadge />}
            {!noIcon && <Icon name="angle-right" />}
          </div>
        </NameLink>
      );
    }
    if (browser.lessThan.medium && brokerId) {
      return (
        <div className="h2 contact-card__name--mobile">
          {name} {isVerifiedUser && <VerifiedBadge />}
        </div>
      );
    }
    return (
      <div className="h3 contact-card__name contact-card__name-grey">
        {name} {isVerifiedUser && <VerifiedBadge />}
      </div>
    );
  };

  const showContactPhone = phone && (phone.size > 0 || phone.length > 0) && browser.lessThan.medium;
  const showShareButton = shareObject && browser.lessThan.medium;
  return (
    <div className={classes}>
      {isVipBroker && (
        <span className="badge">{isDeveloper ? t('common.vip_developer_badge') : t('common.vip_agent_badge')}</span>
      )}
      {(image || imagePlaceholder) && (
        <NameLink className="contact-card__image">
          <Avatar
            imageUrl={image}
            placeholderUrl={imagePlaceholder}
            className={browser.lessThan.medium ? 'contact-card__avatar-mobile' : 'contact-card__avatar'}
          />
        </NameLink>
      )}
      <div className="contact-card__content">
        <div className="contact-card__title">
          <div>
            {renderName()}
            {company && !hideCompanyName && <div className="contact-card__company-name">{company}</div>}
            {rating && rating >= 4 && <Rating rating={rating} showNr={showRatingNumber} />}
          </div>
          {fbShareUrl && (
            <div className="contact-card__fb-share">
              <FacebookShareButton url={fbShareUrl}>
                <Button icon="facebook" facebook extraSmall text={t('common.share')} />
              </FacebookShareButton>
            </div>
          )}
        </div>
        <Contact
          gaEvent={gaEventHandler}
          email={showEmail || renderEmail ? email : []}
          renderEmail={renderEmail}
          address={address}
          phone={phone}
          showPhone={showPhone}
          renderPhone={renderPhone}
          sendStatistics={sendStatistics ?? null}
          disableLinks={preview ? true : disableLinks}
          www={www}
          facebook={facebook}
          twitter={twitter}
          instagram={instagram}
          linkedin={linkedin}
          className="contact-card__contact-list"
          t={t}
        />
        <AchievementsList t={t} awards={awards} certificates={certificates} />
        {children}
      </div>
      {!hideContactButtons && (showContactPhone || (!showEmail && email)) && (
        <div className="contact-card__actions">
          {!showEmail && email && object && (
            <ContactBrokerForm
              t={t}
              preview={preview}
              objectId={object.get('id')}
              objectGuid={object.get('guid')}
              objectFriendlyId={object.get('friendly_id')}
              objectUnitType={object.get('unit_type')}
              objectPrice={object.get('price')}
              objectLocation={object.get('address')}
              brokerId={brokerId}
              brokerEmail={email}
            />
          )}
          <ButtonGroup fixed>
            {showContactPhone && (
              <Button
                href={`tel:${(phone.first && phone.first()) || phone[0]}`}
                primary
                icon="phone"
                text={t('broker.call')}
                onClick={() => {
                  gaEventHandler('call_button');
                  if (sendStatistics) sendStatistics(AGENT_SHOW_PHONE);
                }}
              />
            )}
            {showShareButton && (
              <Button
                transparent
                className="btn-share"
                icon="share"
                text={t('common.share')}
                onClick={() => {
                  dispatch(setShareModal(true, shareObject.id, shareObject.type));
                  if (gaEvent) {
                    gaEvent(shareObject.gaEvent);
                  }
                }}
              />
            )}
          </ButtonGroup>
        </div>
      )}
      {company && companyClickRoute && (
        <Link
          href={preview ? '#' : companyClickRoute}
          className={classNames('contact-card__company', {
            'contact-card__company--no-logo': !companyLogo,
          })}
        >
          {companyLogo && <img src={companyLogo} alt={`${company} logo`} />}
          <span className="contact-card__company-cta">
            <span>{t('broker.companyProfile')}</span>
            <Icon name="angle-right" />
          </span>
        </Link>
      )}
    </div>
  );
};

ContactCard.propTypes = {
  t: PropTypes.func.isRequired,
  layout: PropTypes.oneOf([...Object.values(CONTACT_CARD_LAYOUT)]).isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  imagePlaceholder: PropTypes.string,
  name: PropTypes.string,
  company: PropTypes.string,
  rating: PropTypes.string,
  showRatingNumber: PropTypes.bool,
  email: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Collection)]),
  address: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.instanceOf(Collection),
  ]),
  phone: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Collection)]),
  www: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Collection)]),
  facebook: PropTypes.string,
  twitter: PropTypes.string,
  instagram: PropTypes.string,
  linkedin: PropTypes.string,
  awards: PropTypes.oneOfType([PropTypes.instanceOf(Collection.Indexed), PropTypes.arrayOf(PropTypes.object)]),
  certificates: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])),
  gaEvent: PropTypes.func,
  className: PropTypes.string,
  sendStatistics: PropTypes.func,
  compact: PropTypes.bool,
  showEmail: PropTypes.bool,
  disableLinks: PropTypes.bool,
  hideContactButtons: PropTypes.bool,
  companyLogo: PropTypes.string,
  brokerId: PropTypes.string,
  object: PropTypes.instanceOf(Collection),
  noIcon: PropTypes.bool,
  isVipBroker: PropTypes.bool,
  isDeveloper: PropTypes.bool,
  shareObject: PropTypes.object,
  setShareModal: PropTypes.object,
  showPhone: PropTypes.bool,
  renderPhone: PropTypes.bool,
  shareObject: PropTypes.object,
  setShareModal: PropTypes.object,
};

ContactCard.defaultProps = {
  image: '',
  imagePlaceholder: '',
  name: '',
  company: '',
  rating: null,
  showRatingNumber: false,
  email: '',
  address: '',
  phone: '',
  www: '',
  facebook: '',
  twitter: '',
  instagram: '',
  linkedin: '',
  awards: null,
  certificates: null,
  gaEvent: null,
  className: '',
  sendStatistics: null,
  compact: false,
  showEmail: false,
  disableLinks: false,
  hideContactButtons: false,
  companyLogo: null,
  brokerId: null,
  object: null,
  noIcon: false,
  isVipBroker: false,
  isDeveloper: false,
  shareObject: null,
  showPhone: false,
  renderPhone: true,
  shareObject: null,
};

export default ContactCard;
